<template>
  <div class="px-0 pb-2">
    <CreateCall
      v-model="showModal"
      @hide-modal="showModal = false"
      :studentInfo="studentInfo"
      :calls="calls"
      @update-data="updateData"
    ></CreateCall>
    <!-- exceptional attendance row -->
    <div class="row align-center ex-row">
      <div class="mb-3 col-lg-3">
        <label>الفرقة<span class="red">*</span></label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedGrade"
          :options="grades"
          :searchable="true"
          :close-on-select="true"
          placeholder=" اختر الفرقة"
          @select="getSubjects($event)"
          label="name"
          :custom-label="customLabel"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
      <div class="mb-3 col-lg-3">
        <label>المادة</label>
        <multiselect
          class="required form-control"
          id="student"
          v-model="selectedSubject"
          :options="subjects"
          :searchable="true"
          :multiple="true"
          :close-on-select="true"
          placeholder=" اختر المادة"
          label="name"
          :custom-label="customLabel"
          track-by="id"
          required="required"
          :internal-search="true"
        >
          >
          <template v-slot:noOptions>
            <span> فارغة </span>
          </template>
          <template v-slot:noResult>
            <span>لا يوجد نتيجة </span>
          </template>
        </multiselect>
      </div>
         <div class="mb-3 col-lg-3">
           <label> رقم الصفحة</label>
                <vmd-input
                 id="student-number"
                type="text"
                placeholder="ادخل رقم الصفحة  "
                 v-model="pageNumber"
                    />
                  </div>
      <div class="text-center col-lg-1">
        <vmd-button
          class="my-4 mb-2 width action-btn"
          variant="gradient"
          color="primary"
          @click="filterData()"
        >
          فلتر</vmd-button
        >
      </div>
      <div class="text-center col-lg-1">
        <vmd-button
          class="my-4 mb-2 width action-btn"
          variant="gradient"
          color="info"
          @click="printReport()"
        >
          طباعة</vmd-button
        >
      </div>
      <div class="text-center col-lg-1">
        <vmd-button
          class="my-4 mb-2 width action-btn"
          variant="gradient"
          color="warning"
          @click="exportTableToExcel('myTable', fileName)"
        >
          اكسل</vmd-button
        >
      </div>
    </div>
    <div id="print-subject" style="direction: rtl">
      <div class="row times" style="direction: rtl">
        <div class="col-md-4">
          <h5>
            الفرقة : <span class="green">{{ selectedGrade.name }}</span>
          </h5>
        </div>
         <div class="col-md-4">
          <h5>
            إجمالي الطلاب : <span class="green total-tex">{{ totalStudents}}</span>
          </h5>
        </div>
      </div>
      <div class="row align-center overflow-x" style="direction: rtl">
        <table id="myTable" class="table" tyle="direction:rtl">
          <thead>
            <tr>
              <th rowspan="2">#</th>
              <th rowspan="2">رقم الطالب</th>
              <th rowspan="2">اسم الطالب</th>
              <th rowspan="2">الهاتف</th>
              <th colspan="3" class="text-center">المكالمات</th>
              <th rowspan="2">مج</th>
              <th
                colspan="2"
                rowspan="2"
                v-for="(key, index) in keys"
                :key="index"
              >
                {{ key }}
                <hr />
                <div style="display: flex; gap: 40px">
                  <span style="width: 10%"> عدد</span>
                  <span style="width: 90%"> المدرس</span>
                </div>
              </th>
            </tr>
            <tr>
              <th id="add-call">إضافة</th>
              <th>اخر مكالمة</th>
              <th>المكالمة القادمة</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in subjectAttendance" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.student.number }}</td>
              <td>{{ item.student.full_name }}</td>
              <td>
                <a class="to-link" :href="`tel:${item.student.mobile}`">
                  {{ item.student.mobile }}</a
                >
              </td>
              <td id="add-call-btn">
                <button
                  style="width: unset !important"
                  @click="
                    getCallDetails(
                      item.student.full_name,
                      item.student.id
                    )
                  "
                  class="badge btn btn-info badge-sm pointer"
                >
                  إضافة
                </button>
              </td>
              <td>
                {{
                  item.student.last_call
                    ? item.student.last_call.created_at
                    : null
                }}
              </td>
              <td>
                {{
                  item.student.last_call
                    ? item.student.last_call.next_call_date
                    : null
                }}
              </td>
              <td>{{ item.student.group }}</td>
              <td colspan="2" v-for="(key, index) in keys" :key="index">
                <div style="display: flex; gap: 5px">
                  <span
                    :class="{
                      'count-span': item[key].count > 0,
                      'count-0': item[key].count === 0,
                    }"
                  >
                    {{ item[key].count }}</span
                  >
                  -
                  <span style="width: 90%">
                    {{
                      item[key].instructor ? item[key].instructor : "-"
                    }}</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- pagination -->
           <div class="row mt-3">
            <div class=" col-lg-9 col-sm-12 overflow-x">
  <nav aria-label="...">
    <ul class="pagination pagination-lg">
      <li @click="paginate('prev')" class="pointer" :class="{'pagi-active': currentPage === 1}">
        <span class="page-link pagi-item"><i class="fas fa-chevron-right"></i></span>
      </li>
      <li @click="paginate(page)" class="pointer" :class="{'pagi-active': currentPage === page}" v-for="page in visiblePages" :key="page">
        <span class="page-link pagi-item">{{ page }}</span>
      </li>
        <li v-if="showEllipsisAfter" class="pointer">
          <span class="page-link pagi-item">...</span>
        </li>
      <li @click="paginate('next')" class="pointer" :class="{'pagi-active': currentPage === allPages}">
        <span class="page-link pagi-item"><i class="fas fa-chevron-left"></i></span>
      </li>
    </ul>
  </nav>
  </div>
  <div class=" col-lg-3  text-center col col-sm-12" v-if="subjectAttendance?.length">
    <div class="">
      يعرض <span class="total-tex green">{{currentPage}}</span> من   <span class="total-tex green">{{allPages}} </span> صفحة
    </div>
  </div>
</div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import * as XLSX from 'xlsx'
import $ from 'jquery'
import swal from 'sweetalert'
import VmdButton from '@/components/VmdButton.vue'
import CreateCall from '@/components/CreateCall.vue'
const today = new Date()
const date =
  today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
const time =
  today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
const CurrentDateTime = date + ' ' + time
export default {
  name: 'SubjectAttendance',
  components: {
    VmdButton,
    CreateCall,
    VmdInput
  },
  data () {
    return {
      // data table
      subjects: [],
      selectedSubject: '',
      groups: [],
      selectedGroup: '',
      grades: [],
      selectedGrade: '',
      subjectInfo: [],
      fileName: `تقرير حضور الطلاب للمادة ${CurrentDateTime}`,
      exceptional: '',
      excelFile: [],
      subjectAttendance: [],
      totalStudents: 0,
      showModal: false,
      studentInfo: {},
      calls: [],
      page: 1,
      currentPage: 1,
      allPages: 0,
      pageNumber: 1,
      visiblePages: []

    }
  },

  created () {
    console.log(CurrentDateTime, this.fileName)
    this.$http.get(`${this.$hostUrl}subjects`).then((response) => {
      this.subjects = response.data.data
    })
    this.$http.get(`${this.$hostUrl}groups`).then((response) => {
      this.groups = response.data.data
    })
    this.$http.get(`${this.$hostUrl}grades`).then((response) => {
      this.grades = response.data.data
    })
  },
  computed: {
    keys () {
      // Get all the dynamic keys from the data array
      const allKeys = this.subjectAttendance.map((item) => Object.keys(item))
      // Merge all the keys into one array
      const mergedKeys = [].concat.apply([], allKeys)
      // Remove duplicates
      const uniqueKeys = [...new Set(mergedKeys)]
      // Remove the 'student' key
      const filteredKeys = uniqueKeys.filter((key) => key !== 'student')
      return filteredKeys
    },
    showEllipsisAfter () {
      return this.visiblePages[this.visiblePages.length - 1] < this.allPages
    }
  },
  methods: {
    getCallDetails (studentName, studentId) {
      this.calls = []
      this.studentInfo = {}
      this.studentInfo = {
        name: studentName,
        id: studentId
      }
      this.$http
        .get(`${this.$hostUrl}calls?student_id=${studentId}`)
        .then((response) => {
          this.calls = response.data.data
        })
      this.showModal = true
    },
    exportTableToExcel (tableID, filename = '') {
      console.log('export excel all')
      var wb = XLSX.utils.table_to_book(document.getElementById(tableID))
      var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      var blob = new Blob([wbout], { type: 'application/octet-stream' })
      var url = URL.createObjectURL(blob)
      var a = document.createElement('a')
      document.body.appendChild(a)
      a.href = url
      a.download = filename + '.xlsx'
      a.click()
      setTimeout(function () {
        URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 0)
    },
    getSubjects ($event) {
      this.totalStudents = 0
      this.subjectAttendance = []
      this.currentPage = 1
      this.pageNumber = this.currentPage
      this.selectedSubject = ''
      this.$http
        .get(`${this.$hostUrl}grade/${$event.id}/subjects`)
        .then((response) => {
          this.subjects = response.data.data
        })
    },
    filterData () {
      if (!this.selectedGrade) {
        swal(' يجب ادخال الفرقة ')
        return
      }

      let subjectIds = ''
      if (this.selectedSubject) {
        const selectedSubjects = this.selectedSubject?.map(value => value.id) || []
        subjectIds = JSON.stringify(selectedSubjects).replace(/\s/g, '')
      }
      const page = this.pageNumber !== '' ? parseInt(this.pageNumber, 10) : 1
      this.fetchAttendanceData(this.selectedGrade.id, subjectIds, page)
    },

    paginate (action) {
      if (!this.selectedGrade) {
        return
      }

      let subjectIds = ''
      if (this.selectedSubject) {
        const selectedSubjects = this.selectedSubject?.map((value) => value.id) || []
        subjectIds = JSON.stringify(selectedSubjects).replace(/\s/g, '')
      }

      let page = this.currentPage

      if (action === 'prev') {
        page = Math.max(1, page - 1) // Decrement page number, but keep it within bounds
      } else if (action === 'next') {
        page = Math.min(this.allPages, page + 1) // Increment page number, but keep it within bounds
      } else {
        page = parseInt(action, 10) // Go to the specified page
      }
      this.pageNumber = page.toString()
      this.fetchAttendanceData(this.selectedGrade.id, subjectIds, page)
    },

    fetchAttendanceData (gradeId, subjectIds, page) {
      const url = `${this.$hostUrl}attendance-report?grade_id=${gradeId}&subject_ids=${subjectIds}&page=${page}`

      this.$http.get(url)
        .then(response => {
          this.subjectAttendance = response.data.data.data
          this.currentPage = response.data.data.current_page
          this.allPages = response.data.data.last_page
          this.totalStudents = response.data.data.total
          const totalVisiblePages = 10 // Adjust the number of visible pages as per your requirement
          const startPage = Math.max(1, this.currentPage - Math.floor(totalVisiblePages / 2))
          const endPage = Math.min(this.allPages, startPage + totalVisiblePages - 1)
          this.visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
        })
    },
    getPage () {
      let subjectIds = ''
      if (this.selectedSubject) {
        const selectedSubjects = this.selectedSubject?.map(value => value.id) || []
        subjectIds = JSON.stringify(selectedSubjects).replace(/\s/g, '')
      }
      const page = parseInt(this.pageNumber, 10)
      if (!isNaN(page)) {
        this.fetchAttendanceData(this.selectedGrade.id, subjectIds, page)
      }
    },
    printReport (report) {
      $(
        '.dataTables_filter,.dataTables_length,.pagination,.dataTables_info'
      ).hide()
      $('#add-call,#add-call-btn').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css'
          // `${basePath}/print.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }
      setTimeout(this.$htmlToPaper('print-subject', options), 10000)
      $(
        '.dataTables_filter,.dataTables_length,.pagination,.dataTables_info'
      ).show()
      $('#add-call,#add-call-btn').show()
    }
  }
}
</script>
<style scoped>
.count-span {
  width: 18%;
  background: #abdcae;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  padding: 1px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
}
.count-0 {
  width: 18%;
  background: #ff00006b;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  padding: 1px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
}
.width{
  width:100% !important
}
</style>
