
<template>
  <div class="container-fluid py-4 all-students">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 ">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
            <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3">الطلاب</h6>
              </div>
               <div class="col-lg-6">
                  <!-- <router-link to="/students/change">

               <span class="badge badge-sm new-student"><i class="fas fa-exchange-alt"></i> تغيير الفرقة </span>
                 </router-link> -->
                 <router-link to="/students/create">

               <span class="badge badge-sm new-student"><i  class="fas fa-user-plus"></i>إضافة طالب </span>
                 </router-link>

               </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <!-- filters -->
            <div class="row align-center padding" >
              <div class="mb-3 col-lg-3" v-if="!facultyCenter">
                <label> الفرقة</label>
                <multiselect
                    class='required form-control'
                    id='grade'
                    v-model="selectedGrade"
                    :options='grades'
                    :searchable='true'
                    :close-on-select='true'
                    placeholder=" اختر الفرقة"
                    label='name'
                    :custom-label="customLabel"
                    track-by='id'
                    required='required'
                    :internal-search="true"
                    >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
              </div>
                 <div class=" bottom  mb-4 col-lg-2 ">
                   <label> الطلاب المتوقفين</label>
                   <div class="input-group input-group-outline null">
                    <select
                      class="form-control"
                      v-model="studentStatus"
                      required
                    >
                      <option selected value="0">الحاليين</option>
                      <option  value="1">المتوقفين</option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                  </div>
                  <div class="mb-3 col-lg-3">
                    <label> رقم الطالب</label>
                    <vmd-input
                      id="student-number"
                      type="text"
                      placeholder="رقم الطالب  "
                      @keyup.enter="filterData()"
                      v-model="studentNumber"
                    />
                  </div>
                    <div class="text-center col-lg-2 ">
                  <vmd-button
                    class="my-4 mb-4 width"
                    variant="gradient"
                    color="info"
                    @click="filterData()"
                    >  فلتر</vmd-button
                  >
                </div>
                  <div class="text-center col-lg-2 ">
                  <vmd-button
                    class="my-4 mb-4 width"
                    variant="gradient"
                    color="danger"
                    @click="clearData()"
                    >  مسح</vmd-button
                  >
                </div>
            </div>
            <div class="row align-center ex-row">

            </div>
          <div class="row">
            <vdtnet-table style="direction:rtl" class=" table-striped table  table-bordered2 text-center "
            :jquery="jq"
            ref="table"
            :fields="fields"
            :opts="options"
            @show="doAlertShow"
            @edit="doAlertEdit"
            @register="doAlertRegister"
            @reserve='doAlertReserve'
            @delete="doAlertDelete"
            @activeDevice="doAlertActivate"
            @deactivateDevice="doAlertDectivate"
            @activeCode='doAlertActiveCode'
            @reloaded="doAfterReload"
            @table-creating="doCreating"
            @table-created="doCreated"
          >
          </vdtnet-table>
          </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <vue-final-modal
      v-model="showModal"
      name="example"
      classes="modal-container course-modal"
      content-class="modal-content"
    >
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3"> حجز في مجموعة مواد للطالب: {{studentName}}</h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <form @submit="reserveSubjects" role="form" class="text-start mt-3">
                <div class="row modal-row">
                    <div class="mb-3 col-lg-5" >
                    <label>
                     اختر المواد <span class="red">*</span><span color="danger" class="error">{{
                    }}</span></label>
                    <div class="mb-3 ">
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedSubject"
                        :options='subjects'
                        :searchable='true'
                        :multiple="true"
                        :close-on-select='true'
                        placeholder=" اختر المواد"
                        label='name'
                        :custom-label="customLabel"
                        track-by='name'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                </div>
                </div>
                <div class="row">
                  <div class="text-center col-lg-6">
                    <vmd-button
                      class="my-4 mb-4  w-75"
                      variant="gradient"
                      color="info"
                    >
                      حفظ</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-6">
                    <vmd-button
                      type="reset"
                      class="my-4 mb-4  w-75"
                      variant="gradient"
                      color="danger"
                      @click="showModal = false"
                    >
                      إلغاء
                    </vmd-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import VmdInput from '@/components/VmdInput.vue'
import VdtnetTable from 'vue-datatables-net'
import VmdButton from '@/components/VmdButton.vue'
// import VmdInput from '@/components/VmdInput.vue'
import { VueFinalModal } from 'vue-final-modal'
import $ from 'jquery'
import swal from 'sweetalert'
let selectedGrade = ''
let selectedGroup = ''
let studentStatus = 0
let studentNumber = ''
export default {
  name: 'AllStudents',
  components: {
    VmdInput,
    VdtnetTable,
    VmdButton,
    // VmdInput,
    VueFinalModal

  },
  data () {
    return {
      showModal: false,
      facultyCenter: false,
      studentName: '',
      studentId: '',
      grades: [],
      groups: [],
      faculties: [],
      universities: [],
      subjects: [],
      selectedGrade: selectedGrade,
      selectedGroup: selectedGrade,
      studentStatus: studentStatus,
      studentNumber: studentNumber,
      selectedSubject: '',
      pagination: {
        rowsPerPage: 30
      },
      jq: $,
      options: {
        ajax: {
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + localStorage.token
            )
          },
          url: `${this.$hostUrl}students`,
          data: function (d) {
            d.grade_id = selectedGrade
            d.group_id = selectedGroup
            d.discontinue = studentStatus
            console.log('studentNumber', studentNumber)
            d.number = studentNumber
          }
        },
        buttons: [
          {
            extend: 'print',
            text: '',
            titleAttr: 'طباعة',
            className: 'fas fa-print btn-primary',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5]
            },
            customize: function (win) {
              $(win.document.body).css('direction', 'rtl')
            }
          },
          {
            extend: 'copy',
            text: '',
            className: 'fas fa-copy btn-warning',
            titleAttr: 'نسخ',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          },
          {
            extend: 'excel',
            text: '',
            titleAttr: 'إكسل',
            className: 'fas fa-table btn-success',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          }
        ],
        dom:
          "<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6 added'l>>" +
          "<'row'<'col-sm-12'Btr>>" +
          "<'row'<'col-sm-12 col-md-6'p><'col-sm-12 col-md-6'i>>",
        paging: true,
        language: {
          url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Arabic.json',
          processing: '<i class="fa fa-spinner fa-spin" style="font-size:40px;color:#7aeb7f"></i>'
        },
        responsive: false,
        processing: true,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        ordering: true,
        lengthChange: true,
        serverSide: true,
        order: [[5, 'desc']],
        fixedHeader: true,
        saveState: true,
        select: false
      },
      fields: {
        number: {
          label: 'رقم الطالب',
          sortable: true,
          searchable: true
        },
        full_name: {
          label: 'اسم الطالب',
          sortable: true,
          searchable: true
        },
        mobile: {
          label: ' رقم الهاتف',
          sortable: true,
          searchable: true
        },
        grade: {
          label: 'الفرقة ',
          sortable: true,
          searchable: true
        },
        created_at: {
          label: 'تاريخ التسجيل',
          sortable: true,
          searchable: true
        },
        courses: {
          label: ' الدورات',
          sortable: true,
          searchable: true,
          isLocal: true,
          defaultOrder: 'desc',
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="register"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm btn-warning">تسجيل</span></a>'
        },
        reservation: {
          label: '  المواد',
          sortable: true,
          searchable: true,
          defaultOrder: 'desc',
          isLocal: true,
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="reserve"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm btn-secondary">حجز</span></a>'
        },
        device: {
          label: 'فتح / قفل الجهاز',
          sortable: true,
          searchable: true,
          isLocal: true,
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="register"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm btn-warning">تسجيل</span></a>'
        },
        verified: {
          label: ' تفعيل الرقم',
          isLocal: true,
          sortable: true,
          searchable: true,
          render: this.Verify,
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="activeCode"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm btn-info">تفعيل</span></a>'
        },
        actions: {
          isLocal: true,
          label: 'العمليات',
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="edit"><i class="fas fa-edit" title="تعديل"></i> </a>' +
             '&ensp;<a href="javascript:void(0);" data-action="show"><i class="fas fa-eye" title="عرض"></i> </a>'
        }
      }
    }
  },
  created () {
    const grades = []
    this.$http.get(`${this.$hostUrl}grades`).then((response) => {
      $.each(response.data.data, function (key, val) {
        grades.push({
          name: val.name,
          id: val.id
        })
      })
      this.grades = grades
    })

    this.changProcessingTxt()
  },
  methods: {
    Verify (data, type, row, meta) {
      if (data === true) {
        data = 'مفعل'
      } else if (data === false) {
        data = '&ensp;<a href="javascript:void(0);" data-action="activeCode"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm btn-info">تفعيل</span></a>'
      } else {
        data = 'غير مسجل'
      }
      return data
    },
    changProcessingTxt () {
      console.log($('.dataTables_processing'))
    },
    doAlertShow (data) {
      this.$router.push({
        path: `/students/${data.id}/show`,
        params: { id: data.id }
      })
    },
    doAlertEdit (data) {
      this.$router.push({
        path: `/students/${data.id}/edit`,
        params: { id: data.id }
      })
    },
    doAlertRegister (data) {
      this.$router.push({
        path: `/students/${data.id}/course-register`,
        params: { id: data.id }
      })
    },
    doAlertReserve (data) {
      this.showModal = true
      this.studentName = data.full_name
      this.studentId = data.id
      const subjects = []
      this.$http.get(`${this.$hostUrl}subject/filter?student_id=${data.id}`).then((response) => {
        $.each(response.data.data, function (key, val) {
          subjects.push({
            name: val.name,
            id: val.id
          })
        })
        this.subjects = subjects
      })
    },
    // filters
    filterData () {
      selectedGrade = this.selectedGrade.id
      selectedGroup = this.selectedGroup.id
      studentStatus = this.studentStatus
      studentNumber = this.studentNumber
      const table = this.$refs.table
      table.reload()
    },
    clearData () {
      this.selectedGrade = ''
      this.selectedGroup = ''
      this.studentStatus = 0
      this.studentNumber = ''
      this.filterData()
      // const table = this.$refs.table
      // table.reload()
    },
    searchNumber () {
      studentNumber = this.studentNumber
      const table = this.$refs.table
      table.reload()
    },
    async reserveSubjects (e) {
      e.preventDefault()

      const formData = new FormData()
      formData.append('student_id', this.studentId)
      const allSelectedSubjects = []
      $.each(this.selectedSubject, function (key, value) {
        allSelectedSubjects.push({ id: value.id })
      })
      for (let i = 0; i < allSelectedSubjects.length; i++) {
        formData.append('subject_id[]', allSelectedSubjects[i].id)
      }

      this.errors = await this.postRequest(formData, this.$hostUrl + 'reservations', '')
      if (!this.errors.extension) {
        this.showModal = false
      }
      this.selectedSubject = []
    },
    async doAlertActivate (data) {
      swal({
        title: 'هل انت متأكد ؟',
        text: 'بمجرد الموافقة ، سيتم  فتح الجهاز !',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .post(
              `${this.$hostUrl}student/${data.id}/activate-device`
            )
            .then((response) => {
              if (!response.data.error) {
                swal({ title: response.data.message, buttons: 'تم' })
                const table = this.$refs.table
                setTimeout(() => {
                  table.reload()
                }, 1500)
              }
            }).catch(function (error) {
              if (Object.prototype.hasOwnProperty.call(error, 'response')) {
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
            })
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    async doAlertDectivate (data) {
      swal({
        title: 'هل انت متأكد ؟',
        text: '   بمجرد الموافقة سيتم قفل الجهاز!',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .post(
              `${this.$hostUrl}student/${data.id}/deactivate-device`
            )
            .then((response) => {
              if (!response.data.error) {
                swal({ title: response.data.message, buttons: 'تم' })
                const table = this.$refs.table
                setTimeout(() => {
                  table.reload()
                }, 1500)
              }
            }).catch(function (error) {
              if (Object.prototype.hasOwnProperty.call(error, 'response')) {
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
            })
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    doAlertActiveCode (data) {
      swal({
        title: 'هل انت متأكد ؟',
        text: ' بمجرد الموافقة سيتم تفعيل رقم الطالب!',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .post(
              `${this.$hostUrl}student/${data.id}/verify`
            )
            .then((response) => {
              if (!response.data.error) {
                swal({ title: response.data.message, buttons: 'تم' })
                const table = this.$refs.table
                setTimeout(() => {
                  table.reload()
                }, 1500)
              }
            }).catch(function (error) {
              if (Object.prototype.hasOwnProperty.call(error, 'response')) {
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
            })
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    doAfterReload (data, table) {
      console.log('data reloaded')
    },
    // eslint-disable-next-line no-unused-vars
    doCreating (comp, el) {

    },
    // eslint-disable-next-line no-unused-vars
    doCreated (comp) {
      console.log('created', this.$hostUrl)
    },
    doSearch () {
      this.$refs.table.search(this.quickSearch)
    },
    doExport (type) {
      const parms = this.$refs.table.getServerParams()
      parms.export = type
    },
    formatCode (zipcode) {
      return zipcode.split('-')[0]
    }
  }
}
</script>
<style >
.ps-3 {
    padding-right: 1rem !important;}
    .me-3 {
    margin-left: 1rem !important;}
      .fa-edit{
      padding-left: 12px;
    }
    .fa-eye{
      font-size:17px
    }
    /* .table-bordered2 > :not(caption) > * > *{
    border: 1px solid red !important
} */
/* .table-bordered2 tr{
  border-width: unset !important
} */
/* .table-bordered2 .table{
    border: 1px solid red !important
} */
.all-students #vdtnetable1{
  border: 1px solid #e0dadd !important;
      border-collapse: separate;
    border-spacing: 1px;
    background: #e0dadd !important;
}
.all-students table{
  border: 1px solid #e0dadd !important;
      border-collapse: separate;
    border-spacing: 1px;
    background: #e0dadd !important;
}

.all-students table.dataTable{
 border: 1px solid #e0dadd !important;
      border-collapse: separate;
    border-spacing: 1px;
    background: #e0dadd !important;;
}
    /* table{
      border-spacing: unset !important;
      border-width:unset !important;
      border-color:#bcb9b9 !important;
      border-bottom-color:#222 !important
    }
   .table-bordered2 {
      border-width:unset !important;
    }
    .bottom{
      margin-bottom: 24px !important;
    }
    .w-75{
      width:75% !important
    }*/
</style>
