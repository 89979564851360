<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  إضافة طالب</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit="createStudent" role="form" class="text-start mt-3">
                       <div class="row">
                <div class="mb-3 col-lg-6">
                    <label>اسم الطالب<span class="red">*</span>
                    <span color='danger' class="error">{{errors.full_name?errors.full_name[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   label="الاسم  بالعربي"
                   v-model='student.fullName'
                    required
                   :invalidFeedback="errors.name"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label> رقم الهاتف<span class="red">*</span>
               <span color='danger' class="error">{{errors.mobile?errors.mobile[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   label="  رقم الهاتف (11 خانه فقط)"
                   v-model='student.mobile'
                    required
                  />
                </div>
                </div>
                <div class='row'>
                <div class="mb-3 col-lg-6">
                      <label class='unrequired'>  البريد الإلكتروني<span class="green">(اختياري)</span></label>
                       <span color='danger' class="error"></span>
                  <vmd-input
                    id="email"
                    type="email"
                    label=" البريد الإلكتروني"
                    v-model='student.email'
                    :invalidFeedback="errors.email"
                  />
                </div>
                   <div class="mb-3 col-lg-6">
                    <label class='unrequired'> رابط الفيسبوك<span class="green">(اختياري)</span>
                      <span color='danger' class="error">{{errors.facebook_url?errors.facebook_url[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   label=" رابط الفيسبوك"
                    v-model='student.facebookUrl'
                  />
                </div>
                       </div>
                           <div class='row'>
                     <div class="mb-3 col-lg-6">
                    <label> الفرقة <span class="red">*</span>
                    <span color='danger' class="error">{{errors.grade_id?errors.grade_id[0]:''}}</span>
                    </label>
                      <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedGrade"
                        :options='grades'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الفرقة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                <div class="mb-3 col-lg-6">
                      <label>  المجموعة <span class="red">*</span>
                      <span color='danger' class="error">{{errors.group_id?errors.group_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedGroup"
                        :options='groups'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر المجموعة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                       </div>
                              <div class='row'>
                   <div class="mb-3 col-lg-6">
                      <label>  المناطق <span class="red">*</span>
                      <span color='danger' class="error">{{errors.area_id?errors.area_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='area'
                        v-model=" selectedArea"
                        :options='areas'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="اختر المنطقة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                <div class="mb-3 col-lg-6">
                      <label>  المصدر <span class="red">*</span>
                      <span color='danger' class="error">{{errors.source_id?errors.source_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='area'
                        v-model=" selectedSource"
                        :options='sources'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="اختر المصدر"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                       </div>
                       <div class="row" v-if="selectedSource.id === '2c481399-60f5-11ee-bd6a-f23c936035ca'">
                        <div class="mb-3 col col-5">
                      <label >  المبيعات <span class="red">*</span>
                      <span color='danger' class="error">{{errors.area_id?errors.area_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='area'
                        v-model=" selectedSales"
                        :options='salespeople'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="اختر الاسم"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                      </div>
                      <div class="col-1 col">
                          <span style="padding:15px !important" @click="showModal = true" title="اضافةشخص مبيعات"  class="badge li-h badge-sm btn  new-student">
                             <i  class="fas fa-user-plus"></i></span>
                      </div>
                       </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                  type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > مسح</vmd-button
                  >
                </div>
                       </div>
              </form>
        </div>
      </div>
      </div>
    </div>
     <!-- modal -->
    <vue-final-modal
      v-model="showModal"
      name="example"
      classes="modal-container course-modal"
      content-class="modal-content"
    >
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3"> اضافة موظف مبيعات</h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <form @submit="addSalesPerson" role="form" class="text-start mt-3">
                <div class="row modal-row">
                  <div class="mb-3 col-lg-12" >
                    <label>
                      الاسم <span class="red">*</span><span color="danger" class="error">{{
                    }}</span></label>
                    <div class="mb-3 ">
                       <vmd-input
                    id=""
                    type="text"
                   label="الاسم  بالعربي"
                   v-model='salesPersonN'
                    required
                   :invalidFeedback="errors.name"
                  />
                </div>
                </div>
                </div>
                <div class="row">
                  <div class="text-center col-lg-6">
                    <vmd-button
                      class="my-4 mb-4  w-75"
                      variant="gradient"
                      color="info"
                    >
                      حفظ</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-6">
                    <vmd-button
                      type="reset"
                      class="my-4 mb-4  w-75"
                      variant="gradient"
                      color="danger"
                      @click="showModal = false"
                    >
                      إلغاء
                    </vmd-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
    </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import { VueFinalModal } from 'vue-final-modal'
import $ from 'jquery'
import swal from 'sweetalert'
export default {
  name: 'AddStudent',
  components: {
    VmdInput,
    VmdButton,
    VueFinalModal
  },
  data () {
    return {
      showModal: false,
      student: {},
      groups: [
        { id: '5d2a4482-5848-43c4-b30e-7d69ec2efcc5', name: '4' },
        { id: '7cdf25a8-4837-4480-98ae-2ed752287ad4', name: '2' },
        { id: '9a2f0d62-093c-4e38-a3f9-8c01cbf35739', name: '3' },
        { id: 'fff0c94b-3406-494e-9cce-516b021fbc39', name: '1' }],
      grades: [
        { id: '5de6043a-bc65-437b-bb57-0cbf69573dd7', name: 'الفرقة الرابعة' },
        { id: 'ab1fb157-188b-45ea-b046-302ff1221253', name: 'الفرقة الأولى' },
        { id: 'd96e432f-9a95-4bdb-9a87-bceaf981fe0e', name: 'الفرقة الثانية' },
        { id: 'f4a80ded-3d68-4795-84b0-5692daa74b78', name: 'الفرقة الثالثة' }],
      areas: [],
      sources: [],
      salespeople: [],
      selectedGroup: '',
      selectedGrade: '',
      selectedArea: '',
      selectedSource: '',
      selectedSales: '',
      salesPersonN: '',
      errors: []
    }
  },
  created () {
    const areas = []
    this.$http.get(`${this.$hostUrl}areas`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          areas.push({
            name: value.name,
            id: value.id
          })
        })
        this.areas = areas
      })
    const sources = []
    this.$http.get(`${this.$hostUrl}sources`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          sources.push({
            name: value.name,
            id: value.id
          })
        })
        this.sources = sources
      })
    const salespeople = []
    this.$http.get(`${this.$hostUrl}salespeople`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          salespeople.push({
            name: value.name,
            id: value.id
          })
        })
        this.salespeople = salespeople
      })
  },
  methods: {
    async addSalesPerson (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('name', this.salesPersonN)

      try {
        this.errors = await this.postRequest(formData, this.$hostUrl + 'salespeople', '')
        const response = await this.$http.get(`${this.$hostUrl}salespeople`)

        response.data.data.forEach((value) => {
          this.salespeople.push({
            name: value.name,
            id: value.id
          })
        })

        this.showModal = false
      } catch (error) {
        // Handle error here
      }
    },
    async createStudent (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.student.fullName)) {
        swal('اسم الطالب يجب ان يحتوي على حروف')
        return 0
      }
      if (!this.selectedSource) {
        this.errors.source_id = []
        this.errors.source_id.push(' اختيار المصدر مطلوب')
        return 0
      }
      formData.append('full_name', this.student.fullName)
      if (this.student.email) {
        formData.append('email', this.student.email)
      }
      if (this.student.facebookUrl) {
        formData.append('facebook_url', this.student.facebookUrl)
      }
      formData.append('source_id', this.selectedSource.id)
      if (this.selectedSource.id === '2c481399-60f5-11ee-bd6a-f23c936035ca') {
        formData.append('salesperson_id', this.selectedSales.id)
      }
      formData.append('mobile', this.student.mobile)
      formData.append('user_name', this.student.username)
      formData.append('group_id', this.selectedGroup.id)
      formData.append('grade_id', this.selectedGrade.id)
      formData.append('area_id', this.selectedArea.id)
      this.errors = await this.postRequest(formData, this.$hostUrl + 'students', '/students')
    }
  }
}
</script>
<style >
.error{
  color:#f44335 !important;
  font-size:13px;
   padding-right: 25px;
}
.unrequired{
    display: flex;
    gap: 5px;
}
.li-h{
  line-height:87px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
 rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
    padding: 20px !important;
    margin-top: 26px !important;
    margin-left: unset !important
}

</style>
