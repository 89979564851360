<template>
  <div class="container-fluid py-4 ">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">
                    {{ $t("message.customers") }}
                  </h6>
                </div>
                <div class="col-lg-6">
                  <!-- <router-link to="/students/change">

               <span class="badge badge-sm new-student"><i class="fas fa-exchange-alt"></i> تغيير الفرقة </span>
                 </router-link> -->
                  <router-link to="/students/create">
                    <span class="badge badge-sm new-student"
                      ><i class="fas fa-user-plus"></i>
                      {{ $t("message.createCustomer") }}
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="row align-center ex-row padding">
                <div class="mb-3 col-lg-4">
                    <label> {{$t('message.enterNum')}}</label>
                    <vmd-input
                      id="student-number"
                      type="text"
                      :placeholder="$t('message.enterNum')"
                      @keyup.enter="filterData()"
                      v-model="studentNumber"
                    />
                  </div>
                   <div class="mb-3 col-lg-4" v-if="!facultyCenter">
                <label> {{$t('message.job')}}</label>
                <multiselect
                    class='required form-control'
                    id='grade'
                    v-model="selectedStatus"
                    :options='status'
                    :searchable='true'
                    :close-on-select='true'
                    :placeholder="$t('message.select')"
                    label='name'
                    :custom-label="customLabel"
                    track-by='id'
                    required='required'
                    :internal-search="true"
                    >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
              </div>
              <div class="mb-3 col-lg-4">
                      <label> {{$t('message.source')}}
                      <span color='danger' class="error">{{errors.group_id?errors.group_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedSource"
                        :options='sources'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.select') "
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                    <div class="text-center col-lg-2 ">
                  <vmd-button
                    class="my-4 mb-4 width"
                    variant="gradient"
                    color="info"
                    @click="filterData()"
                    >  {{$t('message.filter')}}</vmd-button
                  >
                </div>
                  <div class="text-center col-lg-2 ">
                  <vmd-button
                    class="my-4 mb-4 width"
                    variant="gradient"
                    color="danger"
                    @click="clearData()"
                    >  {{$t('message.delete')}}</vmd-button
                  >
                </div>
            </div>
            <div class="row">
              <vdtnet-table
                style="direction: rtl"
                class="table-striped table table-bordered2 text-center"
                :jquery="jq"
                ref="table"
                :fields="fields"
                :opts="options"
                @show="doAlertShow"
                @edit="doAlertEdit"
                @register="doAlertRegister"
                @level="doAlertLevel"
                @delete="doAlertDelete"
                @activeDevice="doAlertActivate"
                @deactivateDevice="doAlertDectivate"
                @getCalls="doAlertCalls"
                @activeCode="doAlertActiveCode"
                @reloaded="doAfterReload"
                @table-creating="doCreating"
                @table-created="doCreated"
              >
              </vdtnet-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <vue-final-modal
      v-model="showModal"
      name="example"
      classes="modal-container course-modal placement-modal"
      content-class="modal-content"
    >
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                    {{$t('message.placement')}} : {{ studentName }}
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="row modal-row">
                <h6 style="padding-right: 40px; text-align:center" class="text-warning">
                  {{$t('message.leastFields')}}
                </h6>
              </div>
              <form
                @submit="determineLevel"
                role="form"
                class="text-start mt-3"
              >
                <div class="row modal-row">
                  <div class="mb-3 col-lg-5">
                    <label>  {{$t('message.assessor')}} <span class="red">*</span></label>
                    <multiselect
                      class="required form-control"
                      id="grade"
                      v-model="selectedInstructor"
                      :options="instructors"
                      :searchable="true"
                      :close-on-select="true"
                      :placeholder="$t('message.select')"
                      label="name"
                      :custom-label="customLabel"
                      track-by="id"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="mb-3 col-lg-5">
                    <label>  {{$t('message.subject')}}<span class="red">*</span> </label>
                    <multiselect
                      class="required form-control"
                      id="grade"
                      v-model="selectedSubject"
                      :options="subjects"
                      :searchable="true"
                      :close-on-select="true"
                      :placeholder=" $t('message.select') "
                      label="name"
                      :custom-label="customLabel"
                      track-by="id"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div class="row modal-row">
                    <div class="   mb-4 col-lg-5 ">
                   <label>  {{$t('message.type')}}<span class="red">*</span></label>
                   <div class="input-group input-group-outline null">
                    <select
                      class="form-control"
                      v-model="level.type"
                      required
                    > <option  value="to_start">To Start</option>
                      <option selected value="end_of_level">End Of Level</option>
                      <option  value="IELTS">IELTS</option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                  </div>
                  <div class="mb-3 col-lg-5">
                    <label>
                        {{$t('message.listening')}}
                      <span color="danger" class="error">{{}}</span
                    ></label>
                    <vmd-input
                      id=""
                      type="text"
                      :label=" $t('message.degree') "
                      v-model="level.listening"
                    />
                  </div>
                </div>
                <div class="row modal-row">
                  <div class="mb-3 col-lg-5">
                    <label>
                       {{$t('message.speaking')}}<span color="danger" class="error">{{}}</span
                    ></label>
                    <vmd-input
                      id=""
                      type="text"
                      :label="$t('message.degree')"
                      v-model="level.speaking"
                    />
                  </div>
                  <div class="mb-3 col-lg-5">
                    <label>
                        {{$t('message.reading')}} <span color="danger" class="error">{{}}</span
                    ></label>
                    <vmd-input
                      id=""
                      type="text"
                      :label="$t('message.degree') "
                      v-model="level.reading"
                    />
                  </div>
                </div>
                <div class="row modal-row">
                   <div class="mb-3 col-lg-5">
                    <label>
                        {{$t('message.writing')}} <span color="danger" class="error">{{}}</span
                    ></label>
                    <vmd-input
                      id=""
                      type="text"
                      :label="$t('message.degree')"
                      v-model="level.writing"
                    />
                  </div>
                  <div class="mb-3 col-lg-5">
                    <label>
                        {{$t('message.G&A')}}
                      <span color="danger" class="error">{{}}</span
                    ></label>
                    <vmd-input
                      id=""
                      type="text"
                      :label="$t('message.degree')"
                      v-model="level.grammer"
                    />
                  </div>
                </div>
                <div class="row modal-row">
                  <div class="mb-3 col-lg-5">
                    <label>
                        {{$t('message.addFile')}} (pdf)
                      <span color="danger" class="error">{{}}</span
                    ></label>
                    <vmd-input id="" type="file" @change="onFileChange" />
                  </div>
                  <div class="mb-3 col-lg-5">
                    <label>
                        {{$t('message.note')}}
                      <span  class="green">({{$t('message.optional')}})</span
                    ></label>
                    <div class="input-group input-group-outlin">
                   <textarea class="form-control" v-model="level.note"></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="text-center col-lg-6">
                    <vmd-button
                      class="my-4 mb-4 w-75"
                      variant="gradient"
                      color="info"
                    >
                      {{$t('message.save')}}</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-6">
                    <vmd-button
                      type="reset"
                      class="my-4 mb-4 w-75"
                      variant="gradient"
                      color="danger"
                      @click="showModal = false"
                    >
                      {{$t('message.cancel')}}
                    </vmd-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
    <div class="add-student">
        <CreateCall
      v-model="showCallModal"
      @hide-modal="showCallModal = false"
      :studentInfo="studentInfo"
      :calls="studentCalls"
      @update-data="updateData"
    ></CreateCall>
    </div>
  </div>
</template>

<script>
import VdtnetTable from 'vue-datatables-net'
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'
import { VueFinalModal } from 'vue-final-modal'
import CreateCall from '@/components/CreateCall.vue'
import $ from 'jquery'
import swal from 'sweetalert'
let studentStatus = 0
let studentNumber = ''
let selectedSource = ''
let selectedStatus = ''
export default {
  name: 'Clients',
  components: {
    VdtnetTable,
    VmdButton,
    VmdInput,
    CreateCall,
    VueFinalModal
  },
  data () {
    return {
      showModal: false,
      level: {},
      showCallModal: false,
      studentCalls: [],
      studentInfo: {},
      file: '',
      facultyCenter: false,
      studentName: '',
      studentId: '',
      grades: [],
      groups: [],
      faculties: [],
      universities: [],
      subjects: [],
      instructors: [],
      selectedStatus: '',
      status: [],
      sources: [],
      selectedSource: '',
      studentStatus: studentStatus,
      studentNumber: studentNumber,
      selectedInstructor: '',
      selectedSubject: '',
      errors: [],
      pagination: {
        rowsPerPage: 30
      },
      jq: $,
      options: {
        ajax: {
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + localStorage.token
            )
          },
          url: `${this.$hostUrl}students`,
          data: function (d) {
            d.discontinue = studentStatus
            d.number = studentNumber
            d.source_id = selectedSource
            d.status_id = selectedStatus
          }
        },
        buttons: [
          {
            extend: 'print',
            text: '',
            titleAttr: 'طباعة',
            className: 'fas fa-print btn-primary',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5]
            },
            customize: function (win) {
              $(win.document.body).css('direction', 'rtl')
            }
          },
          {
            extend: 'copy',
            text: '',
            className: 'fas fa-copy btn-warning',
            titleAttr: 'نسخ',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          },
          {
            extend: 'excel',
            text: '',
            titleAttr: 'إكسل',
            className: 'fas fa-table btn-success',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          }
        ],
        dom:
          "<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6 added'l>>" +
          "<'row'<'col-sm-12'Btr>>" +
          "<'row'<'col-sm-12 col-md-6'p><'col-sm-12 col-md-6'i>>",
        paging: true,
        language: {
          url: this.$t('message.url'),
          processing:
            '<i class="fa fa-spinner fa-spin" style="font-size:40px;color:#7aeb7f"></i>'
        },
        responsive: false,
        processing: true,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        ordering: true,
        lengthChange: true,
        serverSide: true,
        order: [[9, 'desc']],
        fixedHeader: true,
        saveState: true,
        select: false
      },
      fields: {
        number: {
          label: this.$t('message.id'),
          sortable: true,
          searchable: true
        },
        full_name: {
          label: this.$t('message.name'),
          sortable: true,
          searchable: true
        },
        mobile: {
          label: this.$t('message.mobile'),
          sortable: true,
          searchable: true
        },
        branch: {
          label: this.$t('message.branch'),
          sortable: true,
          searchable: true,
          render: (branch) => branch?.name || null
        },

        status: {
          label: this.$t('message.job'),
          sortable: true,
          searchable: true,
          isLocal: true,
          render: (status) => status?.name || null
        },
        source: {
          label: this.$t('message.source'),
          sortable: true,
          searchable: true,
          isLocal: true,
          render: (source) => source?.name || null
        },
        courses: {
          label: this.$t('message.courses'),
          sortable: true,
          searchable: true,
          isLocal: true,
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="register"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm btn-warning">' +
            this.$t('message.register') +
            '</span></a>'
        },
        level: {
          label: this.$t('message.exam'),
          sortable: true,
          searchable: true,
          isLocal: true,
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="level"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm btn-secondary">' +
            this.$t('message.add') + '</span></a>'
        },
        addCall: {
          label: '  اضافة مكالمة ',
          sortable: true,
          searchable: true,
          isLocal: true,
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="getCalls"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm btn-info">اضافة</span></a>'
        },
        created_at: {
          label: this.$t('message.createdAt'),
          sortable: true,
          searchable: true
        },
        // device: {
        //   label: 'فتح / قفل الجهاز',
        //   sortable: true,
        //   searchable: true,
        //   isLocal: true,
        //   defaultContent:
        //     '&ensp;<a href="javascript:void(0);" data-action="register"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm btn-warning">تسجيل</span></a>'
        // },
        // verified: {
        //   label: ' تفعيل الرقم',
        //   isLocal: true,
        //   sortable: true,
        //   searchable: true,
        //   render: this.Verify,
        //   defaultContent:
        //     '&ensp;<a href="javascript:void(0);" data-action="activeCode"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm btn-info">تفعيل</span></a>'
        // },
        actions: {
          isLocal: true,
          label: this.$t('message.operations'),
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="edit"><i class="fas fa-edit" title="تعديل"></i> </a>' +
            '&ensp;<a href="javascript:void(0);" data-action="show"><i class="fas fa-eye" title="عرض"></i> </a>'
        }
      }
    }
  },
  created () {
    this.changProcessingTxt()
    const instructors = []
    this.$http.get(`${this.$hostUrl}instructors`).then((response) => {
      $.each(response.data.data, function (key, val) {
        instructors.push({
          name: val.name,
          id: val.id
        })
      })
      this.instructors = instructors
    })
    this.$http.get(`${this.$hostUrl}statuses`)
      .then((response) => {
        this.status = response.data.data.map(val => ({
          name: val.name,
          id: val.id
        }))
      })
    this.$http.get(`${this.$hostUrl}sources`)
      .then((response) => {
        this.sources = response.data.data.map(val => ({
          name: val.name,
          id: val.id
        }))
      })
  },
  methods: {
    Verify (data, type, row, meta) {
      if (data === true) {
        data = 'مفعل'
      } else if (data === false) {
        data =
          '&ensp;<a href="javascript:void(0);" data-action="activeCode"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm btn-info">تفعيل</span></a>'
      } else {
        data = 'غير مسجل'
      }
      return data
    },
    changProcessingTxt () {
      console.log($('.dataTables_processing'))
    },
    doAlertShow (data) {
      this.$router.push({
        path: `/students/${data.id}/show`,
        params: { id: data.id }
      })
    },
    doAlertEdit (data) {
      this.$router.push({
        path: `/students/${data.id}/edit`,
        params: { id: data.id }
      })
    },
    doAlertRegister (data) {
      this.$router.push({
        path: `/students/${data.id}/course-register`,
        params: { id: data.id }
      })
    },
    getStatus (data) {
      return data?.name || null
    },
    getSource (data) {
      return data?.name || null
    },
    doAlertLevel (data) {
      this.showModal = true
      this.studentName = data.full_name
      this.studentId = data.id
      const subjects = []
      this.$http
        .get(`${this.$hostUrl}subject/filter?student_id=${data.id}`)
        .then((response) => {
          $.each(response.data.data, function (key, val) {
            subjects.push({
              name: val.name,
              id: val.id
            })
          })
          this.subjects = subjects
        })
    },
    // filters
    filterData () {
      studentStatus = this.studentStatus
      studentNumber = this.studentNumber
      selectedStatus = this.selectedStatus?.id || null
      selectedSource = this.selectedSource?.id || null
      const table = this.$refs.table
      table.reload()
    },
    clearData () {
      this.studentStatus = 0
      this.studentNumber = ''
      this.selectedSource = ''
      this.selectedStatus = ''
      this.filterData()
      // const table = this.$refs.table
      // table.reload()
    },
    searchNumber () {
      studentNumber = this.studentNumber
      const table = this.$refs.table
      table.reload()
    },
    getSubject ($event) {
      const subjects = []
      this.$http
        .get(
          `${this.$hostUrl}subject/filter?student_id=${this.studentId}&instructor_id=${$event.id}`
        )
        .then((response) => {
          $.each(response.data.data, function (key, val) {
            subjects.push({
              name: val.name,
              id: val.id
            })
          })
          this.subjects = subjects
        })
    },
    onFileChange (event, index) {
      this.file = event.target.files[0]
      // this.allPdfFiles[index].pdf_file = file
    },
    async determineLevel (e) {
      e.preventDefault()

      const formData = new FormData()

      const inputs = [
        { name: 'listening', value: this.level.listening },
        { name: 'speaking', value: this.level.speaking },
        { name: 'reading', value: this.level.reading },
        { name: 'writing', value: this.level.writing },
        { name: 'date', value: this.level.date },
        { name: 'g_a', value: this.level.grammer },
        { name: 'pdf_file', value: this.file }
      ]

      const validInputs = inputs.filter((input) => {
        return input.value !== undefined && input.value !== ''
      })
      if (validInputs.length < 1) {
        swal(this.$t('message.leastFields'))
        return 0
      }

      validInputs.forEach((input) => {
        formData.append(input.name, input.value)
      })
      formData.append('student_id', this.studentId)
      formData.append('instructor_id', this.selectedInstructor.id)
      formData.append('level_id', this.selectedSubject.id)
      formData.append('type', this.level.type)
      if (this.level.note) {
        formData.append('note', this.level.note)
      }

      this.errors = await this.postRequest(
        formData,
        this.$hostUrl + 'levels',
        ''
      )
      if (!this.errors.extension) {
        this.showModal = false
        this.level = {}
        this.selectedInstructor = {}
        this.selectedSubject = {}
      }
    },
    async doAlertActivate (data) {
      swal({
        title: 'هل انت متأكد ؟',
        text: 'بمجرد الموافقة ، سيتم  فتح الجهاز !',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .post(`${this.$hostUrl}student/${data.id}/activate-device`)
            .then((response) => {
              if (!response.data.error) {
                swal({ title: response.data.message, buttons: 'تم' })
                const table = this.$refs.table
                setTimeout(() => {
                  table.reload()
                }, 1500)
              }
            })
            .catch(function (error) {
              if (Object.prototype.hasOwnProperty.call(error, 'response')) {
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
            })
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    async doAlertDectivate (data) {
      swal({
        title: 'هل انت متأكد ؟',
        text: '   بمجرد الموافقة سيتم قفل الجهاز!',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .post(`${this.$hostUrl}student/${data.id}/deactivate-device`)
            .then((response) => {
              if (!response.data.error) {
                swal({ title: response.data.message, buttons: 'تم' })
                const table = this.$refs.table
                setTimeout(() => {
                  table.reload()
                }, 1500)
              }
            })
            .catch(function (error) {
              if (Object.prototype.hasOwnProperty.call(error, 'response')) {
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
            })
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    doAlertActiveCode (data) {
      swal({
        title: 'هل انت متأكد ؟',
        text: ' بمجرد الموافقة سيتم تفعيل رقم الطالب!',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .post(`${this.$hostUrl}student/${data.id}/verify`)
            .then((response) => {
              if (!response.data.error) {
                swal({ title: response.data.message, buttons: 'تم' })
                const table = this.$refs.table
                setTimeout(() => {
                  table.reload()
                }, 1500)
              }
            })
            .catch(function (error) {
              if (Object.prototype.hasOwnProperty.call(error, 'response')) {
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
            })
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    doAlertCalls (data) {
      this.studentInfo = {
        name: data.full_name,
        id: data.id
      }
      this.showCallModal = true
      this.$http
        .get(`${this.$hostUrl}calls?student_id=${data.id}`)
        .then((response) => {
          this.studentCalls = response.data.data
        })
    },
    updateData () {
      const table = this.$refs.table
      table.reload()
    },
    // eslint-disable-next-line no-unused-vars
    doAfterReload (data, table) {
      console.log('data reloaded')
    },
    // eslint-disable-next-line no-unused-vars
    doCreating (comp, el) {},
    // eslint-disable-next-line no-unused-vars
    doCreated (comp) {
      console.log('created', this.$hostUrl)
    },
    doSearch () {
      this.$refs.table.search(this.quickSearch)
    },
    doExport (type) {
      const parms = this.$refs.table.getServerParams()
      parms.export = type
    },
    formatCode (zipcode) {
      return zipcode.split('-')[0]
    }
  }
}
</script>
<style>
.ps-3 {
  padding-right: 1rem !important;
}
.me-3 {
  margin-left: 1rem !important;
}
.fa-edit {
  padding-left: 12px;
}
.fa-eye {
  font-size: 17px;
}
/* .table-bordered2 > :not(caption) > * > *{
    border: 1px solid red !important
} */
/* .table-bordered2 tr{
  border-width: unset !important
} */
/* .table-bordered2 .table{
    border: 1px solid red !important
} */
.all-students #vdtnetable1 {
  border: 1px solid #e0dadd !important;
  border-collapse: separate;
  border-spacing: 1px;
  background: #e0dadd !important;
}
.all-students table {
  border: 1px solid #e0dadd !important;
  border-collapse: separate;
  border-spacing: 1px;
  background: #e0dadd !important;
}

.all-students table.dataTable {
  border: 1px solid #e0dadd !important;
  border-collapse: separate;
  border-spacing: 1px;
  background: #e0dadd !important;

}
.placement-modal{
  top:16% !important
}
/* table{
      border-spacing: unset !important;
      border-width:unset !important;
      border-color:#bcb9b9 !important;
      border-bottom-color:#222 !important
    }
   .table-bordered2 {
      border-width:unset !important;
    }
    .bottom{
      margin-bottom: 24px !important;
    }
    .w-75{
      width:75% !important
    }*/
</style>
