<template>
  <div class="py-4 container-fluid dashboard2 ">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6 one">
             <router-link to='/courses'>
            <mini-statistics-card
              :title="{text:$t('message.courses'), value: statistics.courses }"
              detail=""
              :icon="{
                name: 'book',
                color: 'text-white',
                background: 'dark',
              }"
            />
             </router-link>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4 two">
             <router-link to='/subjects'>
            <mini-statistics-card
              :title="{text: $t('message.subjects'), value: statistics.subjects  }"
              detail=""
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'primary',
              }"
            />
             </router-link>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4 three">
             <router-link to='/teachers'>
            <mini-statistics-card
              :title="{text:$t('message.teachers'), value:statistics.instructors   }"
              detail=""
              :icon="{
                name: 'person',
                color: 'text-white',
                background: 'success',
              }"
            />
             </router-link>
          </div>
           <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4 four">
            <router-link to='/students'>
            <mini-statistics-card
              :title="{text:$t('message.customers'), value: statistics.students }"
              detail=""
              :icon="{
                name: 'group',
                color: 'text-white',
                background: 'info',
              }"
            />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row mb-4" >
      <div class="col">
         <div class="col-lg-11 col-md-6 mt-4 chart-col">
            <chart-holder-card
              title="الحضور اليوم"
              subtitle="(<span class='font-weight-bolder'>+15%</span>)   نسبة الحضور"
              update="تم التحديث منذ 4 دقائق"
              color="success"
            >
              <reports-line-chart
                :chart="{
                  labels: [
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                  ],
                  datasets: {
                    label: 'Mobile apps',
                    data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
                  },
                }"
              />
            </chart-holder-card>
          </div>
      </div>
    </div> -->

    <div class="row margin-top">
      <div class="col-lg-12  col-md-12 mb-md-0 mb-4">
        <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                  {{$t('message.todayLectures')}} - <i class="fas fa-calendar"></i><span class="total-tex">{{todayDate}}</span>
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div v-for="item in activeLectures" :key="item.id" style="padding:20px">
              <h5 style="background: rgb(240, 242, 245);" class="border p-2"> {{$t('message.branch')}} : {{ item.name }}</h5>
              <div class="table-responsive p-0" v-if="item.lectures.length">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                       <th
                        class="text-uppercase  font-weight-bolder"
                      >
                         {{$t('message.lecture')}}
                      </th>
                        <th
                        class="text-uppercase  font-weight-bolder "
                      >
                          {{$t('message.startDate')}}
                      </th>
                      <th v-if="centerType !== 'english -school'"
                        class="text-uppercase  font-weight-bolder "
                      >
                        الفرقة
                      </th>
                       <th v-if="centerType === 'english -school'"
                        class="text-uppercase  font-weight-bolder "
                      >
                        {{$t('message.addAttendance')}}
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        {{$t('message.course')}}
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        {{$t('message.teacher')}}
                      </th>
                        <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        {{$t('message.room')}}
                      </th>
                      <th class="text-uppercase  font-weight-bolder "> {{$t('message.registeredStudents')}}</th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        {{$t('message.attendance')}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="lecture in item.lectures"
                      :key="lecture.id"
                    >
                      <td>
                        <router-link :to="{path: '/lectures/'+lecture.id+'/show', params:{lecture_id:lecture.id.id}}">
                        <p class="text-s mb-0 link" style="color:blue !important">{{ lecture.lecture_number }}</p>
                        </router-link>
                      </td>
                       <td >
                        <p class="text-s mb-0 dir">{{ getDate(lecture.start) }}</p>
                      </td>
                        <td v-if="centerType !== 'english -school'">
                        <p class="text-s mb-0">{{ lecture.course?.grade?.name}}</p>
                      </td>
                      <td v-if="centerType === 'english -school'">
                        <router-link to='/add-attendance'>
                         <button
                      ref="" class="btn btn-info"
                        >
                            {{$t('message.add')}}
                        </button>
                        </router-link>
                      </td>
                      <td>
                          <router-link :to="{path: '/courses/'+lecture.course.id+'/show'}">
                        <p class="text-s mb-0" style="color:blue !important">{{ lecture.course.name }}</p>
                        </router-link>
                      </td>
                       <td>
                          <router-link :to="{path: '/teachers/'+lecture.course.instructor.id+'/show'}">
                        <p class="text-s mb-0" style="color:blue !important">{{ lecture.course.instructor.name }}</p>
                          </router-link >
                      </td>
                      <td class="text-center">
                        <p class="text-s mb-0">{{ lecture.room.name }}</p>
                      </td>
                       <td class="text-center">
                        <p class="text-s mb-0">{{ lecture.course.enrolled_students_count }}</p>
                      </td>
                       <td>
                        <p class="text-s mb-0">{{ lecture.total_attended_students}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="no-lecture"><span>  {{$t('message.noLectures')}}</span>  </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
// import ChartHolderCard from './components/ChartHolderCard.vue'
// import ReportsLineChart from '@/examples/Charts/ReportsLineChart.vue'
import MiniStatisticsCard from './components/MiniStatisticsCard.vue'
import logoAtlassian from '@/assets/img/small-logos/logo-atlassian.svg'
import logoSlack from '@/assets/img/small-logos/logo-slack.svg'
import logoSpotify from '@/assets/img/small-logos/logo-spotify.svg'
import logoJira from '@/assets/img/small-logos/logo-jira.svg'
import logoInvision from '@/assets/img/small-logos/logo-invision.svg'
const today = new Date()
const currentDate = today.getDate()
const currentMonth = today.getMonth() + 1 // Months are zero-based
const currentYear = today.getFullYear()

// Format the date as desired
const formattedDate = currentYear + '-' + currentMonth + '-' + currentDate
export default {
  name: 'dashboard-default',
  data () {
    return {
      logoAtlassian,
      logoSlack,
      logoSpotify,
      logoJira,
      logoInvision,
      statistics: {},
      activeLectures: [],
      todayDate: formattedDate,
      centerType: ''
    }
  },
  components: {
    MiniStatisticsCard
    // ChartHolderCard,
    // ReportsLineChart
  },
  created () {
    this.centerType = localStorage.centerType
    this.$http.get(`${this.$hostUrl}statistics`).then(response => {
      this.statistics = response.data.data
    })
    // api/dashboard/branches/lectures/today
    this.$http.get(`${this.$hostUrl}branches/lectures/today`).then(response => {
      this.activeLectures = response.data.data
    })
  },
  methods: {
    getDate (startDate) {
      let start = startDate.substr(11, 5)
      // Check correct time format and split into components
      start = start.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [start]

      if (start.length > 1) { // If time format correct
        start = start.slice(1) // Remove full string match value
        start[5] = +start[0] < 12 ? ' AM ' : ' PM ' // Set AM/PM
        start[0] = +start[0] % 12 || 12 // Adjust hours
      }
      return start.join('') // return adjusted time or original strin
    //   start = (startHours >= 12) ? start + ' ' + 'PM' : start + ' ' + 'AM'
    //   return start
    }
  },
  mounted () {
    if (!localStorage.token) {
      this.$router.push({ name: 'SignIn' })
    }
  }
}
</script>
<style scoped>
.margin-top{
  margin-top: 45px;
}
.no-lecture{
  font-weight: bold;
}
.dir{
  direction: ltr !important
}
.fa-calendar{
  padding-left: 4px;
}
</style>
