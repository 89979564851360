<template>
  <div class="container-fluid py-4 add-student add-course">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">إضافة دورة</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form @submit="createCourse" role="form" class="text-start mt-3">
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label
                    >اسم الدورة<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.name ? errors.name[0] : ''
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    placeholder="الاسم  بالعربي"
                    v-model="course.name"
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label class="unrequired">
                    اسم الدورة بالإنجليزية<span class="green">(اختياري) </span>
                    <span color="danger" class="error">{{
                      errors.name_en ? errors.name_en[0] : ''
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    placeholder="الاسم بالإنجليزية"
                    v-model="course.name_en"
                  />
                </div>
              </div>
               <div class="row">
                <div class="mb-3 col-lg-6">
                  <label class="unrequired">
                    {{ $t("message.startDate") }}<span class="green">(اختياري)</span>
                    <span color="danger" class="error">{{
                      errors.name ? errors.name[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="date"
                    :placeholder="$t('message.startDate')"
                    v-model="course.startDate"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label class="unrequired">
                    {{ $t("message.endDate") }}<span class="green">(اختياري) </span>
                    <span color="danger" class="error">{{
                      errors.name_en ? errors.name_en[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="date"
                    :placeholder="$t('message.endDate')"
                    v-model="course.endDate"
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label class="unrequired">
                    {{ $t("message.startTime") }}<span class="green">(اختياري)</span>
                    <span color="danger" class="error">{{
                      errors.name ? errors.name[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="time"
                    :placeholder="$t('message.startTime')"
                    v-model="course.startTime"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label class="unrequired">
                    {{ $t("message.duration") }}<span class="green">(اختياري) </span>
                    <span color="danger" class="error">{{
                      errors.name_en ? errors.name_en[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    :placeholder="'01:30'"
                    v-model="course.duration"
                  />
                </div>
              </div>
         <div class='row '>
                  <h6  style="text-align:right" class="text-warning text-right ">يجب اختيار الترم ثم الفرقة لظهور المادة المناسبة</h6>
                </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    السنة الدراسية<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.academic_year_id ? errors.academic_year_id[0] : ''
                    }}</span>
                  </label>
                  <div class="input-group input-group-outline null">
                    <select
                      class="form-control"
                      v-model="SelectedYear"
                      required
                    >
                      <option selected value="">اختر السنة الدراسية</option>
                      <option
                        v-for="year in academicYear"
                        :key="year.id"
                        :value="year.id"
                      >
                        {{ year.name }}
                      </option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    الترم الدراسي<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.semester_id ? errors.semester_id[0] : ''
                    }}</span>
                  </label>
                  <div class="input-group input-group-outline null">
                    <select
                      class="form-control"
                      v-model="SelectedSemester"
                      @change=" removeGrade "
                      required
                    >
                      <option value="" selected>
                        اختر الترم الدراسي
                        </option>
                      <option value="1"> الأول</option>
                      <option value="2"> الثاني</option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    الفرقة
                    <span color="danger" class="error">{{
                      errors.grade_id ? errors.grade_id[0] : ''
                    }}</span>
                  </label>
                  <multiselect
                        class='required form-control'
                        id='group'
                        v-model="selectedGrade"
                        :options='grades'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="  اختر الفرقة"
                        label='name'
                        @select='getSubjects($event)'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                    <div class="mb-3 col-lg-6">
                  <label>
                    المواد <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.subject_id ? errors.subject_id[0] : ''
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="area"
                    v-model="selectedSubject"
                    :options="subjects"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder="اختر المادة"
                    label="name"
                    track-by="id"
                    required="required"
                    :internal-search="true"
                    @select="setCourseCost"
                  >
                    >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div class="row">
              <div class="mb-3 col-lg-6">
                  <label>
                    المجموعة <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.group_id ? errors.group_id[0] : ''
                    }}</span></label
                  >
                    <multiselect
                        class='required form-control'
                        id='group'
                        v-model="selectedGroup"
                        :options='groups'
                        :searchable='true'
                        :multiple="true"
                        :close-on-select='true'
                        placeholder="  اختر المجموعة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    عدد المحاضرات <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.no_of_lectures ? errors.no_of_lectures[0] : ''
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    placeholder="   عدد المحاضرات"
                    v-model="course.noOfLectures"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    المدرس <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.instructor_id ? errors.instructor_id[0] : ''
                    }}</span></label
                  >
                   <multiselect
                        class='required form-control'
                        id='group'
                        v-model="selectedInstructor"
                        :options='instructors'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="  اختر  المدرس"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                <div class="mb-3 col-lg-6">
                  <div class="row">
                    <div class="mb-3 mt-4 col-lg-4">
                      <vmd-checkbox id="free-video" type="text" v-model='course.free' :value="true">
                      كورس مجاني
                      </vmd-checkbox>
                    </div>
                    <div class="mb-3 col-lg-8" v-if="!course.free">
                      <label>
                        قيمة الدورة <span class="red">*</span>
                        <span color="danger" class="error">{{
                          errors.total_cost ? errors.total_cost[0] : ''
                        }}</span></label>
                      <vmd-input
                        id=""
                        type="text"
                        placeholder="قيمة الدورة"
                        v-model="course.total_cost"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6" v-if="!course.free">
                  <label>
                    قيمة المحاضرة <span class="red">*</span>
                    <span color="danger" class="error"></span
                  ></label>
                  <vmd-input
                    id=""
                    type="text"
                    placeholder=" قيمة المحاضرة"
                    v-model="course.lectureCost"
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    الفرع <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.branch_id ? errors.branch_id[0] : ''
                    }}</span></label
                  >
                    <multiselect
                        class='required form-control'
                        id='branch'
                        v-model="selectedBranch"
                        :options='branches'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="  اختر  الفرع"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <div class="online attend ">
                    <vmd-checkbox
                      :checked="course.available"
                      v-model="course.available"
                    ></vmd-checkbox>
                    <span> الدورة متاحة أون لاين</span>
                  </div>
                </div>
                  <div class="mb-3 col-lg-6" v-if='course.available'>
                  <label>
                     صورة الدورة  <span class="red">*</span><span color="danger" class="error">{{
                      errors.photo ? errors.photo[0] : ''
                    }}</span></label>
                  <vmd-input
                    id="image"
                    type="file"
                    required
                    @change='onFileChange'
                  />
                </div>
                 <div class="mb-3 col-lg-6">
                  <div class="online attend ">
                    <vmd-checkbox
                      :checked="course.revision"
                      v-model="course.revision"
                    ></vmd-checkbox>
                    <span>   مراجعة نهائية </span>
                  </div>
                  </div>
                  <div class="mb-3 col-lg-6" v-if='course.available'>
                   <label>
                     رسوم التطبيق <span class="red">*</span>
                    <span color="danger" class="error"></span
                  ></label>
                  <vmd-input
                    id=""
                    type="text"
                    placeholder="  رسوم التطبيق"
                    v-model="course.appFees"
                    required
                  />
                </div>
              </div>
              <div class="row">
                 <div class="mb-3 col-12">
                  <label class="unrequired">
                    {{ $t("message.day") }} <span class="green">(اختياري)</span>
                    <span color="danger" class="error">{{
                      errors.no_of_lectures ? errors.no_of_lectures[0] : ""
                    }}</span></label
                  >
                  <ul style="margin-top:30px" class="list-group list-group-horizontal">
                    <li v-for="day in days" :key="day.id" class="list-group-item">
                      <vmd-checkbox v-model="day.isChecked">{{day.name}}</vmd-checkbox>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button class="my-4 mb-2" variant="gradient" color="info">
                    حفظ</vmd-button
                  >
                </div>
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    type="reset"
                    color="danger"
                  >
                    مسح</vmd-button
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import VmdCheckbox from '@/components/VmdCheckbox.vue'
import swal from 'sweetalert'
import $ from 'jquery'
export default {
  name: 'AddCourse',
  components: {
    VmdInput,
    VmdButton,
    VmdCheckbox
  },
  data () {
    return {
      course: {},
      subjects: [],
      groups: [
        { id: '5d2a4482-5848-43c4-b30e-7d69ec2efcc5', name: '4' },
        { id: '7cdf25a8-4837-4480-98ae-2ed752287ad4', name: '2' },
        { id: '9a2f0d62-093c-4e38-a3f9-8c01cbf35739', name: '3' },
        { id: 'fff0c94b-3406-494e-9cce-516b021fbc39', name: '1' }],
      grades: [
        { id: '5de6043a-bc65-437b-bb57-0cbf69573dd7', name: 'الفرقة الرابعة' },
        { id: 'ab1fb157-188b-45ea-b046-302ff1221253', name: 'الفرقة الأولى' },
        { id: 'd96e432f-9a95-4bdb-9a87-bceaf981fe0e', name: 'الفرقة الثانية' },
        { id: 'f4a80ded-3d68-4795-84b0-5692daa74b78', name: 'الفرقة الثالثة' }],
      instructors: [],
      academicYear: [],
      branches: [],
      selectedGrade: '',
      selectedSubject: '',
      selectedGroup: '',
      selectedInstructor: '',
      SelectedYear: '',
      selectedBranch: '',
      SelectedSemester: '2',
      image: '',
      days: [],
      sentDays: [],
      errors: []
    }
  },
  created () {
    const subjects = []
    this.$http.get(`${this.$hostUrl}subjects`).then((response) => {
      $.each(response.data.data, function (key, value) {
        subjects.push({
          name: value.name,
          id: value.id,
          cost: value.cost
        })
      })
      this.subjects = subjects
    })
    const instructors = []
    this.$http.get(`${this.$hostUrl}instructors`).then((response) => {
      $.each(response.data.data, function (key, value) {
        instructors.push({
          name: value.name,
          id: value.id
        })
      })
      this.instructors = instructors
    })
    const academicYear = []
    this.$http.get(`${this.$hostUrl}academic-years`).then((response) => {
      $.each(response.data.data, function (key, value) {
        academicYear.push({
          name: value.name,
          id: value.id
        })
      })
      this.academicYear = academicYear
    })
    const branches = []
    this.$http.get(`${this.$hostUrl}branches`).then((response) => {
      $.each(response.data.data, function (key, value) {
        branches.push({
          name: value.name,
          id: value.id
        })
      })
      this.branches = branches
    })

    this.days = [
      { id: '8c97711c-f8c6-4c4a-b30d-f24e1ca4deb6', name: 'السبت' },
      { id: '63a41dac-a1f2-4fd3-a04a-7884a6204783', name: 'الاحد' },
      { id: '07d687b2-feba-4362-967b-0909863fe99d', name: 'الاثنين' },
      { id: '06569b88-d559-413d-acc4-042fe0d89d85', name: 'الثلاثاء' },
      { id: '0be24ed3-0dd8-49b3-a745-d3b58440ba71', name: 'الأربعاء' },
      { id: '75ea630b-9e2d-4075-9b7c-826135346024', name: 'الخميس' },
      { id: '75fc1c92-6458-4223-b6b9-8b08f9a161a6', name: 'الجمعه' }
    ]
  },
  watch: {
    days: {
      handler (newDays) {
        this.sentDays = newDays
          .filter((day) => day.isChecked)
          .map((day) => day.id)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // enforce the user to choose the semster first for right subjects
    removeGrade () {
      this.selectedGrade = ''
    },
    getSubjects ($event) {
      this.selectedSubject = ''
      const subjects = []
      this.$http.get(`${this.$hostUrl}grade/${$event.id}/subjects?semester=${this.SelectedSemester}`).then((response) => {
        $.each(response.data.data, function (key, value) {
          subjects.push({
            name: value.name,
            id: value.id,
            cost: value.cost
          })
        })
        this.subjects = subjects
      })
    },
    setCourseCost (event) {
      this.course.total_cost = event.cost
    },
    onFileChange (e) {
      const _URL = window.URL || window.webkitURL
      this.image = e.target.files[0]
      let file = ''
      if ((file = e.target.files[0])) {
        const img = new Image()
        img.src = _URL.createObjectURL(file)
      }
    },
    async createCourse (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.course.name)) {
        swal('اسم الدورة يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.course.name)
      if (this.course.name_en) {
        formData.append('name_en', this.course.name_en)
      }
      formData.append('grade_id', this.selectedGrade.id)
      const allSelectedGroups = []
      $.each(this.selectedGroup, function (key, value) {
        allSelectedGroups.push({ id: value.id })
      })
      for (let i = 0; i < allSelectedGroups.length; i++) {
        formData.append('group_id[]', allSelectedGroups[i].id)
      }

      formData.append('subject_id', this.selectedSubject.id)
      formData.append('instructor_id', this.selectedInstructor.id)
      formData.append('academic_year_id', this.SelectedYear)
      formData.append('branch_id', this.selectedBranch.id)
      formData.append('no_of_lectures', this.course.noOfLectures)
      formData.append('total_cost', this.course.free ? 0 : this.course.total_cost.replace(/\.\d*0+$/, ''))
      formData.append('free', this.course.free ? 1 : 0)
      formData.append('lecture_cost', this.course.free ? 0 : this.course.lectureCost)
      if (this.course.available) {
        formData.append('type', 1)
      } else {
        formData.append('type', 0)
      }
      if (this.image) {
        formData.append('photo', this.image)
      }
      if (this.course.revision) {
        formData.append('revision', 1)
      } else {
        formData.append('revision', 0)
      }
      if (this.course.appFees) {
        formData.append('app_fees', this.course.appFees)
      }
      if (this.course.startDate) {
        formData.append('start', this.course.startDate)
      }
      if (this.course.endDate) {
        formData.append('end', this.course.endDate)
      }
      if (this.course.startTime) {
        formData.append('start_time', this.course.startTime)
      }
      if (this.course.duration) {
        formData.append('duration', this.course.duration)
      }
      if (this.sentDays.length) {
        for (let i = 0; i < this.sentDays.length; i++) {
          formData.append('days[]', this.sentDays[i])
        }
      }
      this.errors = await this.postRequest(
        formData,
        this.$hostUrl + 'courses',
        '/courses'
      )
    }
  }
}
</script>
<style >
.add-course .online{
  margin:20px 0 !important
}
</style>
