<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <div>
      <ul class="navbar-nav">
        <li class="nav-item">
          <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="/dashboard"
            :navText="$t('message.dashboard')"
          >
            <template v-slot:icon>
              <i class="material-icons-round opacity-10 fs-5">dashboard</i>
            </template>
          </sidenav-collapse>
        </li>
        <li class="nav-item" v-if="centerType === 'english -school'">
          <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="/students"
            :navText="$t('message.customers')"
          >
            <template v-slot:icon>
              <i class="fas fa-user-graduate opacity-10 fs-5"></i>
            </template>
          </sidenav-collapse>
        </li>
        <li
          class="nav-item"
          v-else-if=" this.permissionList.split(',').includes('student') || isAdmin"
        >
          <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="/students"
            navText="الطلاب"
          >
            <template v-slot:icon>
              <i class="fas fa-user-graduate opacity-10 fs-5"></i>
            </template>
          </sidenav-collapse>
        </li>
        <li
          class="nav-item"
          v-if="permissionList.split(',').includes('course')|| isAdmin"
        >
          <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="/courses"
            :navText="$t('message.courses')"
          >
            <template v-slot:icon>
              <i class="fas fa-book opacity-10 fs-5"></i>
            </template>
          </sidenav-collapse>
        </li>
        <li class="list nav-item"  v-if="ShowSystemControl">
          <sidenav-collapse :navText="$t('message.systemControl')">
            <template v-slot:icon>
              <i class="material-icons-round opacity-10 fs-5">pie_chart</i>
            </template>
            <template v-slot:list>
              <sidenav-collapse
                v-if="permissionList.split(',').includes('instructor') || isAdmin"
                url=""
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/teachers"
                  :navText="$t('message.teachers')"
                class=""
              >
                <template v-slot:icon>
                  <i class="fas fa-chalkboard-teacher opacity-10 fs-5"></i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                v-if="permissionList.split(',').includes('subject')|| isAdmin"
                url=""
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/subjects"
                :navText="$t('message.subjects')"
              >
                <template v-slot:icon>
                  <i class="fas fa-book-open opacity-10 fs-5"></i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                v-if="permissionList.includes('grade') || isAdmin"
                url=""
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/grades"
                  :navText="$t('message.grades')"
              >
                <template v-slot:icon>
                  <i class="fas fa-users opacity-10 fs-5 white"></i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                v-if="permissionList.includes('room') || isAdmin"
                url=""
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/rooms"
                :navText="$t('message.rooms')"
              >
                <template v-slot:icon>
                  <i class="fas fa-chair opacity-10 fs-5"></i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                v-if="
                  permissionList.includes('branch') &&
                  (centerName === 'Seven Eleven' || isAdmin)
                "
                url=""
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/branches"
                navText=" الفروع"
              >
                <template v-slot:icon>
                  <i class="fas fa-th-list opacity-10 fs-5"></i>
                </template>
              </sidenav-collapse>
            </template>
          </sidenav-collapse>
        </li>
         <li
          class="nav-item list"
          v-if="permissionList.includes('video') ||
         permissionList.includes('notifications') ||
          permissionList.includes('slide') "
        >
          <sidenav-collapse
            class=""
            url="#"
            :aria-controls="''"
            v-bind:collapse="true"
            collapseRef=""
            navText=" التحكم في التطبيق "
          >
            <template v-slot:icon>
              <i class="fas fa-mobile-alt"></i>
            </template>
            <template v-slot:list>
              <!-- <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/videos"
                navText=" الفيديوهات"
              >
                <template v-slot:icon>
                  <i class="fas fa-play-circle opacity-10 fs-5"></i>
                </template>
              </sidenav-collapse> -->
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/notifications"
                navText="  الإشعارات"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">message</i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                v-if="permissionList.includes('slide') || isAdmin"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/slider"
                navText=" الشرائح المتحركة"
              >
                <template v-slot:icon>
                  <i class="fas fa-window-restore opacity-10 fs-5"></i>
                </template>
              </sidenav-collapse>
            </template>
          </sidenav-collapse>
        </li>
        <!-- <li
          class="nav-item"
          v-if="permissionList.includes('lecture') || isAdmin"
        >
          <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="/lectures"
            navText="المحاضرات"
          >
            <template v-slot:icon>
              <i class="fas fa-book-reader opacity-10 fs-5"></i>
            </template>
          </sidenav-collapse>
        </li> -->
        <li class="list nav-item"
         v-if="permissionList.split(',').includes('user')||
         permissionList.includes('role')  ">
          <sidenav-collapse :navText="$t('message.users')">
            <template v-slot:icon>
              <i class="material-icons-round opacity-10 fs-5">pie_chart</i>
            </template>
            <template v-slot:list>
              <sidenav-collapse
                v-if="permissionList.split(',').includes('user') || isAdmin"
                url=""
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/users"
                :navText=" $t('message.usersList') "
                class=""
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">group</i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                v-if="permissionList.includes('role') || isAdmin"
                url=""
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/roles"
                :navText=" $t('message.jobs')"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
            </template>
          </sidenav-collapse>
        </li>
        <li class="nav-item attend-li" v-if="permissionList.includes('salesperson') || isAdmin">
          <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="/sales"
            navText="موظفي المبيعات"
          >
            <template v-slot:icon>
              <i class="material-icons-round opacity-10 fs-5">group</i>
            </template>
          </sidenav-collapse>
        </li>
        <li class="nav-item attend-li" v-if="attendance || isAdmin">
          <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="/students-attendance"
            :navText="$t('message.attendance')"
          >
            <template v-slot:icon>
              <i class="material-icons-round opacity-10 fs-5">book</i>
            </template>
          </sidenav-collapse>
        </li>
        <li
          style="font-size: 13px"
          class="nav-item multi-attend"
          v-if="centerType === 'semester' && attendance"
        >
          <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="/multiple-attendance"
            navText="  حضور   متعدد"
          >
            <template v-slot:icon>
              <i class="material-icons-round opacity-10 fs-5">book</i>
            </template>
          </sidenav-collapse>
        </li>
        <li
          class="nav-item"
          v-if="permissionList.includes('receipt') || isAdmin"
        >
          <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="/receipts"
            :navText="$t('message.receipts')"
          >
            <template v-slot:icon>
              <i class="material-icons-round opacity-10 fs-5">receipt_long</i>
            </template>
          </sidenav-collapse>
        </li>
        <li
          class="nav-item"
             v-if="permissionList.split(',').includes('user') &&
            (centerName === 'Seven Eleven' || isAdmin)
          "
        >
          <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="/user-collections"
            navText="تحصيلات المستخدم"
          >
            <template v-slot:icon>
              <i class="fas fa-user"></i>
            </template>
          </sidenav-collapse>
        </li>

        <li class="nav-item" v-if="permissionList.includes('offer') || isAdmin">
          <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="/offers"
            :navText="$t('message.offers')"
          >
            <template v-slot:icon>
              <i class="fas fa-award opacity-10 fs-5"></i>
            </template>
          </sidenav-collapse>
        </li>
        <!-- list -->
        <!-- !-- reports  -->
        <li
          class="nav-item list"
           v-if="
         showReports
         "
        >
          <sidenav-collapse
            class=""
            url="#"
            :aria-controls="''"
            v-bind:collapse="true"
            collapseRef=""
            :navText="$t('message.report')"
          >
            <template v-slot:icon>
              <i class="material-icons-round opacity-10 fs-5">pie_chart</i>
            </template>
            <template v-slot:list>
              <sidenav-collapse
                v-if="permissionList.includes('report:attendance') || isAdmin"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/student-attendance"
                navText=" تقارير  الحضور"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                v-if="permissionList.split(',').includes('report:students')|| isAdmin"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/course-students"
                navText=" تقارير الطلاب  للدورة"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
               <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/sales-report"
                navText=" تقارير   المبيعات"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
               <sidenav-collapse
                v-if="permissionList.includes('placement')"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/placement-test"
                :navText="$t('message.placement') "
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
               <sidenav-collapse
                v-if="permissionList.includes('placement')"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/users-sales"
                :navText="$t('message.userSales') "
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
               <sidenav-collapse
               class="average"
                v-if="permissionList.includes('placement')"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/persistance-average"
                :navText="$t('message.persistanceAverage') "
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
               class="average"
                v-if="permissionList.includes('placement')"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/levels-students"
                :navText="$t('message.levelsStudents') "
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
               <sidenav-collapse
                v-if="permissionList.includes('placement')"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/students-count"
                :navText="$t('message.studentsCount') "
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
               v-if="permissionList.includes('report:student-count')"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/numbers-report"
                navText=" اعداد الطلاب الكلى  "
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/calls-report"
                navText=" تقارير   المكالمات"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
              <!-- <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="/reports/overall-income"
          navText="  تقارير دخل السنتر"
        >    <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">list</i>
          </template>
        </sidenav-collapse> -->
              <sidenav-collapse
               v-if="permissionList.includes('report:subject-payment') || isAdmin"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/subjects-payments"
                navText=" تقارير  مدفوعات المواد"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                v-if="permissionList.includes('report:areas') || isAdmin"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/area-report"
                navText=" تقارير  المناطق"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                v-if="permissionList.includes('report:enroll') || isAdmin"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/center-report"
                navText=" تقاريرالإلتحاق بالمركز"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                v-if="permissionList.includes('report:watches') || isAdmin"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/watches-report"
                navText=" تقارير المشاهدات"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
              <!-- <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="/reports/app-fees"
          navText=" تقارير رسوم التطبيق"
        >    <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">list</i>
          </template>
        </sidenav-collapse> -->
              <sidenav-collapse
                v-if="permissionList.includes('reservation') || isAdmin"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/subjects-reservation"
                navText=" تقارير  حجز المواد"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                v-if="permissionList.includes('report:instructor') || isAdmin"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/instructors"
                navText=" تقارير المدرس"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                v-if="permissionList.includes('report:instructor') || isAdmin"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/instructor-collections"
                navText="   تحصيلات المدرس"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                 v-if="permissionList.split(',').includes('report:user') || permissionList.includes('report:collections')"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/collections-report"
                navText="التحصيلات اليومية"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
              <sidenav-collapse
                v-if="permissionList.includes('report:paid') || isAdmin"
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/paid-remaining"
                navText=" المسدد والمتبقي"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
            </template>
          </sidenav-collapse>
        </li>
        <li
          class="nav-item list"
          v-if="centerType === 'semester' && centerName === 'Seven Eleven'"
        >
          <sidenav-collapse
            class=""
            url="#"
            :aria-controls="''"
            v-bind:collapse="true"
            collapseRef=""
            navText=" الإحصائيات"
          >
            <template v-slot:icon>
              <i class="material-icons-round opacity-10 fs-5">pie_chart</i>
            </template>
            <template v-slot:list>
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/statistics/students-numbers"
                navText="   أعداد الطلاب"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
            </template>
          </sidenav-collapse>
        </li>
        <li class="nav-item list" v-if="centerType === 'university'">
          <sidenav-collapse
            class=""
            url="#"
            :aria-controls="''"
            v-bind:collapse="true"
            collapseRef=""
            navText=" التقارير"
          >
            <template v-slot:icon>
              <i class="material-icons-round opacity-10 fs-5">pie_chart</i>
            </template>
            <template v-slot:list>
              <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="/reports/watches-report"
                navText=" تقارير المشاهدات"
              >
                <template v-slot:icon>
                  <i class="material-icons-round opacity-10 fs-5">list</i>
                </template>
              </sidenav-collapse>
            </template>
          </sidenav-collapse>
        </li>
        <!-- list -->
        <li
          class="nav-item"
          v-if="permissionList.includes('setting') || isAdmin"
        >
          <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="/setting"
            :navText="$t('message.setting')"
          >
            <template v-slot:icon>
              <i class="fas fa-cog opacity-10 fs-5"></i>
            </template>
          </sidenav-collapse>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import SidenavCollapse from './SidenavCollapse.vue'
import $ from 'jquery'

export default {
  name: 'SidenavList',
  props: {
    cardBg: String
  },
  data () {
    return {
      permissionList: '',
      allItems: [],
      centerType: '',
      centerName: '',
      title: 'Soft UI Dashboard PRO',
      controls: 'dashboardsExamples',
      isActive: 'active',
      isAdmin: 0,
      attendance: false
    }
  },
  components: {
    SidenavCollapse
  },
  computed: {
    ShowSystemControl () {
      return (
        this.permissionList.split(',').includes('instructor') ||
        this.permissionList.split(',').includes('subject') ||
        this.permissionList.includes('grade') ||
        this.permissionList.includes('room') ||
        (this.permissionList.includes('branch') &&
          (this.centerName === 'Seven Eleven' || this.isAdmin))
      )
    },
    showReports () {
      return (
        this.permissionList.includes('report:attendanc') ||
        this.permissionList.includes('report:paid') ||
        this.permissionList.includes('report:instructor') ||
        this.permissionList.includes('report:collections') ||
        this.permissionList.includes('report:watches') ||
        this.permissionList.includes('report:enroll') ||
        this.permissionList.includes('report:areas') ||
        this.permissionList.includes('report:students') ||
        this.permissionList.includes('report:user') ||
        this.permissionList.includes('report:student-count') ||
        this.permissionList.includes('report:wallet') ||
        this.permissionList.includes('report:subject-payment') ||
        this.permissionList.includes('report:reservation') ||
        this.permissionList.includes('report:placement')

      )
    }
  },
  created () {
    this.isAdmin = parseInt(localStorage.isAdmin)
    this.centerType = localStorage.centerType
    this.centerName = localStorage.centerName
    const permissions = []
    if (!localStorage.permissions) {
      localStorage.clear()
      this.$router.push({ path: '/login' })
    } else if (localStorage.permissions.length) {
      this.attendance = localStorage.permissions.indexOf('Attendance:Add') > -1
      $.each(localStorage.permissions.split(','), function (key, permission) {
        if (permission.toLowerCase().startsWith('report')) {
          permissions.push(permission.toLowerCase())
        } else {
          permissions.push(permission.split(':')[1].toLowerCase())
        }
      })

      const permissionsList = [...new Set(permissions)]
      this.permissionList = permissionsList.toString()
    }
  }
}
</script>
<style>
.g-sidenav-show.rtl
  .navbar-vertical
  .navbar-nav
  .nav-link[data-bs-toggle="collapse"]:after {
  margin-right: 10px !important;
}
.white {
  color: #fff !important;
}
.list > div > a:first-child {
  background-image: none !important;
  background-color: transparent !important;
}
a.average{
 width: max-content !important;
}
</style>
