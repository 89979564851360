<template>
  <div class="container-fluid py-4 add-student grade-detail center-report">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">   تقارير الإلتحاق بالمركز   </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
             <div class="mb-3 col-lg-12 tab-div tab2">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
      <!-- <li class="nav-item list2" role="presentation">
        <button style="width:100%"  class="  nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"> تقرير الإلتحاق بالدورات</button>
      </li> -->
      <li class="nav-item list2" role="presentation">
        <button style="width:100% "  class="  active nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg" id="subject-tab" data-bs-toggle="tab" data-bs-target="#subject" type="button" role="tab" aria-controls="subject" aria-selected="true"> تقرير الإلتحاق بالمواد</button>
      </li>
      <li class="nav-item list2" role="presentation">
        <button style="width:100%" class=" nav-link btn mb-0 bg-gradient-info  btn-md null null btn-lg" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">  الطلاب   التي لم تحضر المادة</button>
      </li>
    </ul>
    </div>
            <!-- exceptional attendance row -->
          <div class="tab-content" id="myTabContent">
         <div class="tab-pane fade show " id="home" role="tabpanel" aria-labelledby="home-tab">
          <div class= 'row'>
           <div class="mb-3 col-lg-5">
                    <label>الطالب</label>
                    <multiselect
                        class='required form-control'
                        id='student'
                        v-model="selectedStudent"
                        :options='studentsList'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الطالب"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                <div class="text-center col-lg-3 ">
                  <vmd-button
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="primary"
                    @click="filterData('course')"
                    >  فلتر</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="info"
                    @click="printReport('course')"
                    >  طباعة</vmd-button
                  >
                </div>
                </div>
                <div id="print-courses" style=" margin-top: 40px;direction:rtl">
          <div class='row times' style=" margin-top: 40px;direction:rtl">
                <div class="col-md-4" >
                  <h5>السنة الدراسية : <span class="green">2022-2023</span></h5>
                </div>
              </div>
              <div v-for="item in courseStudents" :key="item.id">
              <div class='row times' style=" margin-top: 40px;direction:rtl">
                <div class="col-md-12" >
                  <h5> الطالب : <span class="green">{{item.number}} - {{item.full_name}}</span></h5>
                </div>
              </div>
            <div class="row align-center" style=" margin-top: 40px;direction:rtl">
              <div class="text-center col-lg-12" style="direction:rtl">
                <table class=" b-block  table " style="direction:rtl">
                  <thead>
                    <tr>
                      <th >الدورة التدريبية</th>
                        <th> الفرقة</th>
                        <th>المجموعة</th>
                        </tr>

                  </thead>
                  <tbody>
                    <tr v-for="student in item.courses" :key="student.id">
                      <td>{{student.name}}</td>
                      <td>{{student.grade?student.grade.name:'-'}}</td>
                       <td>{{student.group?student.group.name:'-'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
            </div>
                    <div class="row">
                      <nav aria-label="...">
          <ul class="pagination pagination-lg">
            <li @click="paginate($event)"  class=" pointer " :class="{'pagi-active':currentPage === page} " aria-current="page" v-for="page in allPages" :key="page">
              <span class="page-link pagi-item">{{page}}</span>
            </li>
          </ul>
        </nav>
                </div>
          </div>
          <!-- subject tab -->
          <div class="tab-pane fade show active " id="subject" role="tabpanel" aria-labelledby="subject-tab">
             <!-- subject component -->
                <subjectAttendance></subjectAttendance>
                <!-- this is the old report -->
              <!-- <div class= 'row'>
           <div class="mb-3 col-lg-5">
                    <label>الطالب</label>
                    <multiselect
                        class='required form-control'
                        id='student'
                        v-model="selectedStudent"
                        :options='studentsList'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الطالب"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                <div class="text-center col-lg-2 ">
                  <vmd-button
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="primary"
                    @click="filterData('course')"
                    >  فلتر</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="info"
                    @click="printReport('subject')"
                    >  طباعة</vmd-button
                  >
                </div>
                </div>
                <div id="print-subjects" style=" margin-top: 40px;direction:rtl">
          <div class='row times' style=" margin-top: 40px;direction:rtl">
                <div class="col-md-4" >
                  <h5>السنة الدراسية : <span class="green">2022-2023</span></h5>
                </div>
              </div>
              <div v-for="item in courseStudents" :key="item.id">
              <div class='row times' style=" margin-top: 40px;direction:rtl">
                <div class="col-md-12" >
                  <h5> الطالب : <span class="green">{{item.number}} - {{item.full_name}}</span></h5>
                </div>
              </div>
            <div class="row align-center" style=" margin-top: 40px;direction:rtl" id='print-report2'>
              <div class="text-center col-lg-12" style="direction:rtl">
                <table class=" b-block  table " style="direction:rtl">
                  <thead>
                    <tr>
                      <th > المادة</th>
                        <th> الفرقة</th>
                        <th>المجموعة</th>
                        </tr>

                  </thead>
                  <tbody>
                    <tr v-for="student in item.courses" :key="student.id">
                      <td>{{student.subject.name}}</td>
                      <td>{{student.grade?student.grade.name:'-'}}</td>
                       <td>{{student.group?student.group.name:'-'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
            </div>
                    <div class="row">
                      <nav aria-label="...">
          <ul class="pagination pagination-lg">
            <li @click="paginate($event)"  class=" pointer " :class="{'pagi-active':currentPage === page} " aria-current="page" v-for="page in allPages" :key="page">
              <span class="page-link pagi-item">{{page}}</span>
            </li>
          </ul>
        </nav>
                </div>-->
          </div>
   <!-- last tab -->
     <div class="tab-pane fade show " id="profile" role="tabpanel" aria-labelledby="rofile-tab">
         <div class="row align-center ex-row">
          <div class="mb-3 col-lg-4">
                    <label>   المادة <span class="red">*</span></label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedSubject"
                        :options='subjects'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="  اختر المادة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'

                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                      </div>
                       <div class="mb-3 col-lg-4">
                <label> الفرقة</label>
                <multiselect
                    class='required form-control'
                    id='grade'
                    v-model="selectedGrade"
                    :options='grades'
                    :searchable='true'
                    :close-on-select='true'
                    placeholder=" اختر الفرقة"
                    label='name'
                    :custom-label="customLabel"
                    track-by='id'
                    required='required'
                    :internal-search="true"
                    >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
              </div>
              <div class="mb-3 col-lg-4" v-if="!facultyCenter">
                    <label> المجموعة</label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedGroup"
                        :options='groups'
                        deselect-label="Can't remove this value"
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر المجموعة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='name'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                  <div class="text-center col-lg-2 ">
                  <vmd-button
                    class="my-4 mb-2 width "
                    variant="gradient"
                    color="primary"
                    @click="filterData('unrolled')"
                    >  فلتر</vmd-button
                  >
                </div>
                  <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width "
                    variant="gradient"
                    color="danger"
                    @click="clearData('unrolled')"
                    >  مسح</vmd-button
                  >
                </div>
              </div>
            <div class="row align-center all-students" style=" margin-top: 40px;direction:rtl" id='print-report2'>
               <div class='row times' style=" margin-top: 40px;direction:rtl">
                <div class="col-md-4" >
                  <h5> المادة : <span class="green">{{this.selectedSubject.name}}</span></h5>
                </div>
                <div class="col-md-4" >
                  <h5> الفرقة : <span class="green">{{this.selectedGrade.name?? '-'}}</span></h5>
                </div>
                <div class="col-md-4" >
                  <h5> المجموعة : <span class="green">{{this.selectedGroup.name?? '-'}}</span></h5>
                </div>
              </div>
               <vdtnet-table class=" table-striped table  table-bordered2 text-center "
            hide-select
           single-select
            :jquery="jq"
            ref="table"
            :fields="fields"
            :items="items"
            :opts="options"
            @edit="doAlertEdit"
            @show="doAlertShow"
            @delete="doAlertDelete"
            @attendance='doAlertAttendance'
            @getCalls="doAlertCalls"
            @reloaded="doAfterReload"
            @table-creating="doCreating"
            @table-created="doCreated"
            >
            <template v-slot:header>
      <tr>
        <th>llll</th> <!-- add a new column for the index -->
        <th v-for="(field, i) in fields" :key="field">
          {{$parent.getHeaderSlot(field, i)}}
        </th>
      </tr>
    </template>
          <template v-slot:body="{ items }">
      <tr v-for="(item, index) in items" :key='item'>
        <td>{{ index + 1 }}</td> <!-- display the index in the first column -->
        <td v-for="field in fields" :key="field">{{ item[field.key] }}</td>
      </tr>
    </template>
          </vdtnet-table>
            </div>
          </div>

    </div>
          </div>
          </div>
  </div>
      </div>
       <div class="add-student">
        <CreateCall
      v-model="showCallModal"
      @hide-modal="showCallModal = false"
      :studentInfo="studentInfo"
      :calls="studentCalls"
      @update-data="updateData"
    ></CreateCall>
    </div>
    </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import subjectAttendance from '@/components/subjectAttendance'
import swal from 'sweetalert'
import VdtnetTable from 'vue-datatables-net'
import CreateCall from '@/components/CreateCall.vue'
import $ from 'jquery'
import exportFromJSON from 'export-from-json'
const today = new Date()
const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
const time = today.getHours() + ':' + today.getMinutes()
const CurrentDateTime = date + '  ' + time
let selectedSubject = ''
let selectedGrade = ''
let selectedGroup = ''
let slectedSubjectName = ''
export default {
  name: 'AreaReports',
  components: {
    VmdButton,
    subjectAttendance,
    VdtnetTable,
    CreateCall
  },
  data () {
    return {
      allData: [],
      students: [],
      studentsList: [],
      selectedStudent: '',
      groups: [],
      allPages: '',
      page: 1,
      currentPage: '',
      selectedGroup: '',
      courseStudents: [],
      subjects: [],
      selectedSubject: '',
      unrolledStudents: [],
      grades: [],
      selectedGrade: '',
      areaGrades: [],
      grade: '',
      areas: [],
      selectedArea: '',
      showCallModal: false,
      studentCalls: [],
      studentInfo: {},
      errors: [],
      // data table
      pagination: {
        rowsPerPage: 30
      },
      jq: $,
      options: {
        ajax: {
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + localStorage.token
            )
          },
          url: `${this.$hostUrl}enroll-report`,
          data: (d) => {
            d.subject_id = selectedSubject
            d.group_id = selectedGroup
            d.grade_id = selectedGrade
          },
          dataSrc: (json) => {
            return json.data
          }
        },
        buttons: [
          {
            extend: 'print',
            text: '',
            titleAttr: 'طباعة',
            className: 'fas fa-print btn-primary',
            customize: function (win) {
              $(win.document.body).css('direction', 'rtl')
            }
          },
          {
            extend: 'copy',
            text: '',
            className: 'fas fa-copy btn-warning',
            titleAttr: 'نسخ'
          },
          {
            extend: 'excel',
            text: '',
            title: slectedSubjectName + 'تقرير عدم الحضور في المادة' + CurrentDateTime,
            titleAttr: 'إكسل',
            className: 'fas fa-table btn-success'
          }
        ],
        dom:
           "<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6 added'l>>" +
          "<'row'<'col-sm-12'Btr>>" +
          "<'row'<'col-sm-12 col-md-6'p><'col-sm-12 col-md-6'i>>",
        paging: true,
        language: {
          url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Arabic.json',
          processing: '<i class="fa fa-spinner fa-spin" style="font-size:40px;color:#7aeb7f"></i>'
        },
        responsive: false,
        processing: true,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        ordering: true,
        lengthChange: true,
        serverSide: true,
        fixedHeader: true,
        saveState: true,
        select: false
      },
      fields: {
        number: {
          label: ' رقم الطالب ',
          sortable: true,
          searchable: true,
          defaultOrder: 'desc'
        },
        full_name: {
          label: ' الاسم  ',
          sortable: true,
          searchable: true,
          defaultOrder: 'desc'
        },
        mobile: {
          label: '  الهاتف ',
          sortable: true,
          searchable: true,
          defaultOrder: 'desc',
          render: (mobile) => `<a class='link pointer' href='tel:${mobile}'>${mobile}`
        },
        addCall: {
          label: 'اضافة مكالمة',
          sortable: true,
          searchable: true,
          isLocal: true,
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="getCalls"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm btn-info">اضافة</span></a>'
        },
        grade_name: {
          label: ' الفرقة',
          sortable: true,
          searchable: true,
          defaultOrder: 'desc'
        },
        group_name: {
          label: 'المجموعة ',
          sortable: true,
          searchable: true,
          defaultOrder: 'desc'
        }
      }
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}subjects`).then(response => {
      this.subjects = response.data.data
    })
    // this.$http.get(`${this.$hostUrl}students`).then(response => {
    //   const studentsList = []
    //   $.each(response.data.data, (key, val) => {
    //     studentsList.push({
    //       id: val.id,
    //       name: val.full_name
    //     })
    //   })
    //   this.studentsList = studentsList
    // })
    // this.$http.get(`${this.$hostUrl}enroll-report?enrolled=true&page=${this.page}`).then(response => {
    //   this.currentPage = response.data.data.current_page
    //   this.allPages = Math.ceil(response.data.data.last_page / response.data.data.per_page)
    //   this.courseStudents = response.data.data.data
    // })
    const groups = []
    this.$http.get(`${this.$hostUrl}groups`).then((response) => {
      $.each(response.data.data, function (key, val) {
        groups.push({
          name: val.name,
          id: val.id
        })
      })
      this.groups = groups
    })
    const grades = []
    this.$http.get(`${this.$hostUrl}grades`).then((response) => {
      $.each(response.data.data, function (key, val) {
        grades.push({
          name: val.name,
          id: val.id
        })
      })
      this.grades = grades
    })
  },
  methods: {
    doAfterReload (data) {
      console.log(data.recordsTotal, 'data')
      this.total = data.recordsTotal
    },
    paginate ($event) {
      this.page = $event.target.innerHTML
      this.$http.get(`${this.$hostUrl}enroll-report?enrolled=true&page=${this.page}`).then(response => {
        this.currentPage = response.data.data.current_page
        this.courseStudents = response.data.data.data
      })
    },
    filterData (data) {
      switch (data) {
        case 'course':
          if (!this.selectedStudent) {
            swal('يجب ادخال المادة')
          } else {
            this.$http.get(`${this.$hostUrl}enroll-report?enrolled=true&student_id=${this.selectedStudent.id}`).then(response => {
              this.courseStudents = response.data.data.data
            })
          }
          break
        case 'unrolled':
          if (!this.selectedSubject) {
            swal('يجب ادخال المادة')
          } else {
            selectedSubject = this.selectedSubject.id
            slectedSubjectName = this.selectedSubject.name
            selectedGrade = this.selectedGrade.id
            selectedGroup = this.selectedGroup.id
            const table = this.$refs.table
            table.reload()
          }

          break
      }
    },
    clearData (data) {
      switch (data) {
        case 'student':
          this.selectedArea = ''
          this.selectedGroup = ''
          this.selectedGrade = ''
          this.students = []
          break
        case 'unrolled':
          this.selectedSubject = ''
          this.selectedGroup = ''
          this.selectedGrade = ''
          selectedSubject = this.selectedSubject.id
          selectedGrade = this.selectedGrade.id
          selectedGroup = this.selectedGroup.id
          // eslint-disable-next-line no-case-declarations
          const table = this.$refs.table
          table.reload()
          break
      }
    },
    doAlertCalls (data) {
      this.studentInfo = {
        name: data.full_name,
        id: data.id
      }
      this.showCallModal = true
      this.$http
        .get(`${this.$hostUrl}calls?student_id=${data.id}`)
        .then((response) => {
          this.studentCalls = response.data.data
        })
    },
    printReport (report) {
    // $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }
      if (report === 'course') {
        setTimeout(this.$htmlToPaper('print-courses', options), 10000)
        $('.card-footer').show()
      } if (report === 'subject') {
        setTimeout(this.$htmlToPaper('print-subjects', options), 10000)
        $('.card-footer').show()
      }
    },
    downloadFile (report) {
      if (report === 'student') {
        $.each(this.lectures, (key, val) => {
          this.lectureFile.push({
            الطالب: this.selectedStudent.name,
            الدورة: this.selectedCourse.name,
            الفرقة: this.grade,
            رقم_المحاضرة: val.lecture_number,
            الحضور: val.attendance.attend
          })
        })
        const data = this.lectureFile
        const fileName = ' تقرير حضور الطالب'
        const exportType = exportFromJSON.types.xls
        if (data) exportFromJSON({ data, fileName, exportType })
      } else {
        $.each(this.subjectAttendance, (key, val) => {
          this.subjectFile.push({
            المادة: this.selectedSubject.name,
            الفرقة: this.grade,
            اسم_الطالب: val.student_name,
            الهاتف: val.mobile,
            الحضور: val.attend_count,
            الحضور_الإستثنائي: val.exceptional_count
          })
        })
        const data = this.subjectFile
        const fileName = ' تقرير  الحضور في المادة'
        const exportType = exportFromJSON.types.xls
        if (data) exportFromJSON({ data, fileName, exportType })
      }
    }
  }
}

</script>
<style>
#collection-date{
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
    border-bottom: 1px solid #f0f2f5 !important;
}
.width{
  width:100%;
}
.online{
  border:1px solid #ddd
}
.times{
  margin-top: 10px !important;
}
.times h5{
  font-size:16px !important
}
.times .red{
  position: relative !important;
}
.filter-text{
  font-weight: 700;
    color: #dfad07;
    font-size: 15px !important;
}
.tab2{
  margin-top: 27px !important;
    margin-bottom: 41px !important;
    border-bottom: 1px solid #ddd;
    padding-bottom: 26px !important;
}
.pagi-item{
  color: #7b809a !important;
    padding: 0 !important;
    margin: 0 3px !important;
    border-radius: 50% !important;
    width: 36px !important;
    height: 36px !important;
    font-size: 0.875rem;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.pagi-active span{
  color: #fff !important;
   background-color: #4ea852 !important;
    border-color: #4ea852 !important;
}
.dt-buttons button{
  width: unset !important
}
.center-report .list2{
  width:40% !important
}
</style>
