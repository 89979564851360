<template>
     <div class="container-fluid py-4 add-student ">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  تعديل مدرس</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit='createInstructor' role="form" class="text-start mt-3">
                    <div class="row">
                      <div class="mb-3 col-lg-6 text-center">
                  <label for='image' class="required">

                  </label>
                  <img class="img-thumbnail insrt-img" :src='instructor.photo_url'  width="200px" height="200px" >
                  </div>
                      </div>
                       <div class="row">
                <div class="mb-3 col-lg-6">
                    <label>اسم المدرس<span class="red">*</span>
                    <span color='danger' class="error">{{errors.name?errors.name[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model="instructor.name"
                   placeholder="اسم المدرس"
                  />
                </div>
                  <div class="mb-3 col-lg-6" >
                  <label>
                     تغيير الصورة   <span class="red">*</span><span color="danger" class="error">{{
                      errors.photo ? errors.photo[0] : ''
                    }}</span></label>
                  <vmd-input
                    id="image"
                    type="file"
                    @change='onFileChange'
                  />
                </div>
                </div>
                 <div class="row">
                <div class="mb-3 col-lg-6">
                    <label>  هاتف المساعد <span class="green">(اختياري)</span><span color="danger" class="error">{{
                      errors.assistant_mobile ? errors.assistant_mobile[0] : ''
                    }}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   placeholder="  رقم الهاتف "
                   v-model="instructor.assistant_mobile"
                  />
                </div>
                </div>
               <!-- subjects -->
     <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
               <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3 float-right">مواد المدرس</h6>
              </div>
               <div class="col-lg-6">
               <span @click='newSubject($event)' class="badge badge-sm  new-student pointer"><i class="fas fa-location-arrow"></i>إضافة مادة </span>
               </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0 overflow">
              <table class="table align-items-center mb-0 ">
                <thead>
                  <tr class="text-center">
                       <th
                      class="text-uppercase   font-weight-bolder "
                    >
                       المادة<span class="red">*</span>
                    </th>
                      <th
                      class="text-uppercase   font-weight-bolder "
                    >
                        المجموعة<span class="red">*</span>
                    </th>
                      <th
                      class="text-uppercase text-secondary text-center  font-weight-bolder opacity-7"
                    >
                    </th>
                  </tr>
                </thead>
                 <tbody>
                  <tr v-for='(item,index) in allSubjects' :key='index' >
                      <td colspan="" style="width:45%">
                        <div class="">
                      <multiselect
                        class='required form-control'
                        id='group'
                        v-model="item.subject"
                        :options='subjects'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="  اختر المادة"
                        label='name'
                        :custom-label="customLabel"
                        :open-direction="top"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                    </td>
                     <td class="text-center" style="width:45%">
                      <div class="">
                 <multiselect
                        class='required form-control'
                        id='group'
                        v-model="item.group"
                        :options='groups'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="  اختر المجموعة"
                        :multiple="true"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                      </div>
                    </td>
                    <td class="text-center">
                     <span  @click='removeSubject(index)' class="badge badge-sm bg-gradient-danger delete pointer" style="width:100%;margin-top: 17px;">حذف</span>
                    </td>
                  </tr>
                 </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                   @click='goBack($event)'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="warning"
                    > رجوع</vmd-button
                  >
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'
import $ from 'jquery'
export default {
  name: 'EditTeacher',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      instructor: {},
      groups: [
        { id: '5d2a4482-5848-43c4-b30e-7d69ec2efcc5', name: '4' },
        { id: '7cdf25a8-4837-4480-98ae-2ed752287ad4', name: '2' },
        { id: '9a2f0d62-093c-4e38-a3f9-8c01cbf35739', name: '3' },
        { id: 'fff0c94b-3406-494e-9cce-516b021fbc39', name: '1' }],
      subjects: [],
      allSubjects: [],
      image: '',
      errors: []
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http.get(`${this.$hostUrl}instructors/${this.$route.params.id}`).then(response => {
      this.instructor = response.data.data
      $.each(this.instructor.subject_list, (key, val) => {
        this.allSubjects.push({
          subject: val,
          group: val.groups
        })
      })
      const subjects = []
      this.$http.get(`${this.$hostUrl}subjects`)
        .then((response) => {
          $.each(response.data.data, function (key, value) {
            subjects.push({
              name: value.name,
              id: value.id
            })
          })
          this.subjects = subjects
        })
    })
  },
  methods: {
    onFileChange (e) {
      const _URL = window.URL || window.webkitURL
      this.image = e.target.files[0]
      let file = ''
      if ((file = e.target.files[0])) {
        const img = new Image()
        img.src = _URL.createObjectURL(file)
      }
    },
    newSubject ($event, index) {
      this.allSubjects.push({
        subject: null,
        group: null

      })
    },
    removeSubject (index) {
      this.allSubjects.splice(index, 1)
    },
    goBack (e) {
      e.preventDefault()
      this.$router.push({ path: '/teachers' })
    },
    async createInstructor (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.instructor.name)) {
        swal('اسم المدرس يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.instructor.name)
      if (this.image) {
        formData.append('photo', this.image)
      }
      if (this.instructor.assistant_mobile) {
        formData.append('assistant_mobile', this.instructor.assistant_mobile)
      }
      const sendSubject = []
      for (let i = 0; i < this.allSubjects.length; i++) {
        const groupsId = []
        $.each(this.allSubjects[i].group, (key, val) => {
          groupsId.push(val.id)
        })
        sendSubject.push({
          subject_id: this.allSubjects[i].subject.id,
          group_id: groupsId
        })
      }
      formData.append('subjects', JSON.stringify(sendSubject))
      formData.append('_method', 'PUT')
      this.errors = await this.postRequest(formData, this.$hostUrl + 'instructors/' + this.$route.params.id, '/teachers')
    }
  }
}
</script>
<style >
.insrt-img{
  width: 170px;
    height: 170px;
    border-radius: 50%;
    float: right;
    margin-bottom: 15px;
}
</style>
