<template>
  <div class="container-fluid py-4 add-student ">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">عرض بيانات الطالب</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form role="form" class="text-start mt-3">
              <div class="row">
                <div class="table-responsive p-0 overflow student-info">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                       رقم الطالب
                      </th>
                      <td>{{student.number}}</td>
                    </tr>

                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                       اسم الطالب
                      </th>
                      <td>{{student.full_name}}</td>
                    </tr>
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                       رقم الهاتف
                      </th>
                      <td>{{student.mobile}}</td>
                    </tr>
                     <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        الفرقة
                      </th>
                      <td>{{student.grade_name}}</td>
                    </tr>
                     <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        المجموعة
                      </th>
                      <td>{{student.group_name}}</td>
                    </tr>
                     <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        المنطقة
                      </th>
                      <td>{{student.area_name}}</td>
                    </tr>

                  </thead>
                </table>
                </div>
                 <div class="mb-3 col-lg-12 tab-div">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation" style="width:20% !important">
        <button class="nav-link active btn  w-96 mb-0 bg-gradient-info btn-md null null btn-lg" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">الإيصالات المالية</button>
      </li>
       <li class="nav-item" role="presentation" style="width:20% !important">
        <button @click ="getStudentReceipts()" class="nav-link w-96 btn mb-0 bg-gradient-info btn-md null null btn-lg" id="receipts-tab" data-bs-toggle="tab" data-bs-target="#receipts" type="button" role="tab" aria-controls="receipts" aria-selected="true">الإيصالات المطبوعة</button>
      </li>
       <li class="nav-item" role="presentation" style="width:20% !important">
        <button @click ="getStudentPayments()" class="nav-link  w-96 btn mb-0 bg-gradient-info btn-md null null btn-lg" id="wallet-tab" data-bs-toggle="tab" data-bs-target="#wallet" type="button" role="tab" aria-controls="home" aria-selected="true">إيصالات المحفظة</button>
      </li>
      <li class="nav-item" role="presentation" style="width:20% !important" >
        <button class="nav-link btn mb-0 bg-gradient-info  w-96 btn-md null null btn-lg" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">أجهزة الطالب</button>
      </li>
      <li class="nav-item" role="presentation" style="width:20% !important">
        <button class="nav-link btn mb-0 bg-gradient-info   w-96 btn-md null null btn-lg" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">الدورات المسجل فيها </button>
      </li>
    </ul>
    </div>
              </div>
            </form>
          </div>
        </div>
        <!-- tabs -->
        <div class="row">
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show " id="profile" role="tabpanel" aria-labelledby="profile-tab">
     <!-- devices -->
         <div class="row mt-4">
          <div class="card col-12 my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                   الأجهزة المستخدمة
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0 overflow">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        #
                      </th>
                      <th
                        class="text-uppercase   font-weight-bolder"
                      >
                        اسم الجهاز
                      </th>
                      <th
                        class="text-uppercase   font-weight-bolder "
                      >
                        موديل
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                      تاريخ التسجيل في النظام
                      </th>
                        <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        أخر محاولة دخول
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                         حالة الجهاز
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                         تغيير
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(device,index) in devices" :key="index">
                      <td>
                        <p class="text-s mb-0">{{ device.readable_id}}</p>
                      </td>
                       <td>
                        <p class="text-s mb-0">{{ device.device_info.manufacturer}}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{device.device_info.model}}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0 ">
                          {{ device.created_at }}
                        </p>
                      </td>
                       <td>
                        <p class="text-s mb-0 ">
                          {{ device.last_try??'-' }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{
                           device.active?'مفتوح':'مقفول '
                          }}
                        </p>
                        </td>
                        <td>
                          &ensp;<a v-if='device.active' href="javascript:void(0);" @click="toggleDevice(device.id)"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm bg-gradient-success"><i class="fas fa-lock-open"></i></span></a>
                          &ensp;<a v-else href="javascript:void(0);" @click="toggleDevice(device.id)"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm bg-gradient-danger"><i class="fas fa-lock"></i></span></a>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
  </div>
  <!-- receipts -->
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
      <div class="row mt-4">
          <div class="card col-12 my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3">الإيصالات المالية</h6>
              </div>
               <div class="col-lg-2">
                    <router-link :to="{ name:' إضافة إيصال ', params: { id: student.id}}">
               <span  class="badge badge-sm new-student pointer"><i  class="fas fa-location-arrow"></i>إضافة ايصال </span>
                </router-link>
               </div>
                <div class="col-lg-2">
                  <router-link :to="{ name:' إيصال عرض  ', params: { id: student.id}}">
               <span  class="badge badge-sm new-student pointer"><i  class="fas fa-location-arrow"></i> ايصال عرض </span>
                 </router-link>
               </div>
                <div class="col-lg-2">
                <router-link :to="{ name:' إيصال مراجعة  ', params: { id: student.id}}">
               <span  class="badge badge-sm new-student pointer"><i  class="fas fa-location-arrow"></i> ايصال مراجعة </span>
                </router-link>
               </div>
              </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead style="background: #252527 !important">
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        #
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        الفرع
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        نوع الإيصال
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        اسم الدورة\العرض

                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        القيمة
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        التاريخ
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        المستخدم
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="receipt in receipts" :key="receipt.id">
                      <td>
                        <p class="text-s mb-0">{{ receipt.code }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ receipt.branch?receipt.branch.name:''}}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ receipt.transaction_type_name }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0" v-if="receipt.course_number">
                          {{
                            receipt.course_number + ' - ' + receipt.course
                          }}
                        </p>
                        <p class="text-s mb-0" v-else>
                          {{
                            receipt.offer
                          }}
                        </p>
                      </td>
                      <td  :class="{'paid-back' :receipt.transaction_type.name_en === 'pay',
                        'refund-back' :receipt.transaction_type.name_en === 'refund'}">
                        <p class="text-s mb-0">
                          {{ $filters.number(receipt.amount) }}
                        </p>
                      </td>
                       <td>
                        <p class="text-s mb-0">
                          {{receipt.created_at}}
                        </p>
                      </td>
                       <td>
                        <p class="text-s mb-0">
                          {{receipt.user_name}}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="back-color">
                    <tr>
                      <th colspan="4">الرصيد</th>
                      <td style="font-size:17px !important" class="bold bolder">{{ $filters.number(totalPayed) }}</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>
    <!-- printed receipts -->
     <div class="tab-pane fade show " id="receipts" role="tabpanel" aria-labelledby="receipts-tab">
      <div class="row mt-4">
          <div class="card col-12 my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3">الإيصالات المطبوعة</h6>
              </div>
              </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead style="background: #252527 !important">
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        #
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        نوع الإيصال
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        اسم الدورة\العرض

                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        القيمة
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        التاريخ
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        المستخدم
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="receipt in ReceiptPayments" :key="receipt.id">
                      <td>
                        <p class="text-s mb-0">{{ receipt.code }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ receipt.transaction_type_name }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0" v-if="receipt.course_number">
                          {{
                            receipt.course_number + ' - ' + receipt.course
                          }}
                        </p>
                        <p class="text-s mb-0" v-else>
                          {{
                            receipt.offer
                          }}
                        </p>
                      </td>
                      <td  :class="{'paid-back' :receipt.transaction_type.name_en === 'pay',
                        'refund-back' :receipt.transaction_type.name_en === 'refund'}">
                        <p class="text-s mb-0">
                          {{ $filters.number(receipt.amount) }}
                        </p>
                      </td>
                       <td>
                        <p class="text-s mb-0">
                          {{receipt.created_at}}
                        </p>
                      </td>
                       <td>
                        <p class="text-s mb-0">
                          {{receipt.user_name}}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>
    <!-- wallet -->
     <div class="tab-pane fade  " id="wallet" role="tabpanel" aria-labelledby="wallet-tab">
      <div class="row mt-4">
          <div class="card col-12 my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3"> إيصالات المحفظة</h6>
              </div>
              </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead style="background: #252527 !important">
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        #
                      </th>
                       <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        المستخدم
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        نوع الإيصال
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        نوع الحركة
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        القيمة
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        رصيد المحفظة
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        التاريخ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in walletPayments" :key="item.id">
                      <td>
                        <p class="text-s mb-0">{{ item.code }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ item.user?.user_name }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ item.transaction_type_name }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ item.receipt_type_name}}
                        </p>
                      </td>
                      <td :class="{'paid-back' :item.receipt_type?.name_en === 'fillWallet',
                        'refund-back' :item.receipt_type?.name_en === 'useWallet'}">
                        <p class="text-s mb-0">
                          {{ $filters.number(item.amount) }}
                        </p>
                      </td>
                            <td>
                          <p class="text-s mb-0">
                            {{ this.$filters.number(calculateAmount(index)) }}
                          </p>
                        </td>
                       <td>
                        <p class="text-s mb-0">
                          {{item.created_at}}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                 <tfoot class="back-color">
                    <tr >
                      <th colspan="5">إجمالي رصيد المحفظة</th>
                      <td style="font-size:17px !important" class="bold bolder">{{ $filters.number(walletTotal) }}</td>
                       <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>
  <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
          <!-- current registration -->
        <div class="row mt-4">
          <div class="col-12">
            <div class="card my-4">
              <div
                class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
              >
                <div
                  class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                >
                  <h6 class="text-white text-capitalize ps-3">
                    الدورات المسجل فيها الطالب
                  </h6>
                </div>
              </div>
              <div class="card-body px-0 pb-2">
                <div class="table-responsive p-0 overflow">
                  <table class="table align-items-center mb-0 student-enroll-c">
                    <thead>
                      <tr>
                         <th
                          class="text-uppercase  font-weight-bolder "
                        >
                          الرقم
                        </th>
                        <th
                          class="text-uppercase  font-weight-bolder "
                        >
                          اسم الدورة
                        </th>
                         <th
                          class="text-uppercase  font-weight-bolder "
                        >
                           الحضور
                        </th>
                         <th
                          class="text-uppercase  text-center font-weight-bolder "
                        >
                           الفيديوهات
                        </th>
                         <th
                          class="text-uppercase   font-weight-bolder "
                        >
                           حد المشاهدات
                        </th>
                           <th
                          class="text-uppercase   font-weight-bolder "
                        >
                            زيادة المشاهدات
                        </th>
                           <th
                          class="text-uppercase  font-weight-bolder "
                        >
                               مشاهدات الطالب
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(course,index) in myCourses" :key="index">
                        <td >
                          <p style="color:blue !important" @click ="getCoursePayments(course.id)" class="text-s mb-0 link to-link pointer">{{ course.number }}</p>
                        </td>
                        <td style=" width: 35%; white-space: normal;" class="wrap-text">
                          <p class="text-s mb-0">{{ course.name }}</p>
                        </td>
                         <td class="text-center">
                          <p class="text-s mb-0">{{ course.lectures_attended_count }}</p>
                        </td>
                        <td style="width: 50%;" class="text-center" >
                           <multiselect style="margin-top:0 !important" v-if="course.type"
                        class='required form-control margin0'
                        id='video'
                        v-model=" course.selectedvideo"
                        :options='myCourses[index].videos'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الفيديو"
                        open-direction="top"
                        label='name'
                        @select='getWatchLimit($event,index)'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                      <p v-else>الدورة أوفلاين</p>
                        </td>
                         <td style="width:8%" class="text-center">
                           <div class=" col-lg-8"  v-if="course.type">
                      <vmd-input
                      class="text-center"
                        id=""
                       type="text"
                        disabled
                        v-model='course.watchLimit'
                        />
                        </div>
                         <p v-else>-</p>
                        </td>
                        <td style="width:8%" class="text-center"> <div class="text-center col-lg-2 " v-if="course.type">
                  <vmd-button
                    class="text-center "
                    variant="gradient"
                    @click="increaseWatchLimit($event,index)"
                    color="info"
                    ><span> +</span></vmd-button
                  >
                    </div>
                     <p v-else>-</p>
                    </td>
                        <td style="" class="text-center" >
                         <ul class="" v-if="course.type && courseWatches">
                          <li class="watches-time" v-for ="(item,index) in course.watchTimes" :key="item.id">
                          مشاهدة #{{index+1}} - {{item.pivot.watched_at}}</li>
                          </ul>
                      <p v-else> - </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>
</div>
        </div>
      </div>
    </div>
    <!-- start course receipts modal-->
    <div>
    <vue-final-modal
      :clickToClose="true"
      v-model="showModal"
      class='cancel-offer call-modal course-payment'
      name="example"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="card my-4">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <div
            class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
          >
            <h6 class="text-white text-capitalize ps-3">الإيصالات المالية للدورة : {{coursePayments?.[0]?.course}} </h6>
          </div>
        </div>
         <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead style="background: #252527 !important">
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        #
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        المستخدم
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        نوع الإيصال
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        القيمة
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        التاريخ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="receipt in coursePayments" :key="receipt.id">
                      <td>
                        <p class="text-s mb-0">{{ receipt.code }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{receipt.user_name}}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ receipt.transaction_type_name }}
                        </p>
                      </td>
                      <td  :class="{'paid-back' :receipt.transaction_type.name_en === 'pay',
                        'refund-back' :receipt.transaction_type.name_en === 'refund'}">
                        <p class="text-s mb-0">
                          {{ $filters.number(receipt.amount) }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{receipt.created_at}}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="back-color">
                    <tr>
                      <th colspan="3">المبلغ المسدد</th>
                      <td style="font-size:17px !important" class="bold bolder">{{ $filters.number(totalCoursePaments) }}</td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
        <div class="card-footer text-center">
          <button
            id="unroll"
            @click="showModal = false"
            class="btn bg-gradient-info mx-1 col-4"
            >اغلاق </button>
        </div>
      </div>

    </vue-final-modal>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import { VueFinalModal } from 'vue-final-modal'
import swal from 'sweetalert'
export default {
  name: 'ShowStudent',
  components: {
    VmdInput,
    VmdButton,
    VueFinalModal
  },
  data () {
    return {
      showModal: false,
      student: {},
      courses: [],
      devices: [],
      myCourses: [],
      receipts: [],
      selectedVideo: '',
      courseWatches: false,
      totalPayed: 0,
      clicksCount: 0,
      walletPayments: [],
      walletTotal: 0,
      ReceiptPayments: [],
      totalCoursePaments: 0,
      coursePayments: []
    }
  },
  created () {
    const current = this
    this.$http
      .get(`${this.$hostUrl}students/${this.$route.params.id}`)
      .then((response) => {
        this.student = response.data.data
        this.devices = this.student.device
        this.myCourses = this.student.my_courses
        const receipts = this.student.payment_history
        receipts.forEach(function (receipt) {
          if (receipt.number === null && (receipt.offer || receipt.course)) {
            current.receipts.push(receipt)
            receipt.subject_name = receipt.payable.subject ? receipt.payable.subject.name : ''
            if (
              receipt.transaction_type.id ===
              '78d9a393-9b84-4372-bb38-5d8b42eccd29'
            ) {
              current.totalPayed -= receipt.amount
            } else {
              current.totalPayed += receipt.amount
            }
          }
        })
        current.receipts.sort(
          (p1, p2) => (Date.parse(p1.created_at) > Date.parse(p2.created_at)) ? 1 : (Date.parse(p1.created_at) < Date.parse(p2.created_at)) ? -1 : 0)
      })
  },
  computed: {
    calculateAmount () {
      return (currentIndex) => {
        if (currentIndex === 0) {
          // Display the first amount as is for the first row
          return this.walletPayments[currentIndex].amount
        } else {
          const previousTotal = this.calculateAmount(currentIndex - 1)
          const currentItem = this.walletPayments[currentIndex]

          if (currentItem.receipt_type?.name_en === 'fillWallet') {
            // Add the current amount to the previous total
            return previousTotal + currentItem.amount
          } else {
            // Subtract the current amount from the previous total
            return previousTotal - currentItem.amount
          }
        }
      }
    }
  },
  methods: {
    getWatchLimit ($event, index) {
      this.myCourses[index].watchLimit = $event.student_watch_limit
      this.myCourses[index].studentWatchLimit = $event.student_repeats
      this.myCourses[index].videoId = $event.id
      this.myCourses[index].watchTimes = $event.student_watches
      const array = this.myCourses[index].watchTimes
      array.sort((a, b) => new Date(a.pivot.watched_at).getTime() - new Date(b.pivot.watched_at).getTime())
      console.log(array, 'array')
      console.log(this.myCourses[index].watchTimes, 'watches')
      if (this.myCourses[index].watchTimes.length) {
        this.courseWatches = true
      } else {
        this.courseWatches = false
      }
    },
    async increaseWatchLimit ($event, index, e) {
      if (this.myCourses[index].watchLimit === 0 || this.myCourses[index].watchLimit === null) {
        swal('الدورة مجانية')
      }
      this.myCourses[index].watchLimit++
      const formData = new FormData()
      formData.append('video_id', this.myCourses[index].videoId)
      formData.append('watch_limit', this.myCourses[index].watchLimit)
      this.errors = await this.postRequest(formData, `${this.$hostUrl}student/${this.$route.params.id}/increase-limit`, '')
    },
    async toggleDevice (id) {
      swal({
        title: 'هل انت متأكد ؟',
        text: 'بمجرد الموافقة ، سيتم  تغيير حالة الجهاز !',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .post(
              `${this.$hostUrl}device/${id}/toggle/student/${this.$route.params.id}`
            )
            .then((response) => {
              if (!response.data.error) {
                swal({ title: response.data.message, buttons: 'تم' })
                // refresh data
                this.$http
                  .get(`${this.$hostUrl}students/${this.$route.params.id}`)
                  .then((response) => {
                    this.student = response.data.data
                    this.devices = this.student.device
                  })
              }
            }).catch(function (error) {
              if (Object.prototype.hasOwnProperty.call(error, 'response')) {
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
            })
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    getStudentPayments () {
      this.$http
        .get(`${this.$hostUrl}student/${this.$route.params.id}/payments`)
        .then((response) => {
          this.walletPayments = response.data.data.wallet_history
          this.walletTotal = response.data.data.wallet
        })
    },
    getStudentReceipts () {
      this.$http
        .get(`${this.$hostUrl}student/${this.$route.params.id}/payments`)
        .then((response) => {
          this.ReceiptPayments = response.data.data.payments
        })
    },
    getCoursePayments (courseId) {
      this.totalCoursePaments = 0
      this.coursePayments = []
      this.showModal = true
      this.$http
        .get(`${this.$hostUrl}student/${this.$route.params.id}/payments?course_id=${courseId}`)
        .then((response) => {
          this.coursePayments = response.data.data.payments
          this.coursePayments.map((receipt) => {
            if (receipt.transaction_type.id === '78d9a393-9b84-4372-bb38-5d8b42eccd29') {
              this.totalCoursePaments -= receipt.amount
            } else {
              this.totalCoursePaments += receipt.amount
            }
          })
        })
    }
  }
}

</script>
<style>
.margin0{
  margin-top:0 !important
}
.tab-div{
  margin-top: 50px !important;
}
.nav-tabs{
  border-bottom:unset !important
}
.tab-div li{
  width:33%
}
.tab-div .btn-info{
  color:#fff
}
.nav-tabs .nav-link.active,
 .nav-tabs .nav-item.show .nav-link{
  color:#fff !important;
  background-image: linear-gradient(195deg, #49a3f1 0%, #1a73e8 100%) !important;
 }
.nav-tabs .nav-link{
  color:#222 !important;
  background:#f8f9fa !important
}
.watches-time{
  font-weight: bold;
  font-size: 13px !important;
}
.paid-back{
 background: #baf2c5a1 !important;
}
.refund-back{
  background: #fb060669 !important;
}
.student-info table{
  width:75%;
  text-align: right;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
   rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.student-info td{
  background: #fff !important;
}
.close-btn{
  width:14%;
  margin-top:30px !important
}
#ch-receipt{
  padding-top: 20px;
  padding-right: 20px;
}
.student-enroll-c td{
  white-space: normal;
}
.w-96{
 width: 96% !important
}
.course-payment .modal-content{
  max-height: 700px !important;
}
</style>
