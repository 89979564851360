<template>
  <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">
                    تقارير المكالمات
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div id="" style="margin-top: 40px; direction: rtl">
              <div class="row align-center ex-row">
                <div class="mb-3 col-lg-3" v-if="!today">
                  <label> من : </label>
                  <vmd-input id="" type="date" v-model="fromDate" />
                </div>
                <div class="mb-3 col-lg-3" v-if="!today">
                  <label> الي: </label>
                  <vmd-input id="" type="date" v-model="toDate" />
                </div>
                <div class="mb-3 col-lg-3" >
                  <label>المستخدمين</label>
                  <multiselect
                    class="required form-control"
                    id="student"
                    v-model="selectedUser"
                    :options="users"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder=" اختر المستخدم "
                    label="name"
                    track-by="id"
                    required="required"
                    :internal-search="true"
                  >
                    >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
                <div class="mb-3 col-lg-3">
                  <label>نوع الفيدياك</label>
                  <multiselect
                    class="required form-control"
                    id="student"
                    v-model="selectedFeedback"
                    :options="feedbackCategories"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder="  اختر النوع "
                    label="name"
                    track-by="id"
                    required="required"
                    :internal-search="true"
                  >
                    >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
                <div class="mb-3 col-lg-3 today-call">
                  <vmd-checkbox
                    id="free-video"
                    type="text"
                    :checked="today"
                    v-model="today"
                    :value="1"
                    @change="filterCalls"
                  >
                    مكالمات مجدولة اليوم
                  </vmd-checkbox>
                </div>
                <div class="mb-3 col-lg-3">
                  <vmd-button
                    class="my-4 mb-2 width action-btn"
                    variant="gradient"
                    color="info"
                    @click="filterCalls()"
                  >
                    فلتر</vmd-button
                  >
                </div>
              </div>
              <div class="row">
                <vdtnet-table
                  class="table-striped table table-borderless"
                  :jquery="jq"
                  ref="table"
                  :fields="fields"
                  :opts="options"
                  @edit="doAlertEdit"
                  @show="doAlertShow"
                  @changeStatus="doAlertChangeStatus"
                  @add="doAlertLecture"
                  @getCalls="doAlertCalls"
                  @promoCode="createPromoCode"
                  @registerGroup="doAlertRegisterGroup"
                >
                </vdtnet-table>
              </div>
            </div>
          </div>
          <!-- seconed tab -->
        </div>
      </div>
    </div>
      <CreateCall
      v-model="showModal"
      @hide-modal="showModal = false"
      :studentInfo="studentInfo"
      :calls="studentCalls"
      @update-data="updateData"
    ></CreateCall>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'
import VmdCheckbox from '@/components/VmdCheckbox.vue'
import VdtnetTable from 'vue-datatables-net'
import CreateCall from '@/components/CreateCall.vue'
import $ from 'jquery'
let selectedFeedback = ''
let selectedUser = ''
let fromDate = ''
let toDate = ''
let today = ''
export default {
  name: 'Calls',
  components: {
    VmdButton,
    VmdInput,
    VmdCheckbox,
    CreateCall,
    VdtnetTable
  },
  data () {
    return {
      calls: [],
      showModal: false,
      studentCalls: [],
      studentInfo: {},
      fromDate: fromDate,
      toDate: toDate,
      users: [],
      selectedUser: selectedUser,
      feedbackCategories: [],
      selectedFeedback: selectedFeedback,
      today: today,
      errors: [],
      pagination: {
        rowsPerPage: 30
      },
      jq: $,
      options: {
        ajax: {
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + localStorage.token
            )
          },
          url: `${this.$hostUrl}calls`,
          data: (d) => {
            if (selectedUser) {
              d.user_id = selectedUser ?? null
            }
            if (selectedFeedback) {
              d.feedback_category_id = selectedFeedback ?? null
            }
            if (fromDate && !today) {
              d.from = fromDate
            }
            if (toDate && !today) {
              d.to = toDate
            }
            if (today) {
              d.today = 1
            }
          },
          dataSrc: (json) => {
            return json.data
          }
        },
        buttons: [
          {
            extend: 'print',
            text: '',
            titleAttr: 'طباعة',
            className: 'fas fa-print btn-primary',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6, 7]
            },
            customize: function (win) {
              $(win.document.body).css('direction', 'rtl')
            }
          },
          {
            extend: 'copy',
            text: '',
            className: 'fas fa-copy btn-warning',
            titleAttr: 'نسخ',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          },
          {
            extend: 'excel',
            text: '',
            titleAttr: 'إكسل',
            className: 'fas fa-table btn-success',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          }
        ],
        dom:
          "<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6 added'l>>" +
          "<'row'<'col-sm-12'Btr>>" +
          "<'row'<'col-sm-12 col-md-6'p><'col-sm-12 col-md-6'i>>",
        paging: true,
        language: {
          url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Arabic.json',
          processing:
            '<i class="fa fa-spinner fa-spin" style="font-size:40px;color:#7aeb7f"></i>'
        },
        responsive: false,
        processing: true,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        ordering: false,
        lengthChange: true,
        serverSide: true,
        fixedHeader: true,
        saveState: true,
        select: false
      },
      fields: {
        user: {
          label: 'المستخدم',
          sortable: true,
          searchable: true,
          render: (user) => user?.user_name || null
        },
        'student.number': {
          label: 'رقم الطالب',
          sortable: true,
          searchable: true
        },
        student: {
          label: 'اسم الطالب',
          sortable: true,
          searchable: true,
          render: (student) => student?.full_name || null
        },
        'student.mobile': {
          label: ' الهاتف',
          sortable: true,
          searchable: true
        },
        'feedback_category.name': {
          label: 'نوع الفيدباك',
          sortable: true,
          searchable: true
        },
        feedback: {
          label: 'فيدباك',
          sortable: true,
          searchable: true,
          render: this.setMaxWidth
        },
        rating: {
          label: 'التقييم',
          sortable: true,
          searchable: true,
          render: this.getRating
        },
        next_call: {
          label: ' المكالمة القادمة ',
          sortable: true,
          searchable: true
        },
        addCall: {
          label: '  اضافة مكالمة ',
          sortable: true,
          searchable: true,
          isLocal: true,
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="getCalls"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm btn-info">اضافة</span></a>'
        },
        created_at: {
          label: 'تاريخ الإنشاء',
          sortable: true,
          searchable: true,
          defaultOrder: 'desc'
        }
      }
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}users`).then((response) => {
      this.users = response.data.data
      this.users.unshift({
        name: localStorage.userName,
        id: localStorage.userId
      })
    })
    const feedbackCategories = []
    this.$http.get(`${this.$hostUrl}feedback-categories`).then((response) => {
      $.each(response.data.data, function (key, value) {
        feedbackCategories.push({
          name: value.name,
          id: value.id
        })
      })
      this.feedbackCategories = feedbackCategories
    })
  },
  methods: {
    doAlertCalls (data) {
      this.studentInfo = {
        name: data.student.full_name,
        id: data.student.id
      }
      this.showModal = true
      this.$http
        .get(`${this.$hostUrl}calls?student_id=${data.student.id}`)
        .then((response) => {
          this.studentCalls = response.data.data
        })
    },
    updateData () {
      const table = this.$refs.table
      table.reload()
    },
    filterCalls () {
      selectedUser = this.selectedUser?.id || null
      selectedFeedback = this.selectedFeedback?.id || null
      fromDate = this.fromDate
      toDate = this.toDate
      today = this.today
      const table = this.$refs.table
      table.reload()
    },
    getTodayCalls () {
      if (this.today) {
        this.$http
          .get(
            `${this.$hostUrl}calls?feedback_category_id=${
              this.selectedFeedback?.id ?? ''
            }&today=1&only_me=1`
          )
          .then((response) => {
            this.calls = response.data.data
          })
      } else {
        this.filterCalls()
      }
    },
    setMaxWidth (data) {
      return `<span class="wrap-text max-w">${data}</span>`
    },
    getRating (data) {
      if (data) {
        switch (true) {
          case data > 5:
            return ` <span   class=" success-back badge rating-con font-size-12">
      ${data}<i class="fas fa-star w-star"></i></span>`
            break
          case data > 2 && data < 6:
            return ` <span   class=" warning-back badge rating-con font-size-12">
      ${data}<i class="fas fa-star w-star"></i></span>`
            break
          default:
            return ` <span   class=" danger-back badge rating-con font-size-12">
      ${data}<i class="fas fa-star w-star"></i></span>`
        }
      } else {
        return ''
      }
    }
  }
}
</script>
<style>
.today-call {
  display: flex !important;
}
#collection-date {
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
  border-bottom: 1px solid #f0f2f5 !important;
}
.width {
  width: 100%;
}
.online {
  border: 1px solid #ddd;
}
.times {
  margin-top: 10px !important;
}
.times h5 {
  font-size: 16px !important;
}
.times .red {
  position: relative !important;
}
.filter-text {
  font-weight: 700;
  color: #dfad07;
  font-size: 15px !important;
}
.tab2 {
  margin-top: 27px !important;
  margin-bottom: 41px !important;
  border-bottom: 1px solid #ddd;
  padding-bottom: 26px !important;
}
.course-info {
  background: #f8f9fa;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 38px;
  font-size: 14px;
}
.info-h {
  font-size: 14px !important;
  font-weight: 500;
}
.groups-ul {
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 206px;
  border-top: 2px solid #5cb360;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  display: none;
  position: absolute;
  border-radius: 10px;
}
/* .groups-con{
  color:blue;
  cursor: pointer;
} */
.groups-con:hover .groups-ul {
  display: block;
}
.success-back {
  background: #4caf5094 !important;
  font-size: 12px !important;
  font-weight: bold;
  font-family: Montserrat, sans-serif !important;
}
.warning-back {
  background: #fbe520 !important;
  font-size: 12px !important;
  font-weight: bold;
  font-family: Montserrat, sans-serif !important;
}
.danger-back {
  background: #ef4b4b !important;
  font-size: 12px !important;
  font-weight: bold;
  font-family: Montserrat, sans-serif !important;
}

.info-data {
  font-family: "Roboto", Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
  font-size: 17px !important;
}
.max-w {
  display: inline-block !important;
  width: 273px !important;
  white-space: normal !important;
}
.w-star{
  color:#fff !important
}
.rating-con{
  letter-spacing:6px
}
</style>
