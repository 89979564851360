<template>
  <div class="container-fluid py-4 add-student grade-detail attend-report">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">تقارير الحضور</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="mb-3 col-12 tab-div tab2">
              <ul
                class="nav nav-tabs justify-content-center"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item list2" role="presentation">
                  <button
                    style="width: 100%"
                    class="nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    تقرير الطالب
                  </button>
                </li>
                <!-- <li class="nav-item list2" role="presentation">
                  <button
                    style="width: 100%"
                    class="nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    تقرير المادة
                  </button>
                </li> -->
                <li class="nav-item list2" role="presentation">
                  <button
                    style="width: 100%"
                    class="nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg"
                    id="date-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#date"
                    type="button"
                    role="tab"
                    aria-controls="date"
                    aria-selected="false"
                  >
                    تقرير التاريخ
                  </button>
                </li>
                <li class="nav-item list2" role="presentation">
                  <button
                    style="width: 100%"
                    class="nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg"
                    id="lecture-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#lecture"
                    type="button"
                    role="tab"
                    aria-controls="lecture"
                    aria-selected="false"
                  >
                    تقرير الحضور للمحاضرة
                  </button>
                </li>
                <li class="nav-item list2" role="presentation">
                  <button
                    style="width: 100%"
                    class="nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg"
                    id="lectures-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#lectures"
                    type="button"
                    role="tab"
                    aria-controls="lectures"
                    aria-selected="false"
                  >
                    تقرير الحضور للمحاضرات
                  </button>
                </li>
              </ul>
            </div>
            <!-- exceptional attendance row -->
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div class="row align-center ex-row">
                  <div class="mb-3 col-lg-3">
                    <label>المادة</label>
                    <multiselect
                      class="required form-control"
                      id="student"
                      v-model="selectedSubject"
                      :options="subjects"
                      :searchable="true"
                      :close-on-select="true"
                      placeholder=" اختر المادة"
                      label="name"
                      @select="getCourses($event)"
                      track-by="id"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="mb-3 col-lg-3">
                    <label> اسم الدورة<span class="red">*</span></label>
                    <multiselect
                      class="required form-control"
                      id="grade"
                      v-model="selectedCourse"
                      :options="courses"
                      @select="getStudents($event)"
                      :searchable="true"
                      :close-on-select="true"
                      placeholder=" اختر الدورة"
                      label="name"
                      track-by="id"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="mb-3 col-lg-3">
                    <label>الطالب<span class="red">*</span></label>
                    <multiselect
                      class="required form-control"
                      id="student"
                      v-model="selectedStudent"
                      :options="students"
                      :searchable="true"
                      :close-on-select="true"
                      placeholder=" اختر الطالب"
                      label="name"
                      track-by="id"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="text-center col-lg-1">
                    <vmd-button
                      class="my-4 mb-2 width action-btn"
                      variant="gradient"
                      color="primary"
                      @click="filterData('student')"
                    >
                      فلتر</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-1">
                    <vmd-button
                      class="my-4 mb-2 width action-btn"
                      variant="gradient"
                      color="info"
                      @click="printReport('student')"
                    >
                      طباعة</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-1">
                    <vmd-button
                      class="my-4 mb-2 width action-btn"
                      variant="gradient"
                      color="warning"
                      @click="downloadFile('student')"
                    >
                      اكسل</vmd-button
                    >
                  </div>
                </div>
                <div id="print-report" style="margin-top: 40px; direction: rtl">
                  <div
                    class="row times"
                    style="margin-top: 40px; direction: rtl"
                  >
                    <div class="col-md-4">
                      <h5>
                        الدورة التدريبية :<span class="green">{{
                          selectedCourse.name
                        }}</span>
                      </h5>
                    </div>
                    <div class="col-md-4">
                      <h5>
                        الطالب:
                        <span class="green">{{ selectedStudent.name }}</span>
                      </h5>
                    </div>
                  </div>
                  <div
                    class="row align-center"
                    style="margin-top: 40px; direction: rtl"
                  >
                    <div class="text-center col-lg-12 overflow-x" style="direction: rtl">
                      <table class="b-block table" style="direction: rtl">
                        <thead>
                          <tr>
                            <th>المحاضرة</th>
                            <th>حضور</th>
                            <th>غياب</th>
                            <th>التاريخ</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="lecture in lectures" :key="lecture.id">
                            <td>{{ lecture.lecture_number }}</td>
                            <td class="">
                              <span
                                v-if="lecture.attendance.attended !== 0"
                                class="badge badge-sm bg-gradient-success"
                                ><i class="fas fa-check"></i
                              ></span>
                            </td>
                            <td class="">
                              <span
                                v-if="lecture.attendance.attended === 0"
                                class="badge badge-sm bg-gradient-success"
                                ><i class="fas fa-check"></i
                              ></span>
                            </td>
                            <td>{{ lecture.start }}</td>
                          </tr>
                          <tr></tr>
                          <tr style="background: #dad6d669; font-weight: bold">
                            <td>الإجمالي</td>
                            <td>{{ courseAttendance ?? "" }}</td>
                            <td>{{ courseAbsence ?? "" }}</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row"></div>
                </div>
              </div>

              <!-- <div
                class="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <subjectAttendance></subjectAttendance>
              </div> -->
              <!-- date tab -->
              <div
                class="tab-pane fade"
                id="date"
                role="tabpanel"
                aria-labelledby="date-tab"
              >
                <div class="px-0 pb-2">
                  <CreateCall
                    v-model="showModal"
                    @hide-modal="showModal = false"
                    :studentInfo="studentInfo"
                    :calls="calls"
                    @update-data="updateData"
                  ></CreateCall>
                  <!-- exceptional attendance row -->
                  <span class="filter-text"
                    >اختر الفرقة ثم المادة للدورة المناسبة</span
                  >
                  <div class="row align-center ex-row">
                    <div class="mb-3 col-lg-3">
                      <label> من : </label>
                      <vmd-input id="" type="date" v-model="fromDate" />
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label> الي: </label>
                      <vmd-input id="" type="date" v-model="toDate" />
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label>الفرقة</label>
                      <multiselect
                        class="required form-control"
                        id="student"
                        v-model="selectedGrade"
                        :options="grades"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder=" اختر الفرقة"
                        @select="getSubjects($event)"
                        label="name"
                        track-by="id"
                        required="required"
                        :internal-search="true"
                      >
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label>المجموعة</label>
                      <multiselect
                        class="required form-control"
                        id="student"
                        v-model="selectedGroup"
                        :options="groups"
                        :searchable="true"
                        :multiple="true"
                        :close-on-select="true"
                        placeholder=" اختر المجموعة"
                        label="name"
                        track-by="id"
                        required="required"
                        :internal-search="true"
                      >
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                  </div>
                  <div class="row align-center ex-row">
                    <div class="mb-3 col-lg-3">
                      <label>المادة</label>
                      <multiselect
                        class="required form-control"
                        id="student"
                        v-model="selectedSubject"
                        :options="subjects"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder=" اختر المادة"
                        label="name"
                        @select="getCourses($event)"
                        track-by="id"
                        required="required"
                        :internal-search="true"
                      >
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label>الدورة</label>
                      <multiselect
                        class="required form-control"
                        id="student"
                        v-model="selectedCourse"
                        :options="courses"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder=" اختر الدورة"
                        label="name"
                        track-by="id"
                        required="required"
                        :internal-search="true"
                      >
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label>حالة الحضور</label>
                      <div class="input-group input-group-outline null">
                        <select class="form-control" v-model="selectedStatus">
                          <option selected value="">الكل</option>
                          <option value="2">الحضور</option>
                          <option value="1">الغياب</option>
                        </select>
                        <i class="fas fa-caret-down" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="text-center col-lg-1">
                      <vmd-button
                        class="my-4 mb-2 width action-btn"
                        variant="gradient"
                        color="primary"
                        @click="filterData('date')"
                      >
                        فلتر</vmd-button
                      >
                    </div>
                    <!-- <div class="text-center col-lg-2">
                  <vmd-button
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="warning"
                    @click="downloadFile('subject')"
                    >  اكسل</vmd-button
                  >
                </div> -->
                    <div class="text-center col-lg-1">
                      <vmd-button
                        class="my-4 mb-2 width action-btn"
                        variant="gradient"
                        color="info"
                        @click="printReport('date')"
                      >
                        طباعة</vmd-button
                      >
                    </div>
                  </div>
                  <div id="print-date" style="margin-top: 40px; direction: rtl">
                    <div v-for="item in dateAttendance" :key="item.id">
                      <div
                        class="row times"
                        style="margin-top: 40px; direction: rtl"
                      >
                        <div class="col-md-4" v-if="item.students.length > 0">
                          <h5>
                            التاريخ : <span class="green">{{ fromDate }}</span>
                          </h5>
                        </div>
                        <div class="col-md-6">
                          <h5>
                            الدورة التدريبية :
                            <span class="green">{{ selectedCourse.name }}</span>
                          </h5>
                        </div>
                        <div class="col-md-2">
                          <h5>
                            المحاضرة :
                            <span class="green">{{ item.lecture }}</span>
                          </h5>
                        </div>
                      </div>
                      <div
                        class="row align-center"
                        style="margin-top: 40px; direction: rtl"
                        id="print-report"
                      >
                        <div
                          class="text-center col-lg-12 overflow-x"
                          style="direction: rtl"
                        >
                          <table class="b-block table" style="direction: rtl">
                            <thead>
                              <tr>
                                <th rowspan="2">رقم</th>
                                <th rowspan="2">الاسم</th>
                                <th rowspan="2">الهاتف</th>
                                <th class="call-th" colspan="3">المكالمات</th>
                                <th rowspan="2">الفرقة</th>
                                <th rowspan="2">المجموعة</th>
                                <th rowspan="2">استثنائي</th>
                                <th rowspan="2">حضور</th>
                                <th rowspan="2">غياب</th>
                                <th rowspan="2">تاريخ المحاضرة</th>
                              </tr>
                              <tr class="call-th">
                                <th>اضافة</th>
                                <th>اخر مكالمة</th>
                                <th>المكالمة القادمة</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="student in item.students"
                                :key="student.id"
                              >
                                <td>{{ student.number }}</td>
                                <td>{{ student.full_name }}</td>
                                <td>
                                  <a
                                    class="to-link"
                                    :href="`tel:${student.mobile}`"
                                  >
                                    {{ student.mobile }}</a
                                  >
                                </td>
                                <td class="call-th" id="add-call-btn">
                                  <button
                                    style="width: unset !important"
                                    @click="
                                      getCallDetails(
                                        student.full_name,
                                        student.id,
                                        student.calls
                                      )
                                    "
                                    class="badge btn btn-info badge-sm pointer"
                                  >
                                    إضافة
                                  </button>
                                </td>
                                <td class="call-th">
                                  {{
                                    student.last_call
                                      ? student.last_call.created_at
                                      : null
                                  }}
                                </td>
                                <td class="call-th">
                                  {{
                                    student.last_call
                                      ? student.last_call.next_call_date
                                      : null
                                  }}
                                </td>
                                <td>
                                  {{ student.grade ? student.grade.name : "" }}
                                </td>
                                <td>
                                  {{ student.group ? student.group.name : "" }}
                                </td>
                                <td>{{ student.pivot.exceptional ?? "-" }}</td>
                                <td class="text-center">
                                  <span
                                    v-if="student.pivot.attended"
                                    class="badge badge-sm bg-gradient-success"
                                    ><i class="fas fa-check"></i
                                  ></span>
                                </td>
                                <td class="text-center">
                                  <span
                                    v-if="!student.pivot.attended"
                                    class="badge badge-sm bg-gradient-success"
                                    ><i class="fas fa-check"></i
                                  ></span>
                                </td>
                                <td>
                                  {{
                                    student.lecture_date || student.attend_date
                                  }}
                                </td>
                              </tr>
                              <tr class="card-footer"
                                style="background: #dad6d669; font-weight: bold"
                              >
                                <td colspan="9">الإجمالي</td>
                                <td>{{ item.attendance_count }}</td>
                                <td>{{ item.absence_count }}</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- lecture  -->
              <div
                class="tab-pane fade"
                id="lecture"
                role="tabpanel"
                aria-labelledby="lecture-tab"
              >
               <LectureAttendance/>
              </div>
              <!-- lectures  -->
              <div
                class="tab-pane fade"
                id="lectures"
                role="tabpanel"
                aria-labelledby="lectures-tab"
              >
                <div class="px-0 pb-2">
                  <span class="filter-text"
                    >اختر الفرقة ثم المادة للدورة المناسبة</span
                  >
                  <!-- exceptional attendance row -->
                  <div class="row align-center ex-row">
                    <div class="mb-3 col-lg-3">
                      <label>الفرقة</label>
                      <multiselect
                        class="required form-control"
                        id="student"
                        v-model="selectedGrade"
                        :options="grades"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder=" اختر الفرقة"
                        @select="getSubjects($event)"
                        label="name"
                        track-by="id"
                        required="required"
                        :internal-search="true"
                      >
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label>المجموعة</label>
                      <multiselect
                        class="required form-control"
                        id="student"
                        v-model="selectedGroup"
                        :options="groups"
                        :multiple="true"
                        :close-on-select="true"
                        placeholder=" اختر المجموعة"
                        label="name"
                        track-by="id"
                        required="required"
                        :internal-search="true"
                      >
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label>المادة</label>
                      <multiselect
                        class="required form-control"
                        id="student"
                        v-model="selectedSubject"
                        :options="subjects"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder=" اختر المادة"
                        label="name"
                        @select="getCourses($event)"
                        track-by="id"
                        required="required"
                        :internal-search="true"
                      >
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label> اسم الدورة<span class="red">*</span></label>
                      <multiselect
                        class="required form-control"
                        id="grade"
                        v-model="selectedCourse"
                        :options="courses"
                        @select="getStudents($event)"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder=" اختر الدورة"
                        label="name"
                        track-by="id"
                        required="required"
                        :internal-search="true"
                      >
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label>الطالب</label>
                      <multiselect
                        class="required form-control"
                        id="student"
                        v-model="selectedStudent"
                        :options="students"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder=" اختر الطالب"
                        label="name"
                        track-by="id"
                        required="required"
                        :internal-search="true"
                      >
                        >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="text-center col-lg-2">
                      <vmd-button
                        class="my-4 mb-2 width"
                        variant="gradient"
                        color="primary"
                        @click="filterData('lectures')"
                      >
                        فلتر</vmd-button
                      >
                    </div>
                    <div class="text-center col-lg-2">
                      <vmd-button
                        class="my-4 mb-2 width"
                        variant="gradient"
                        color="info"
                        @click="printReport('lectures')"
                      >
                        طباعة</vmd-button
                      >
                    </div>
                  </div>
                  <div
                    id="print-lectures"
                    style="margin-top: 40px; direction: rtl"
                  >
                    <div
                      class="row times"
                      style="margin-top: 40px; direction: rtl"
                    >
                      <div class="col-md-4">
                        <h5>
                          المادة :
                          <span class="green">{{ selectedSubject.name }}</span>
                        </h5>
                      </div>
                      <div class="col-md-4">
                        <h5>
                          الدورة :
                          <span class="green">{{ selectedCourse.name }}</span>
                        </h5>
                      </div>
                    </div>
                    <div
                      class="row align-center"
                      style="margin-top: 40px; direction: rtl"
                    >
                      <div
                        class="text-center col-lg-12 overflow-x"
                        style="direction: rtl"
                      >
                        <table class="b-block table" style="direction: rtl">
                          <thead>
                            <tr>
                              <th>رقم</th>
                              <th>اسم الطالب</th>
                              <th>الهاتف</th>
                              <th>الفرقة</th>
                              <th>المجموعة</th>
                              <th
                                v-for="(lecture, index) in allLectures"
                                :key="index"
                              >
                                {{ lecture.lecture_number }}
                              </th>
                              <th>الحضور</th>
                              <th>اخري</th>
                              <th>استثنائي</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="student in lecturesAttendance"
                              :key="student.id"
                            >
                              <td>{{ student.number }}</td>
                              <td>{{ student.full_name }}</td>
                              <td>{{ student.mobile }}</td>
                              <td>
                                {{ student.grade ? student.grade.name : "" }}
                              </td>
                              <td>
                                {{ student.group ? student.group.name : "" }}
                              </td>
                              <td
                                v-for="(item, index) in student.lectures"
                                :key="index"
                              >
                                <span
                                  v-if="item.attended"
                                  class="badge badge-sm bg-gradient-success"
                                  ><i class="fas fa-check"></i
                                ></span>
                              </td>
                              <td>{{ student.attend_count }}</td>
                              <td>-</td>
                              <td>{{ student.exceptional_count }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'
import CreateCall from '@/components/CreateCall.vue'
import LectureAttendance from '@/components/LectureAttendance'
import swal from 'sweetalert'
import $ from 'jquery'
import exportFromJSON from 'export-from-json'
const date = new Date()
const today =
  date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
export default {
  name: 'StudentAttendanceReport',
  components: {
    VmdInput,
    VmdButton,
    CreateCall,
    LectureAttendance
  },
  data () {
    return {
      tableDate: today,
      fromDate: today,
      toDate: today,
      courses: [],
      selectedCourse: '',
      students: [],
      selectedStudent: '',
      groups: [],
      selectedGroup: '',
      subjects: [],
      selectedSubject: '',
      grades: [],
      selectedGrade: '',
      subjectFile: [],
      lectures: [],
      lecturesList: [],
      selectedLecture: '',
      lectureFile: [],
      subjectAttendance: [],
      courseAttendance: null,
      courseAbsence: null,
      dateAttendance: [],
      lectureAttendance: [],
      lecturesAttendance: [],
      totalAttend: '',
      totalExceptional: '',
      selectedStatus: '',
      exceptional: '',
      grade: '',
      subjectName: '',
      allLectures: [],
      totalLectureAttand: [],
      lectAttendance: true,
      studentInfo: {},
      calls: [],
      showModal: false,
      showLectCalls: false,
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}courses`).then((response) => {
      this.courses = response.data.data.filter((course) => {
        return course.type === 0
      })
    })
    // const students = []
    // this.$http.get(`${this.$hostUrl}students`).then(response => {
    //   // this.students = response.data.data
    //   $.each(response.data.data, (key, val) => {
    //     students.push({
    //       name: val.full_name,
    //       id: val.id
    //     })
    //   })
    //   this.students = students
    // })
    this.$http.get(`${this.$hostUrl}subjects`).then((response) => {
      this.subjects = response.data.data
    })
    this.$http.get(`${this.$hostUrl}groups`).then((response) => {
      this.groups = response.data.data
    })
    this.$http.get(`${this.$hostUrl}grades`).then((response) => {
      this.grades = response.data.data
    })
  },
  methods: {
    getSubjects ($event) {
      this.selectedSubject = ''
      this.$http
        .get(`${this.$hostUrl}grade/${$event.id}/subjects?semester=1`)
        .then((response) => {
          this.subjects = response.data.data
        })
    },
    getCourses ($event) {
      this.selectedCourse = ''
      this.$http
        .get(`${this.$hostUrl}courses?subject_id=${$event.id}`)
        .then((response) => {
          this.courses = response.data.data.filter((course) => {
            return course.type === 0
          })
          if (!this.courses.length) {
            swal(' لا توجد دورات اوفلاين تابعه للمادة')
          }
        })
    },
    getLectures ($event) {
      this.$http
        .get(`${this.$hostUrl}course/${$event.id}/lectures`)
        .then((response) => {
          if (!response.data.data.length) {
            swal('لا توجد محاضرات')
          }
          const lectureList = []
          $.each(response.data.data, (key, val) => {
            lectureList.push({
              id: val.id,
              name: val.lecture_number + ' ' + '-' + ' ' + val.start
            })
          })
          this.lecturesList = lectureList
        })
    },
    getStudents ($event) {
      this.selectedStudent = ''
      this.$http
        .get(`${this.$hostUrl}courses/${$event.id}`)
        .then((response) => {
          // this.students = response.data.data
          const students = []
          $.each(response.data.data.students, (key, val) => {
            students.push({
              name: val.full_name,
              id: val.id
            })
          })
          this.students = students
        })
    },
    getCallDetails (studentName, studentId, calls) {
      this.studentInfo = {
        name: studentName,
        id: studentId
      }
      this.$http
        .get(`${this.$hostUrl}calls?student_id=${studentId}`)
        .then((response) => {
          this.calls = response.data.data
        })
      this.showModal = true
    },

    // for date calls
    updateData () {
      let allSelectedGroups = []
      $.each(this.selectedGroup, function (key, value) {
        allSelectedGroups.push(value.id)
      })
      allSelectedGroups = JSON.stringify(allSelectedGroups).replace(/\s/g, '')
      this.$http
        .get(
          `${this.$hostUrl}attendance-report?from=${this.fromDate}&to=${
            this.toDate
          }&attend=${this.selectedStatus}&course_id=${
            this.selectedCourse.id
          }&group_id=${this.selectedGroup ? allSelectedGroups : ''}`
        )
        .then((response) => {
          this.dateAttendance = response.data.data.data
        })
    },

    filterData (data) {
      switch (data) {
        case 'student':
          if (!this.selectedCourse || !this.selectedStudent) {
            swal('يجب ادخال كل البيانات')
          } else {
            this.$http
              .get(
                `${this.$hostUrl}attendance-report?student_id=${this.selectedStudent.id}&course_id=${this.selectedCourse.id}`
              )
              .then((response) => {
                this.courseAttendance = response.data.data.attend_count
                this.courseAbsence = response.data.data.absent_count
                this.lectures = response.data.data.lectures
                console.log(this.subjectFile)
                if (!this.lectures.length) {
                  swal('لا يوجد محاضرات ')
                }
              })
          }
          break
        case 'date':
          if (!this.selectedCourse) {
            swal('برجاء ادخال الدورة')
          } else {
            let allSelectedGroups = []
            $.each(this.selectedGroup, function (key, value) {
              allSelectedGroups.push(value.id)
            })
            allSelectedGroups = JSON.stringify(allSelectedGroups).replace(
              /\s/g,
              ''
            )
            this.$http
              .get(
                `${this.$hostUrl}attendance-report?from=${this.fromDate}&to=${
                  this.toDate
                }&attend=${this.selectedStatus}&course_id=${
                  this.selectedCourse.id
                }&group_id=${this.selectedGroup ? allSelectedGroups : ''}`
              )
              .then((response) => {
                if (!response.data.data.data.length) {
                  swal('لا يوجد حضور')
                }
                this.dateAttendance = response.data.data.data
                // console.log(this.dateAttendance, 'date student')
              })
          }
          break
        case 'lectures':
          if (!this.selectedCourse) {
            swal('يجب الدورة')
          } else {
            let allSelectedGroups = []
            $.each(this.selectedGroup, function (key, value) {
              allSelectedGroups.push(value.id)
            })
            allSelectedGroups = JSON.stringify(allSelectedGroups).replace(
              /\s/g,
              ''
            )
            this.$http
              .get(
                `${this.$hostUrl}report/lectures?student_id=${
                  this.selectedStudent.id ?? ''
                }&course_id=${this.selectedCourse.id}&group_id=${
                  this.selectedGroup ? allSelectedGroups : ''
                }`
              )
              .then((response) => {
                this.lecturesAttendance = response.data.students
                this.allLectures = this.lecturesAttendance[0].lectures
              })
          }
      }
    },
    getGrade (id) {
      let number = ''
      switch (id) {
        case 'ab1fb157-188b-45ea-b046-302ff1221253':
          number = 1
          break
        case 'd96e432f-9a95-4bdb-9a87-bceaf981fe0e':
          number = 2
          break
        case 'f4a80ded-3d68-4795-84b0-5692daa74b78':
          number = 3
          break
        case '5de6043a-bc65-437b-bb57-0cbf69573dd7':
          number = 4
          break
      }
      return number
    },
    printReport (report) {
      // $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }
      if (report === 'student') {
        setTimeout(this.$htmlToPaper('print-report', options), 10000)
        $('.card-footer').show()
      }
      if (report === 'date') {
        $('.call-th,.call-td,.card-footer').hide()
        setTimeout(this.$htmlToPaper('print-date', options), 10000)
        $('.card-footer').show()
        $('.call-th,.call-td,.card-footer').show()
      }
      if (report === 'lectures') {
        setTimeout(this.$htmlToPaper('print-lectures', options), 10000)
        $('.card-footer').show()
      }
    },
    downloadFile (report) {
      if (report === 'student') {
        $.each(this.lectures, (key, val) => {
          this.lectureFile.push({
            الطالب: this.selectedStudent.name,
            الدورة: this.selectedCourse.name,
            الفرقة: this.grade,
            رقم_المحاضرة: val.lecture_number,
            الحضور: val.attendance.attended === 1 ? 'o' : '-',
            الغياب: val.attendance.attended === 0 ? 'o' : '-'
          })
        })
        const data = this.lectureFile
        const fileName = ` تقرير حضور الطالب - ${today}`
        const exportType = exportFromJSON.types.xls
        if (data) exportFromJSON({ data, fileName, exportType })
      }
    }
  }
}
</script>
<style>
#collection-date {
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
  border-bottom: 1px solid #f0f2f5 !important;
}
.width {
  width: 100%;
}
.online {
  border: 1px solid #ddd;
}
.times {
  margin-top: 10px !important;
}
.times h5 {
  font-size: 16px !important;
}
.times .red {
  position: relative !important;
}
.filter-text {
  font-weight: 700;
  color: #dfad07;
  font-size: 15px !important;
}
.tab2 {
  margin-top: 27px !important;
  margin-bottom: 41px !important;
  border-bottom: 1px solid #ddd;
  padding-bottom: 26px !important;
}
.action-btn {
  padding: 0.625rem 0.5rem !important;
}
</style>
