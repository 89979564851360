<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="container-fluid py-4 add-student grade-detail attend-report">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">
                    اشعارات الفلاتر
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="mb-3 row  tab2">
                <div class="mb-3 col-5 ">
              <label>نوع الإشعار</label>
              <multiselect
                class="required form-control"
                id="student"
                v-model="selectedType"
                :options="filteredTypes"
                @select="clearData"
                :searchable="true"
                :close-on-select="true"
                placeholder=" اختر النوع"
                 open-direction="top"
                label="name"
                track-by="name"
                required="required"
                :internal-search="true"
              >
                >
                <template v-slot:noOptions>
                  <span> فارغة </span>
                </template>
                <template v-slot:noResult>
                  <span>لا يوجد نتيجة </span>
                </template>
              </multiselect>
            </div>
            </div>
            <div v-if="selectedType" class="tab2">
           <NotificationsTabs ref="NotificationsTabs" :notifiType='selectedType.key' @data-sent="handleData" @get-students ="getStudents"/>
           </div>
            <!-- sent notification -->
            <div style="padding-top:0px" class="card-body px-0 pb-2" v-if="students.length">
              <h5 style="font-size:16px">  عدد الطلاب : <span class="green">{{students?.length}}  </span>
                      </h5>
              <div class="row">
                   <form @submit='createNotifications' role="form" class="text-start mt-3">
                       <div class="row">
                  <div class="mb-3 col-lg-6" >
                  <label>
                     عنوان الرسالة  <span class="red">*</span><span color="danger" class="error">{{
                      errors.title ? errors.title[0] : ''
                    }}</span></label>
                  <vmd-input
                    id="title"
                    type="text"
                    placeholder='عنوان الرسالة'
                    v-model='message.title'
                    required
                  />
                </div>
                    <div class="mb-3 col-lg-6" >
                  <label>
                      العنوان الفرعي  <span class="red">*</span><span color="danger" class="error">{{
                      errors.title ? errors.title[0] : ''
                    }}</span></label>
                  <vmd-input
                    id="title"
                    type="text"
                    placeholder='العنوان الفرعي '
                    v-model='message.subtitle'
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6" >
                   <label>
                     الرسالة <span color="danger" class="error">{{
                      errors.content ? errors.content[0] : ''
                    }}</span></label>
                  <div class="">
                  <quill-editor theme="snow" required :content ="message.content"
                  v-model:content="message.content" contentType ='html'></quill-editor>
                  </div>
                  </div>
                </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                  type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > مسح</vmd-button
                  >
                </div>
                       </div>
              </form>
              </div>
              </div>
            <!-- students table -->
            <div v-if="students.length" id="print-report" style="margin-top: 40px; direction: rtl">
              <div
                class="row align-center tab2"
                style="margin-top: 40px; direction: rtl"
              >
                <div class="text-center col-lg-12" style="direction: rtl">
                  <table class="b-block table" style="direction: rtl">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>رقم الطالب</th>
                        <th>الاسم</th>
                        <th>الهاتف</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(student,index) in students" :key="student.id">
                        <td>{{ index+1 }}</td>
                        <td>{{ student.number }}</td>
                        <td>{{ student.full_name }}</td>
                        <td>{{ student.mobile }}</td>
                      </tr>
                      <tr></tr>
                      <tr style="background: #dad6d669; font-weight: bold">
                        <td colspan="2">عدد الطلاب</td>
                        <td>{{ students.length}}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import NotificationsTabs from '@/components/NotificationsTabs.vue'
import VmdInput from '@/components/VmdInput.vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
export default {
  name: 'SystemNotifications',
  components: {
    VmdButton,
    VmdInput,
    NotificationsTabs,
    QuillEditor
  },
  data () {
    return {
      types: [{
        key: 'students', name: 'الطلاب'
      },
      {
        key: 'course', name: 'طلاب دورة واحدة'
      },
      {
        key: 'lecture', name: 'الحضور - الغياب '
      },
      {
        key: 'subject', name: 'عدم حضور مادة'
      }
      ],
      selectedType: '',
      selectedData: {},
      students: [],
      message: {},
      centerType: '',
      errors: []
    }
  },
  created () {
    this.centerType = localStorage.centerType
  },
  computed: {
    filteredTypes () {
      if (this.centerType === 'secondary') {
        return this.types.slice(0, 2)
      } else {
        return this.types
      }
    }
  },
  methods: {
    clearData () {
      this.students = []
      if (this.$refs.NotificationsTabs) {
        this.$refs.NotificationsTabs.clearFilters()
      }
      this.selectedData = {}
    },
    handleData (data) {
      this.selectedData = data
    },
    getStudents () {
      if (this.selectedType.key === 'students') {
        let url = `${this.$hostUrl}notification/students?grade_id=${JSON.stringify(this.selectedData.selectedGrade)}&type=students`

        if (this.centerType !== 'secondary') {
          url += `&group_id=${JSON.stringify(this.selectedData.selectedGroup)}`
        }

        this.$http.get(url)
          .then((response) => {
            this.students = response.data.data.students.original.data
          })
      }
      if (this.selectedType.key === 'course') {
        this.$http.get(`${this.$hostUrl}notification/students?course_id=${this.selectedData.course}&type=course`).then((response) => {
          this.students = response.data.data.students.original.data
        })
      }
      if (this.selectedType.key === 'lecture') {
        this.$http.get(`${this.$hostUrl}notification/students?lecture_id=${this.selectedData.lecture}&type=lecture&attended=${this.selectedData.attended}`).then((response) => {
          this.students = response.data.data.students.original.data
        })
      }
      if (this.selectedType.key === 'subject') {
        this.$http.get(`${this.$hostUrl}notification/students?subject_id=${this.selectedData.subject}&grade_id=${this.selectedData.grade}&type=subject&group_id=${JSON.stringify(this.selectedData.selectedGroup) ?? null}`).then((response) => {
          this.students = response.data.data.students.original.data
        })
      }
    },
    async createNotifications (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('title', this.message.title)
      formData.append('subtitle', this.message.subtitle)
      formData.append('content', this.message.content)
      if (this.selectedType.key === 'students') {
        formData.append('type', 'students')
        formData.append('group_id', JSON.stringify(this.selectedData.selectedGroup))
        formData.append('grade_id', JSON.stringify(this.selectedData.selectedGrade))
      }
      if (this.selectedType.key === 'course') {
        formData.append('type', 'course')
        formData.append('course_id', this.selectedData.course)
      }
      if (this.selectedType.key === 'lecture') {
        formData.append('type', 'lecture')
        formData.append('lecture_id', this.selectedData.lecture)
        formData.append('attended', this.selectedData.attended)
      }
      if (this.selectedType.key === 'subject') {
        formData.append('type', 'subject')
        formData.append('subject_id', this.selectedData.subject)
        formData.append('group_id', JSON.stringify(this.selectedData.selectedGroup))
        formData.append('grade_id', this.selectedData.grade)
      }

      this.errors = await this.postRequest(formData, this.$hostUrl + 'notifications', '')
      this.selectedData.selectedGrade = ''
      this.selectedData.selectedGroup = ''
      this.selectedType = ''
      this.message = {}
      this.students = {}
    }
    // getSubjects ($event) {
    //   this.selectedSubject = ''
    //   this.$http
    //     .get(`${this.$hostUrl}grade/${$event.id}/subjects?semester=1`)
    //     .then((response) => {
    //       this.subjects = response.data.data
    //     })
    // },
    // getCourses ($event) {
    //   this.selectedCourse = ''
    //   this.$http
    //     .get(`${this.$hostUrl}courses?subject_id=${$event.id}`)
    //     .then((response) => {
    //       this.courses = response.data.data.filter((course) => {
    //         return course.type === 0
    //       })
    //       if (!this.courses.length) {
    //         swal(' لا توجد دورات اوفلاين تابعه للمادة')
    //       }
    //     })
    // },
    // getLectures ($event) {
    //   this.$http
    //     .get(`${this.$hostUrl}course/${$event.id}/lectures`)
    //     .then((response) => {
    //       if (!response.data.data.length) {
    //         swal('لا توجد محاضرات')
    //       }
    //       const lectureList = []
    //       $.each(response.data.data, (key, val) => {
    //         lectureList.push({
    //           id: val.id,
    //           name: val.lecture_number + ' ' + '-' + ' ' + val.start
    //         })
    //       })
    //       this.lecturesList = lectureList
    //     })
    // },
    // updateData () {
    //   let allSelectedGroups = []
    //   $.each(this.selectedGroup, function (key, value) {
    //     allSelectedGroups.push(value.id)
    //   })
    //   allSelectedGroups = JSON.stringify(allSelectedGroups).replace(/\s/g, '')
    //   this.$http
    //     .get(
    //       `${this.$hostUrl}attendance-report?from=${this.fromDate}&to=${
    //         this.toDate
    //       }&attend=${this.selectedStatus}&course_id=${
    //         this.selectedCourse.id
    //       }&group_id=${this.selectedGroup ? allSelectedGroups : ''}`
    //     )
    //     .then((response) => {
    //       this.dateAttendance = response.data.data.data
    //     })
    // },
  }
}
</script>
<style>
#collection-date {
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
  border-bottom: 1px solid #f0f2f5 !important;
}
.width {
  width: 100%;
}
.online {
  border: 1px solid #ddd;
}
.times {
  margin-top: 10px !important;
}
.times h5 {
  font-size: 16px !important;
}
.times .red {
  position: relative !important;
}
.filter-text {
  font-weight: 700;
  color: #dfad07;
  font-size: 15px !important;
}
.tab2 {
  margin-top: 27px !important;
  margin-bottom: 41px !important;
  border-bottom: 1px solid #ddd;
  padding-bottom: 26px !important;
}
.action-btn {
  padding: 0.625rem 0.5rem !important;
}
</style>
