import i18n from '../i18n'
import AllStudents from '../views/Students/Index.vue'
import AddStudent from '../views/Students/Create.vue'
import EditStudent from '../views/Students/Edit.vue'
import ShowStudent from '../views/Students/Show.vue'
import CourseRegister from '../views/Students/CourseRegister.vue'
import ChangeGrade from '../views/Students/ChangeGrade.vue'
// students by faculty
import AllStudentsFaculty from '../views/Students/ByFaculty/Index.vue'
import AddStudentFaculty from '../views/Students/ByFaculty/Create.vue'
import EditStudentFaculty from '../views/Students/ByFaculty/Edit.vue'
import ShowStudentFaculty from '../views/Students/ByFaculty/Show.vue'
import CourseRegisterFaculty from '../views/Students/ByFaculty/CourseRegister.vue'
import ChangeGradeFaculty from '../views/Students/ByFaculty/ChangeGrade.vue'
// students by grade
import AllStudentsGrade from '../views/Students/ByGrade/Index.vue'
import AddStudentGrade from '../views/Students/ByGrade/Create.vue'
import EditStudentGrade from '../views/Students/ByGrade/Edit.vue'
import ShowStudentGrade from '../views/Students/ByGrade/Show.vue'
import CourseRegisterGrade from '../views/Students/ByGrade/CourseRegister.vue'
// summer courses
import Clients from '../views/Students/SummerCourses/Index.vue'
import CreateClient from '../views/Students/SummerCourses/Create.vue'
import EditClient from '../views/Students/SummerCourses/Edit.vue'
import ShowClient from '../views/Students/SummerCourses/Show.vue'
// import SummerCourseRegister from '../views/Students/ByGrade/SummerCourses.vue'
// teachers
import Teachers from '../views/Teachers/Index.vue'
import AddTeacher from '../views/Teachers/Create.vue'
import ShowTeacher from '../views/Teachers/Show.vue'
import EditTeacher from '../views/Teachers/Edit.vue'
// teachers by faculty
import FacultyTeachers from '../views/Teachers/ByFaculty/Index.vue'
import FacultyAddTeacher from '../views/Teachers/ByFaculty/Create.vue'
import FacultyShowTeacher from '../views/Teachers/ByFaculty/Show.vue'
import FacultyEditTeacher from '../views/Teachers/ByFaculty/Edit.vue'
// teachers by grade
import GradeTeachers from '../views/Teachers/ByGrade/Index.vue'
import GradeAddTeacher from '../views/Teachers/ByGrade/Create.vue'
import GradeShowTeacher from '../views/Teachers/ByGrade/Show.vue'
import GradeEditTeacher from '../views/Teachers/ByGrade/Edit.vue'
// summer courses
import SummerTeachers from '../views/Teachers/SummerCourses/Index.vue'
import AddSummerTeacher from '../views/Teachers/SummerCourses/Create.vue'
import ShowSummerTeacher from '../views/Teachers/SummerCourses/Show.vue'
import EditSummerTeacher from '../views/Teachers/SummerCourses/Edit.vue'
// subject
import Subjects from '../views/Subjects/Index.vue'
import AddSubject from '../views/Subjects/Create.vue'
import ShowSubject from '../views/Subjects/Show.vue'
import EditSubject from '../views/Subjects/Edit.vue'
import Reservation from '../views/Subjects/Reservation.vue'
// subjects by faculty
import FacultySubjects from '../views/Subjects/ByFaculty/Index.vue'
import FacultyAddSubject from '../views/Subjects/ByFaculty/Create.vue'
import FacultyShowSubject from '../views/Subjects/ByFaculty/Show.vue'
import FacultyEditSubject from '../views/Subjects/ByFaculty/Edit.vue'
import FacultyReservation from '../views/Subjects/ByFaculty/Reservation.vue'
// subjects by grade
import GradeSubjects from '../views/Subjects/ByGrade/Index.vue'
import GradeAddSubject from '../views/Subjects/ByGrade/Create.vue'
import GradeShowSubject from '../views/Subjects/ByGrade/Show.vue'
import GradeEditSubject from '../views/Subjects/ByGrade/Edit.vue'
import GradeReservation from '../views/Subjects/ByGrade/Reservation.vue'
// courses
import Courses from '../views/Courses/Index.vue'
import AddCourse from '../views/Courses/Create.vue'
import ShowCourse from '../views/Courses/Show.vue'
import ShowOnlineCourse from '../views/Courses/OnlineCourse.vue'
import EditCourse from '../views/Courses/Edit.vue'
import CreateLecture from '../views/Courses/CreateLecture.vue'
import CreateVideo from '../views/Courses/CreateVideo.vue'
import Test from '../views/Courses/TestVideo.vue'
import CreatePromoCode from '../views/Courses/CreatePromoCode.vue'
// courses by faculty
import FacultyCourses from '../views/Courses/ByFaculty/Index.vue'
import FacultyAddCourse from '../views/Courses/ByFaculty/Create.vue'
import FacultyShowCourse from '../views/Courses/ByFaculty/Show.vue'
import FacultyShowOnlineCourse from '../views/Courses/ByFaculty/OnlineCourse.vue'
import FacultyEditCourse from '../views/Courses/ByFaculty/Edit.vue'
// courses by grade
import GradeCourses from '../views/Courses/ByGrade/Index.vue'
import GradeAddCourse from '../views/Courses/ByGrade/Create.vue'
import GradeShowCourse from '../views/Courses/ByGrade/Show.vue'
import GradeShowOnlineCourse from '../views/Courses/ByGrade/OnlineCourse.vue'
import GradeEditCourse from '../views/Courses/ByGrade/Edit.vue'
import GradeVideo from '../views/Courses/ByGrade/CreateVideo.vue'

// summer courses
import SummerCourses from '../views/Courses/SummerCourses/Index.vue'
import CreateSummerCourse from '../views/Courses/SummerCourses/Create.vue'
import EditSummerCourse from '../views/Courses/SummerCourses/Edit.vue'
import ShowSummerCourse from '../views/Courses/SummerCourses/Show.vue'
// receipts
import Receipts from '../views/Receipts/Index.vue'
import AddReceipt from '../views/Receipts/Create.vue'
import AddRevesionReceipt from '../views/Receipts/CreateRevesion.vue'
import AddOfferReceipt from '../views/Receipts/CreateOffer.vue'
import ShowReceipt from '../views/Receipts/Show.vue'

const dynamicRoutes = {
  // seven eleven routes
  SubjectRoutes: [
    {
      path: '',
      name: 'المواد',
      component: Subjects,
      meta: {
        title: ' المواد'
      }
    },
    {
      path: 'create',
      name: 'إضافة مادة',
      component: AddSubject,
      meta: {
        title: 'إضافة مادة'
      }
    },
    {
      path: ':id/edit',
      name: 'تعديل مادة',
      component: EditSubject,
      meta: {
        title: 'تعديل المادة'
      }
    },
    {
      path: ':id/show',
      name: 'عرض  المادة',
      component: ShowSubject,
      meta: {
        title: ' عرض المادة'
      }
    },
    {
      path: ':id/reserve',
      name: 'حجز المادة',
      component: Reservation,
      meta: {
        title: ' حجز المادة'
      }
    }
  ],
  CourseRoutes: [
    {
      path: '',
      name: 'الدورات التدريبية ',
      component: Courses,
      meta: {
        title: ' الدورات التدريبية'
      }
    },
    {
      path: 'create',
      name: '    إضافة دورة ',
      component: AddCourse,
      meta: {
        title: 'إضافة دورة'
      }
    },
    {
      path: ':id/show',
      name: ' تفاصيل الدورة ',
      component: ShowCourse,
      meta: {
        title: ' تفاصيل الدورة'
      }
    },
    {
      path: ':id/show-online-course',
      name: ' تفاصيل الدورة- أونلاين ',
      component: ShowOnlineCourse,
      meta: {
        title: ' تفاصيل الدورة -أونلاين'
      }
    },
    {
      path: ':id/edit',
      name: ' تعديل الدورة ',
      component: EditCourse,
      meta: {
        title: ' تعديل الدورة'
      }
    },
    {
      path: ':id/add-lecture',
      name: '  إضافة محاضرة ',
      component: CreateLecture,
      meta: {
        title: 'إضافة محاضرة'
      }
    },
    {
      path: ':id/add-video',
      name: '  إضافة محاضرة أونلاين ',
      component: CreateVideo,
      meta: {
        title: 'إضافة محاضرة أونلاين'
      }
    },

    {
      path: ':id/test',
      name: '   test ',
      component: Test
    },
    {
      path: ':id/promo-code',
      name: 'إضافة بروموكود',
      component: CreatePromoCode,
      meta: {
        title: 'إضافة بروموكود'
      }
    }
  ],
  TeacherRoutes: [
    {
      path: '',
      name: 'كل المدرسين ',
      component: Teachers,
      meta: {
        title: ' المدرسين'
      }
    },
    {
      path: 'create',
      name: '   إضافة مدرس ',
      component: AddTeacher,
      meta: {
        title: 'إضافة مدرس'
      }
    },
    {
      path: ':id/show',
      name: ' عرض بيانات المدرس ',
      component: ShowTeacher,
      meta: {
        title: ' عرض المدرس'
      }

    },
    {
      path: ':id/edit',
      name: '   تعديل المدرس ',
      component: EditTeacher,
      meta: {
        title: ' تعديل المدرس'
      }

    }

  ],
  StudentsRoutes: [
    {
      path: '',
      name: 'الطلاب',
      component: AllStudents,
      meta: {
        title: ' الطلاب'
      }
    },
    {
      path: ':id/edit',
      name: ' تعديل بيانات الطالب',
      component: EditStudent,
      meta: {
        title: ' تعديل بيانات الطالب'
      }
    },
    {
      path: 'create',
      name: 'إضافة طالب',
      component: AddStudent,
      meta: {
        title: 'إضافة طالب'
      }
    },
    {
      path: ':id/show',
      name: ' عرض بيانات الطالب',
      component: ShowStudent,
      meta: {
        title: ' عرض بيانات الطالب'
      }
    },
    {
      path: 'change',
      name: ' تغيير الفرقة',
      component: ChangeGrade
    },
    {
      path: ':id/course-register',
      name: ' التسجيل في الدورة',
      component: CourseRegister,
      meta: {
        title: ' التسجيل في الدورة'
      }
    }
  ],
  OffersRoute: [
    // offers
    {
      path: '',
      name: ' جميع العروض',
      component: () => import('../views/Offers/Index.vue'),
      meta: {
        title: ' جميع العروض'
      }
    },
    {
      path: 'create',
      name: '   إضافة عرض ',
      component: () => import('../views/Offers/Create.vue'),
      meta: {
        title: 'إضافة عرض'
      }
    },
    {
      path: ':id/edit',
      name: '   تعديل العرض ',
      component: () => import('../views/Offers/Edit.vue'),
      meta: {
        title: ' تعديل العرض'
      }
    },
    {
      path: ':id/enroll',
      name: 'التسجيل في  العرض',
      component: () => import('../views/Offers/offerEnroll.vue'),
      meta: {
        title: ' التسجيل في العرض'
      }
    }
  ],
  ReceiptsRoute: [
    {
      path: '',
      name: 'الإيصالات',
      component: Receipts,
      meta: {
        title: ' الإيصالات'
      }
    },
    {
      path: 'create',
      name: ' إضافة إيصال ',
      component: AddReceipt,
      meta: {
        title: 'إضافة إيصال'
      }
    },
    {
      path: 'create-revesion',
      name: ' إيصال مراجعة  ',
      component: AddRevesionReceipt,
      meta: {
        title: ' إيصال مراجعة'
      }
    },
    {
      path: 'create-offer',
      name: ' إيصال عرض  ',
      component: AddOfferReceipt,
      meta: {
        title: ' إيصال عرض'
      }
    },
    {
      path: ':id/show',
      name: ' بيانات الإيصال  ',
      component: ShowReceipt,
      meta: {
        title: ' بيانات الإيصال'
      }
    }
  ],
  PaiedRemainingReport:
    {
      path: 'paid-remaining',
      name: 'تقارير  المسدد والمتبقي',
      component: () => import('@/views/Reports/PaiedRemaining.vue'),
      meta: {
        title: ' تقارير المسدد والمتبقي'
      }
    },
  ResrvationReport:
    {
      path: 'subjects-reservation',
      name: 'تقارير الحجز في المواد ',
      component: () => import('@/views/Reports/SubjectsReservation.vue'),
      meta: {
        title: ' تقارير الحجز في المواد'
      }
    }

}
// vision routes
if (localStorage.centerType === 'university') {
  dynamicRoutes.SubjectRoutes = [
    {
      path: '',
      name: 'المواد ',
      component: FacultySubjects
    },
    {
      path: 'create',
      name: '   إضافة مادة ',
      component: FacultyAddSubject
    },
    {
      path: ':id/edit',
      name: '   تعديل مادة ',
      component: FacultyEditSubject
    },
    {
      path: ':id/show',
      name: 'عرض  المادة ',
      component: FacultyShowSubject
    },
    {
      path: ':id/reserve',
      name: 'حجز المادة ',
      component: FacultyReservation
    }
  ]
  dynamicRoutes.TeacherRoutes = [
    {
      path: '',
      name: 'كل المدرسين ',
      component: FacultyTeachers
    },
    {
      path: 'create',
      name: '   إضافة مدرس ',
      component: FacultyAddTeacher
    },
    {
      path: ':id/show',
      name: ' عرض بيانات المدرس ',
      component: FacultyShowTeacher

    },
    {
      path: ':id/edit',
      name: '   تعديل المدرس ',
      component: FacultyEditTeacher

    }

  ]
  dynamicRoutes.StudentsRoutes = [
    {
      path: '',
      name: 'الطلاب',
      component: AllStudentsFaculty
    },
    {
      path: ':id/edit',
      name: ' تعديل بيانات الطالب',
      component: EditStudentFaculty
    },
    {
      path: 'create',
      name: 'إضافة طالب',
      component: AddStudentFaculty
    },
    {
      path: ':id/show',
      name: ' عرض بيانات الطالب',
      component: ShowStudentFaculty
    },
    {
      path: 'change',
      name: ' تغيير الفرقة',
      component: ChangeGradeFaculty
    },
    {
      path: ':id/course-register',
      name: ' التسجيل في الدورة',
      component: CourseRegisterFaculty
    }
  ]
  dynamicRoutes.CourseRoutes.forEach((ele) => {
    if (ele.path === ':id/show-online-course') {
      ele.component = FacultyShowOnlineCourse
    }
    if (ele.path === 'create') {
      ele.component = FacultyAddCourse
    }
    if (ele.path === ':id/edit') {
      ele.component = FacultyEditCourse
    }
    if (ele.path === ':id/show') {
      ele.component = FacultyShowCourse
    }
    if (ele.path === '') {
      ele.component = FacultyCourses
    }
  })
}
// waqad routes
if (localStorage.centerType === 'one-instructor') {
  dynamicRoutes.SubjectRoutes = [
    {
      path: '',
      name: 'المواد ',
      component: GradeSubjects,
      meta: {
        title: 'المواد',
        page: 'المواد'
      }
    },
    {
      path: 'create',
      name: '   إضافة مادة ',
      component: GradeAddSubject,
      meta: {
        title: 'إضافة مادة'
      }
    },
    {
      path: ':id/edit',
      name: '   تعديل مادة ',
      component: GradeEditSubject,
      meta: {
        title: 'تعديل مادة'
      }
    },
    {
      path: ':id/show',
      name: 'عرض  المادة ',
      component: GradeShowSubject,
      meta: {
        title: 'عرض مادة'
      }
    },
    {
      path: ':id/reserve',
      name: 'حجز المادة ',
      component: GradeReservation,
      meta: {
        title: 'حجز مادة'
      }
    }
  ]
  dynamicRoutes.TeacherRoutes = [
    {
      path: '',
      name: 'كل المدرسين ',
      component: GradeTeachers,
      meta: {
        title: 'المدرسين '
      }
    },
    {
      path: 'create',
      name: '   إضافة مدرس ',
      component: GradeAddTeacher,
      meta: {
        title: 'إضافة مدرس'
      }
    },
    {
      path: ':id/show',
      name: ' عرض بيانات المدرس ',
      component: GradeShowTeacher,
      meta: {
        title: ' عرض المدرس'
      }

    },
    {
      path: ':id/edit',
      name: '   تعديل المدرس ',
      component: GradeEditTeacher,
      meta: {
        title: ' تعديل المدرس'
      }

    }

  ]
  dynamicRoutes.StudentsRoutes = [
    {
      path: '',
      name: 'الطلاب',
      component: AllStudentsGrade,
      meta: {
        title: ' الطلاب'
      }
    },
    {
      path: ':id/edit',
      name: ' تعديل بيانات الطالب',
      component: EditStudentGrade,
      meta: {
        title: ' تعديل الطالب'
      }
    },
    {
      path: 'create',
      name: 'إضافة طالب',
      component: AddStudentGrade,
      meta: {
        title: 'إضافة طالب'
      }
    },
    {
      path: ':id/show',
      name: ' عرض بيانات الطالب',
      component: ShowStudentGrade,
      meta: {
        title: ' عرض الطالب'
      }
    },
    {
      path: ':id/course-register',
      name: ' التسجيل في الدورة',
      component: CourseRegisterGrade,
      meta: {
        title: ' التسجيل في الدورة'
      }
    }
  ]
  dynamicRoutes.CourseRoutes.forEach((ele) => {
    if (ele.path === ':id/show-online-course') {
      ele.component = GradeShowOnlineCourse
    }
    if (ele.path === 'create') {
      ele.component = GradeAddCourse
    }
    if (ele.path === ':id/edit') {
      ele.component = GradeEditCourse
    }
    if (ele.path === ':id/show') {
      ele.component = GradeShowCourse
    }
    if (ele.path === '') {
      ele.component = GradeCourses
    }
    if (ele.path === ':id/add-video') {
      ele.component = GradeVideo
    }
  })
}
// takizy routes
if (localStorage.centerType === 'english -school') {
  dynamicRoutes.StudentsRoutes = [
    {
      path: '',
      name: i18n.global.t('message.customers'),
      component: Clients,
      meta: {
        title: i18n.global.t('message.customers'),
        page: 'العملاء'
      }
    },
    {
      path: 'create',
      name: i18n.global.t('message.createCustomer'),
      component: CreateClient,
      meta: {
        title: i18n.global.t('message.createCustomer')
      }
    },
    {
      path: ':id/edit',
      name: i18n.global.t('message.updateCustomer'),
      component: EditClient,
      meta: {
        title: i18n.global.t('message.updateCustomer')
      }
    },
    {
      path: ':id/show',
      name: i18n.global.t('message.showCustomer'),
      component: ShowClient,
      meta: {
        title: i18n.global.t('message.showCustomer')
      }
    },
    {
      path: ':id/course-register',
      name: ' التسجيل في الدورة',
      component: () => import('../views/Students/SummerCourses/CourseRegister.vue'),
      meta: {
        title: ' التسجيل في الدورة'
      }
    }
  ]
  dynamicRoutes.TeacherRoutes = [
    {
      path: '',
      name: i18n.global.t('message.teachers'),
      component: SummerTeachers,
      meta: {
        title: i18n.global.t('message.teachers')
      }
    },
    {
      path: 'create',
      name: i18n.global.t('message.createTeacher'),
      component: AddSummerTeacher,
      meta: {
        title: i18n.global.t('message.createTeacher')
      }
    },
    {
      path: ':id/show',
      name: i18n.global.t('message.showTeacher'),
      component: ShowSummerTeacher,
      meta: {
        title: i18n.global.t('message.showTeacher')
      }

    },
    {
      path: ':id/edit',
      name: i18n.global.t('message.updateTeacher'),
      component: EditSummerTeacher,
      meta: {
        title: i18n.global.t('message.updateTeacher')
      }

    }

  ]
  dynamicRoutes.SubjectRoutes = [
    {
      path: '',
      name: i18n.global.t('message.subjects'),
      component: () => import('../views/Subjects/SummerCourses/Index.vue'),
      meta: {
        title: i18n.global.t('message.subjects')
      }
    },
    {
      path: 'create',
      name: i18n.global.t('message.createSubject'),
      component: () => import('../views/Subjects/SummerCourses/Create.vue'),
      meta: {
        title: i18n.global.t('message.createSubject')
      }
    },
    {
      path: ':id/edit',
      name: i18n.global.t('message.updateSubject'),
      component: () => import('../views/Subjects/SummerCourses/Edit.vue')
    },
    {
      path: ':id/show',
      name: i18n.global.t('message.showSubject'),
      component: () => import('../views/Subjects/SummerCourses/Show.vue')
    },
    {
      path: ':id/reserve',
      name: 'حجز المادة ',
      component: () => import('../views/Subjects/SummerCourses/Reservation.vue')
    }
  ]
  dynamicRoutes.OffersRoute = [
    // offers
    {
      path: '',
      name: ' جميع العروض',
      component: () => import('../views/Offers/SummerCourses/Index.vue'),
      meta: {
        title: ' جميع العروض'
      }
    },
    {
      path: 'create',
      name: '   إضافة عرض ',
      component: () => import('../views/Offers/SummerCourses/Create.vue'),
      meta: {
        title: 'إضافة عرض'
      }
    },
    {
      path: ':id/edit',
      name: '   تعديل العرض ',
      component: () => import('../views/Offers/SummerCourses/Edit.vue'),
      meta: {
        title: ' تعديل العرض'
      }
    },
    {
      path: ':id/enroll',
      name: 'التسجيل في  العرض',
      component: () => import('../views/Offers/SummerCourses/offerEnroll.vue'),
      meta: {
        title: ' التسجيل في العرض'
      }
    }
  ]
  dynamicRoutes.ReceiptsRoute = [
    // offers
    {
      path: '',
      name: '  الإيصالات',
      component: () => import('../views/Receipts/SummerCourses/Index.vue'),
      meta: {
        title: ' جميع الإيصالات'
      }
    },
    {
      path: 'create',
      name: ' إضافة إيصال ',
      component: () => import('../views/Receipts/SummerCourses/Create.vue'),
      meta: {
        title: 'إضافة ايصال'
      }
    },
    {
      path: ':id/edit',
      name: '   تعديل ايصال ',
      component: () => import('../views/Receipts/SummerCourses/Edit.vue'),
      meta: {
        title: ' تعديل ايصال'
      }
    },
    {
      path: ':id/show',
      name: ' عرض الإيصال',
      component: () => import('../views/Receipts/SummerCourses/Show.vue'),
      meta: {
        title: '  عرض الإيصال'
      }
    },
    {
      path: 'create-revesion',
      name: ' إيصال مراجعة  ',
      component: AddRevesionReceipt,
      meta: {
        title: ' إيصال مراجعة'
      }
    },
    {
      path: 'create-offer',
      name: ' إيصال عرض  ',
      component: AddOfferReceipt,
      meta: {
        title: ' إيصال عرض'
      }
    }
  ]
  dynamicRoutes.PaiedRemainingReport =
  {
    path: 'paid-remaining',
    name: 'تقارير  المسدد والمتبقي',
    component: () => import('@/views/Reports/SummerCourses/PaiedRemaining.vue'),
    meta: {
      title: ' تقارير المسدد والمتبقي'
    }
  }
  dynamicRoutes.ResrvationReport =
  {
    path: 'subjects-reservation',
    name: 'تقارير الحجز في المواد ',
    component: () => import('@/views/Reports/SummerCourses/SubjectsReservation.vue'),
    meta: {
      title: ' تقارير الحجز في المواد'
    }
  }
  dynamicRoutes.CourseRoutes.forEach((ele) => {
    if (ele.path === 'create') {
      ele.component = CreateSummerCourse
    }
    if (ele.path === ':id/edit') {
      ele.component = EditSummerCourse
    }
    if (ele.path === ':id/show') {
      ele.component = ShowSummerCourse
    }
    if (ele.path === '') {
      ele.component = SummerCourses
    }
  })
}
// beit fan routes
if (localStorage.centerType === 'secondary') {
  dynamicRoutes.StudentsRoutes = [
    {
      path: '',
      name: 'الطلاب ',
      component: () => import('../views/Students/SecondaryCourses/Index.vue'),
      meta: {
        title: 'الطلاب',
        page: 'الطلاب'
      }
    },
    {
      path: 'create',
      name: '   إضافة طالب ',
      component: () => import('../views/Students/SecondaryCourses/Create.vue'),
      meta: {
        title: 'إضافة طالب'
      }
    },
    {
      path: ':id/edit',
      name: '   تعديل طالب ',
      component: () => import('../views/Students/SecondaryCourses/Edit.vue'),
      meta: {
        title: 'تعديل طالب'
      }
    },
    {
      path: ':id/show',
      name: 'عرض  بيانات الطالب ',
      component: () => import('../views/Students/SecondaryCourses/Show.vue'),
      meta: {
        title: 'عرض العميل '
      }
    },
    {
      path: ':id/course-register',
      name: ' التسجيل في الدورة',
      component: () => import('../views/Students/SummerCourses/CourseRegister.vue'),
      meta: {
        title: ' التسجيل في الدورة'
      }
    }
  ]
  dynamicRoutes.TeacherRoutes = [
    {
      path: '',
      name: 'كل المدرسين ',
      component: () => import('../views/Teachers/SecondaryCourses/Index.vue'),
      meta: {
        title: ' المدرسين'
      }
    },
    {
      path: 'create',
      name: '   إضافة مدرس ',
      component: () => import('../views/Teachers/SecondaryCourses/Create.vue'),
      meta: {
        title: 'إضافة  مدرس'
      }
    },
    {
      path: ':id/show',
      name: ' عرض بيانات المدرس ',
      component: () => import('../views/Teachers/SecondaryCourses/Show.vue'),
      meta: {
        title: 'عرض   المدرس'
      }

    },
    {
      path: ':id/edit',
      name: '   تعديل المدرس ',
      component: () => import('../views/Teachers/SecondaryCourses/Edit.vue'),
      meta: {
        title: 'تعديل  المدرس'
      }

    }

  ]
  dynamicRoutes.SubjectRoutes = [
    {
      path: '',
      name: 'المواد ',
      component: () => import('../views/Subjects/SecondaryCourses/Index.vue'),
      meta: {
        title: ' المواد'
      }
    },
    {
      path: 'create',
      name: '   إضافة مادة ',
      component: () => import('../views/Subjects/SecondaryCourses/Create.vue'),
      meta: {
        title: '  إضافة مادة'
      }
    },
    {
      path: ':id/edit',
      name: '   تعديل مادة ',
      component: () => import('../views/Subjects/SecondaryCourses/Edit.vue')
    },
    {
      path: ':id/show',
      name: 'عرض  المادة ',
      component: () => import('../views/Subjects/SecondaryCourses/Show.vue'),
      meta: {
        title: 'تعديل  المادة'
      }
    },
    {
      path: ':id/reserve',
      name: 'حجز المادة ',
      component: () => import('../views/Subjects/SecondaryCourses/Reservation.vue'),
      meta: {
        title: ' حجز المادة'
      }
    }
  ]
  dynamicRoutes.CourseRoutes = [
    {
      path: '',
      name: 'الدورات ',
      component: () => import('../views/Courses/SecondaryCourses/Index.vue'),
      meta: {
        title: ' الدورات'
      }
    },
    {
      path: 'create',
      name: '   إضافة دورة ',
      component: () => import('../views/Courses/SecondaryCourses/Create.vue'),
      meta: {
        title: '  إضافة دورة'
      }
    },
    {
      path: ':id/edit',
      name: '   تعديل الدورة ',
      component: () => import('../views/Courses/SecondaryCourses/Edit.vue'),
      meta: {
        title: '  تعديل الدورة'
      }
    },
    {
      path: ':id/show-online-course',
      name: 'عرض  الدورة ',
      component: () => import('../views/Courses/SecondaryCourses/OnlineCourse.vue'),
      meta: {
        title: '  عرض الدورة'
      }
    },
    {
      path: ':id/add-video',
      name: '  إضافة محاضرة أونلاين ',
      component: () => import('../views/Courses/SecondaryCourses/CreateVideo.vue'),
      meta: {
        title: 'إضافة محاضرة أونلاين'
      }
    },
    {
      path: ':id/video-promocode',
      name: '  فيديو بروموكود',
      component: () => import('../views/Courses/SecondaryCourses/VideoPromocode.vue'),
      meta: {
        title: 'فيديو بروموكود'
      }
    },
    {
      path: ':id/promo-code',
      name: 'إضافة بروموكود',
      component: () => import('../views/Courses/SecondaryCourses/CreatePromoCode.vue'),
      meta: {
        title: 'إضافة بروموكود'
      }
    }
  ]

  // dynamicRoutes.CourseRoutes.forEach((ele) => {
  //   if (ele.path === 'create') {
  //     ele.component =  import('../views/Teachers/SecondaryCourses/Index.vue')
  //   }
  //   if (ele.path === ':id/edit') {
  //     ele.component = EditSummerCourse
  //   }
  //   if (ele.path === ':id/show') {
  //     ele.component = ShowSummerCourse
  //   }
  //   if (ele.path === '') {
  //     ele.component = SummerCourses
  //   }
  // })
}
export default dynamicRoutes
