<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  إضافة مادة</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form @submit="createSubject" role="form" class="text-start mt-3">
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>اسم المادة<span class="red">*</span>
                  <span color='danger' class="error">{{errors.name?errors.name[0]:''}}</span>
                  </label>
                  <vmd-input
                    id="inp"
                    type="text"
                    label="الاسم"
                    v-model='subject.name'
                    :name="subject.name"
                    required
                    />
                </div>
                <div class="mb-3 col-lg-6">
                  <span color='danger' class="error"></span>
                  <label class='unrequired'> عدد الفيديوهات<span class="green">(اختياري)</span></label>
                  <vmd-input
                    id=""
                    min-length='10'
                    type="number"
                   label="  عدد الفيديوهات"
                   v-model='subject.number_of_videos'
                  />
                </div>
              </div>
              <div class='row'>
                <div class="mb-3 col-lg-6">
                  <label> الفرقة <span class="red">*</span>
                   <span color='danger' class="error">{{errors.grade_id?errors.grade_id[0]:''}}</span></label>
                 <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedGrade"
                        :options='grades'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="اختر الفرقة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='name'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                <div class="mb-3 col-lg-6">
                  <label> الترم الدراسي <span class="red">*</span>
                   <span color='danger' class="error">{{errors.semester?errors.semester[0]:''}}</span></label>
                  <div class="input-group input-group-outline null">
                    <select class="form-control" v-model="selectedSemster">
                      <option selected value="">اختر  الترم الدراسي</option>
                      <option value="1">الأول</option>
                      <option value="2">الثاني</option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label class="unrequired"> قيمة المادة<span class="green">(اختياري)</span>
                  <span color='danger' class="error">{{errors.cost?errors.cost[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="number"
                    label=" قيمة المادة"
                    min-length='10'
                    v-model='subject.cost'
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label class='unrequired'> قيمة الفيديو داخل المادة<span class="green">(اختياري)</span>
                  <span color='danger' class="error">{{errors.video_cost?errors.video_cost[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="number"
                    min-length='10'
                    label="   قيمة الفيديو"
                    v-model='subject.video_cost'
                    />
                </div>
              </div>
              <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ
                  </vmd-button>
                </div>
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    type='reset'
                    > مسح
                  </vmd-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'

export default {
  name: 'AddSubject',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      grades: [
        { id: '5de6043a-bc65-437b-bb57-0cbf69573dd7', name: 'الفرقة الرابعة' },
        { id: 'ab1fb157-188b-45ea-b046-302ff1221253', name: 'الفرقة الأولى' },
        { id: 'd96e432f-9a95-4bdb-9a87-bceaf981fe0e', name: 'الفرقة الثانية' },
        { id: 'f4a80ded-3d68-4795-84b0-5692daa74b78', name: 'الفرقة الثالثة' }],
      selectedGrade: '',
      selectedSemster: '',
      subject: {},
      errors: []
    }
  },
  created () {
  },
  methods: {
    async createSubject (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.subject.name)) {
        swal('اسم المادة يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.subject.name)
      formData.append('name_en', this.subject.name)
      if (this.subject.number_of_videos) {
        formData.append('number_of_videos', this.subject.number_of_videos)
      }
      formData.append('grade_id', this.selectedGrade.id)
      formData.append('semester', this.selectedSemster)
      if (this.subject.cost) {
        formData.append('cost', this.subject.cost)
      }
      if (this.subject.video_cost) {
        formData.append('video_cost', this.subject.video_cost)
      }

      this.errors = await this.postRequest(formData, this.$hostUrl + 'subjects', '/subjects')
    }
  }
}
</script>
