<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">تعديل بيانات الطالب</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                        <form @submit="UpdateStudent" role="form" class="text-start mt-3">
                       <div class="row">
                <div class="mb-3 col-lg-6">
                    <label>اسم الطالب<span class="red">*</span>
                    <span color='danger' class="error">{{errors.full_name?errors.full_name[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                    name="البريد الإلكتروني"
                   v-model='student.full_name'
                   placeholder='اسم الطالب'
                   :invalidFeedback="errors.full_name"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label> رقم الهاتف<span class="red">*</span>
                    <span color='danger' class="error">{{errors.mobile?errors.mobile[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model='student.mobile'
                    placeholder=' رقم الهاتف'
                    :invalidFeedback="errors.mobile"
                  />
                </div>
                </div>
                <div class='row'>
                <div class="mb-3 col-lg-6">
                      <label class='unrequired'>  البريد الإلكتروني<span class="green">(اختياري)</span> </label>
                  <vmd-input
                    id="email"
                    type="email"
                    v-model='student.email'
                    placeholder=' البريد الإلكتروني'
                    :invalidFeedback="errors.email"
                  />
                </div>
                   <div class="mb-3 col-lg-6">
                    <label class='unrequired'>رابط الفيسبوك<span class="green">(اختياري)</span>
                      <span color='danger' class="error">{{errors.facebook_url?errors.facebook_url[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                    v-model='student.facebook_url'
                    placeholder=' رابط الفيسبوك'
                  />
                </div>
                       </div>
                           <div class='row'>
                     <div class="mb-3 col-lg-6">
                    <label> الفرقة <span class="red">*</span>
                    <span color='danger' class="error">{{errors.grade_id?errors.grade_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedGrade"
                        :options='grades'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="اختر الفرقة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                <div class="mb-3 col-lg-6">
                      <label>  المجموعة <span class="red">*</span>
                      <span color='danger' class="error">{{errors.group_id?errors.group_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedGroup"
                        :options='groups'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="اختر المجموعة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                       </div>
                         <div class='row'>
                   <div class="mb-3 col-lg-6">
                      <label>  المناطق <span class="red">*</span>
                      <span color='danger' class="error">{{errors.area_id?errors.area_id[0]:''}}</span></label>
                   <multiselect
                        class='required form-control'
                        id='area'
                        v-model=" selectedArea"
                        :options='areas'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر المنظقة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                 <div class="mb-3 col-lg-6">
                      <label class="unrequired">  المصدر <span class="green">(اختيارى)</span>
                      <span color='danger' class="error">{{errors.area_id?errors.area_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='area'
                        v-model=" selectedSource"
                        :options='sources'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="اختر المصدر"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                       </div>
                       </div>
                       <div class="row" v-if="selectedSource.id === '2c481399-60f5-11ee-bd6a-f23c936035ca'">
                        <div class="mb-3 col col-6">
                      <label >  المبيعات <span class="red">*</span>
                      <span color='danger' class="error">{{errors.area_id?errors.area_id[0]:''}}</span></label>
                    <multiselect
                        class='required form-control'
                        id='area'
                        v-model=" selectedSales"
                        :options='salespeople'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder="اختر الاسم"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                      </div>
                       </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    type='reset'
                    variant="gradient"
                    color="danger"
                    > مسح</vmd-button
                  >
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import $ from 'jquery'
import swal from 'sweetalert'
export default {
  name: 'EditStudent',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      student: {},
      groups: [
        { id: '5d2a4482-5848-43c4-b30e-7d69ec2efcc5', name: '4' },
        { id: '7cdf25a8-4837-4480-98ae-2ed752287ad4', name: '2' },
        { id: '9a2f0d62-093c-4e38-a3f9-8c01cbf35739', name: '3' },
        { id: 'fff0c94b-3406-494e-9cce-516b021fbc39', name: '1' }],
      grades: [
        { id: '5de6043a-bc65-437b-bb57-0cbf69573dd7', name: 'الفرقة الرابعة' },
        { id: 'ab1fb157-188b-45ea-b046-302ff1221253', name: 'الفرقة الأولى' },
        { id: 'd96e432f-9a95-4bdb-9a87-bceaf981fe0e', name: 'الفرقة الثانية' },
        { id: 'f4a80ded-3d68-4795-84b0-5692daa74b78', name: 'الفرقة الثالثة' }],
      areas: [],
      sources: [],
      salespeople: [],
      selectedGroup: '',
      selectedGrade: '',
      selectedArea: '',
      selectedSource: '',
      selectedSales: '',
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}students/${this.$route.params.id}`).then(response => {
      this.student = response.data.data
      this.selectedGrade = {
        id: this.student.grade.id,
        name: this.student.grade.name

      }
      this.selectedGroup = {
        id: this.student.group.id,
        name: this.student.group.name

      }
      this.selectedArea = {
        id: this.student.area.id,
        name: this.student.area.name

      }
      this.selectedSource = {
        id: this.student?.source?.id || '',
        name: this.student?.source?.name || ''

      }
      this.selectedSales = {
        id: this.student?.salesperson?.id || '',
        name: this.student?.salesperson?.name || ''

      }
    })
    const areas = []
    this.$http.get(`${this.$hostUrl}areas`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          areas.push({
            name: value.name,
            id: value.id
          })
        })
        this.areas = areas
      })
    const sources = []
    this.$http.get(`${this.$hostUrl}sources`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          sources.push({
            name: value.name,
            id: value.id
          })
        })
        this.sources = sources
      })
    const salespeople = []
    this.$http.get(`${this.$hostUrl}salespeople`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          salespeople.push({
            name: value.name,
            id: value.id
          })
        })
        this.salespeople = salespeople
      })
  },
  methods: {
    async UpdateStudent (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.student.full_name)) {
        swal('اسم الطالب يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('full_name', this.student.full_name)
      if (this.student.email) {
        formData.append('email', this.student.email)
      }
      if (this.student.facebook_url) {
        formData.append('facebook_url', this.student.facebook_url)
      }
      if (this.selectedSource) {
        formData.append('source_id', this.selectedSource.id)
        if (this.selectedSource.id === '2c481399-60f5-11ee-bd6a-f23c936035ca') {
          formData.append('salesperson_id', this.selectedSales.id)
        }
      }
      formData.append('mobile', this.student.mobile)
      formData.append('group_id', this.selectedGroup.id)
      formData.append('grade_id', this.selectedGrade.id)
      formData.append('area_id', this.selectedArea.id)
      formData.append('_method', 'PUT')
      this.errors = await this.postRequest(formData, this.$hostUrl + 'students/' + this.$route.params.id, '/students')
    }
  }
}
</script>
